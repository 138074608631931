import type { RootState } from '@/store';
import { IADetails } from 'interfaces/ia.interface.ts';

export const iaDataSetSelector = (state: RootState) => state.ia.iaDataset;
export const iaDataSetSelectorFilter = (id: string) => (store: RootState) => {
  const filteredData = store.ia.iaDataset?.filter((item: IADetails) => item.parcel_id === id);

  return filteredData?.sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB.getTime() - dateA.getTime();
  });
};

export const iaDataSetSelectorFilterMarker = (lat: number, long: number) => (store: RootState) => {
  const filteredData = store.ia.iaDataset?.filter(
    (item: IADetails) => item.latitude === lat && item.longitude === long
  );

  return filteredData?.sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB.getTime() - dateA.getTime();
  });
};

export const iaUnsavedDataSelector = (state: RootState) =>
  state.ia.iaDataset?.filter((item: IADetails) => item.isUnsaved === true);
export const iaQueryStateSelector = (state: RootState) => state.ia.queryState;
export const iaCurrentEntrySelector = (state: RootState) => state.ia.currentEntry;
export const iaStatusSelector = (state: RootState) => state.ia.queryState;
export const iaProgressSelector = (state: RootState) => state.ia.fetchProgress;
export const iaSyncProgressSelector = (state: RootState) => state.ia.syncProgress;
