import { FC } from 'react';
import { PAmultiDetailsViewProps } from './PAmultiDetails.props.ts';
import { Grid, IconButton, useTheme } from '@mui/material';
import useStyles from './PAmultiDetailsView.styles.ts';
import Box from '@mui/material/Box';
import { DualTitleHeaderComponent } from '@zawarski/palmetto-ui-components';
import BackIcon from '@mui/icons-material/ArrowBack';
import { FormContent, SelectMenu, TextField, Typo } from 'components/primitive';
import { PageMaxLayout } from 'components/layout';
import { Controller } from 'react-hook-form';
import { ActionButtons } from 'components/fragment';
import { PACategoryValues } from '@/data';

const PAmultiDetailsView: FC<PAmultiDetailsViewProps> = ({
  form,
  inProcess,
  goBack,
  onHandleSubmit,
  numberOfParcels,
  incidentName,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box className={'layout vertical full-height'} style={{ overflowY: 'auto' }}>
      <DualTitleHeaderComponent
        titleBig={<Typo style={{ fontSize: 16, fontWeight: 500 }}>Add Assessment - PA</Typo>}
        titleSmall={`${incidentName ? incidentName + ', ' : ''} ${numberOfParcels === 1 ? numberOfParcels + ' parcel' : numberOfParcels + ' parcels'} selected`}
        icon={
          <IconButton onClick={() => goBack()}>
            <BackIcon className='icon' />
          </IconButton>
        }
      />
      <PageMaxLayout>
        <FormContent title='Structure Information' bgcolor={'#FFF'}>
          <Grid container spacing={2} sx={classes.gridContainer}>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='category'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <SelectMenu
                    withLabel
                    label='Category'
                    value={value}
                    onChange={onChange}
                    values={Object.values(PACategoryValues)}
                    error={Boolean(error)}
                    fieldError={error}
                    helperText={error ? error.message : ''}
                    placeholder={'Select category'}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='damage_description'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Description of damage'
                    placeholder='Enter description'
                    value={value}
                    onChange={onChange}
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormContent>
      </PageMaxLayout>
      <ActionButtons
        readonly={false}
        isSaving={inProcess}
        withSave
        save={!inProcess ? form.handleSubmit(onHandleSubmit) : undefined}
      />
    </Box>
  );
};

export default PAmultiDetailsView;
