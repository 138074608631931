export enum BuildingDamageTypes {
  MajorDamage = 'Major Damage',
  MinorDamage = 'Minor Damage',
  Affected = 'Affected',
  Destroyed = 'Destroyed',
  Inaccessible = 'Inaccessible',
  NoDamage = 'No Damage',
  Empty = '',
}

export const BuildingDamageColors = {
  [BuildingDamageTypes.MajorDamage]: '#FF4181',
  [BuildingDamageTypes.MinorDamage]: '#F57E23',
  [BuildingDamageTypes.Affected]: '#D7C804',
  [BuildingDamageTypes.Destroyed]: '#D0021B',
  [BuildingDamageTypes.Inaccessible]: '#1F1F1F',
  [BuildingDamageTypes.NoDamage]: '#6EAA2C',
  [BuildingDamageTypes.Empty]: null,
};

export const BuildingDamageSvg = {
  [BuildingDamageTypes.MajorDamage]: '/images/major.svg',
  [BuildingDamageTypes.MinorDamage]: '/images/minor.svg',
  [BuildingDamageTypes.Affected]: '/images/affected.svg',
  [BuildingDamageTypes.Destroyed]: '/images/destroyed.svg',
  [BuildingDamageTypes.Inaccessible]: '/images/inaccessible.svg',
  [BuildingDamageTypes.NoDamage]: '/images/noDamage.svg',
  [BuildingDamageTypes.Empty]: '/images/ia-default.png',
};
