import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import type { RootState } from '@/store';
import { getIncidentsSettings, getUsersSavedIncidents } from '@/services/incidents.endpoint.ts';
import { UserType } from '@/common/enum';
import {
  IncidentParcelSettingsType,
  UserIncidentsType,
  JPDAIncidentSettingValueType,
} from 'interfaces/incidents.interface.ts';
import { IUserPortal } from 'interfaces/UserAccountInfo.interface.ts';
// import { DMAUserSettings } from 'interfaces/UserAccountInfo.interface.ts';

export const fetchIncidentForParcelSync = createAsyncThunk(
  'fetchIncidentForParcelSync',
  async (controller: AbortController, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      // const allIncidents = store.incidents.userIncidents;
      const selectedGroup = store.accountsInfo.selectedGroup;
      const selectedGroupID = store.accountsInfo.selectedGroupID;
      const activeGroup = selectedGroup ? Number(selectedGroup) : selectedGroupID;
      if (!activeGroup) return Promise.reject('Missing required group ID');
      const settingType =
        store.accountsInfo.userType === UserType.STATE_USER
          ? 'selectedIncidentForParcelSyncState'
          : 'selectedIncidentForParcelSync';

      const endpoint = getIncidentsSettings({ pvSettingType: settingType, pvGroupID: activeGroup });
      return await apiFetch<IncidentParcelSettingsType[]>(endpoint, controller).then(
        (res) => res?.data || null
      );
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);

// export const fetchSelectedIncidentSetting = createAsyncThunk(
//   'fetchSelectedIncidentSetting',
//   async (controller: AbortController, thunkAPI) => {
//     try {
//       const store = thunkAPI.getState() as RootState;
//       const { selectedGroup, selectedGroupID } = store.accountsInfo;
//       const pvAccountID = store.accountsInfo.account?.id;
//       const activeGroup = selectedGroup ? selectedGroup : selectedGroupID;
//       if (!pvAccountID) return Promise.reject('Missing required IDs');
//
//       const endpoint = getIncidentsSettings({
//         pvSettingType: 'selectedIncidentMothership',
//         pvGroupID: Number(activeGroup),
//         pvAccountID,
//       });
//       return apiFetch<DMAUserSettings[]>(endpoint, controller).then((res) => res?.data || null);
//     } catch (error) {
//       let message = genericErrorMessage;
//       if (error instanceof Error) message = error.message;
//       else {
//         if (typeof error === 'object' && error !== null) {
//           if ('message' in error) {
//             message = String(error.message);
//           }
//         }
//       }
//       throw Error(message);
//     }
//   }
// );

export const fetchUsersSavedIncidents = createAsyncThunk(
  'fetchUsersSavedIncidents',
  async (_, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const account = store.accountsInfo.account;
      const selectedGroupID = store.accountsInfo.selectedGroupID;
      const selectedGroup = store.accountsInfo.selectedGroup;
      const settings = store.accountsInfo.userDMASettings?.settings ?? [];
      // const selectedIncident = store.incidents.selectedIncident;
      const selectedParcelSyncIncident = store.incidents.selectedIncidentForParcelSyncID;
      const activeGroup = selectedGroup ? selectedGroup : selectedGroupID;

      if (!account || !activeGroup) return;
      let selectedIncSettings;
      for (let i = 0; i < settings.length; i++) {
        if (settings[i].pvSettingType === 'selectedIncidents') {
          selectedIncSettings = settings[i].pvSettingValue;
        }
      }
      if (selectedIncSettings) {
        const incidentIds = selectedIncSettings || '[]';
        const savedIncidents: number[] = [];
        //Sort and remove duplicates in case of bad data
        const obj = JSON.parse(incidentIds);
        obj.forEach(function (item: number) {
          if (savedIncidents.indexOf(item) < 0) {
            savedIncidents.push(item);
          }
        });

        const incidentFilter = determineIncidentsFilter({
          accountInfo: account,
          selectedGroupID: Number(activeGroup),
        });

        const endpoint = getUsersSavedIncidents(JSON.stringify(incidentFilter));
        const incidentResponse = await apiFetch<UserIncidentsType[]>(endpoint).then(
          (res) => res?.data || []
        );
        if (!savedIncidents.includes(Number(selectedParcelSyncIncident) ?? 0)) {
          savedIncidents.push(Number(selectedParcelSyncIncident) ?? 0);
        }

        if (
          selectedParcelSyncIncident &&
          !savedIncidents.includes(Number(selectedParcelSyncIncident))
        ) {
          savedIncidents.push(Number(selectedParcelSyncIncident));
        }

        return parseSelectedIncidents(incidentResponse, Number(activeGroup), savedIncidents);
      } else if (selectedParcelSyncIncident) {
        const incidentFilter = determineIncidentsFilter({
          accountInfo: account,
          selectedGroupID: Number(activeGroup),
        });
        const endpoint = getUsersSavedIncidents(JSON.stringify(incidentFilter));
        const incidentResponse = await apiFetch<UserIncidentsType[]>(endpoint).then(
          (res) => res?.data || []
        );
        const savedIncidents: number[] = [];
        if (!savedIncidents.includes(Number(selectedParcelSyncIncident) ?? 0)) {
          savedIncidents.push(Number(selectedParcelSyncIncident) ?? 0);
        }

        return parseSelectedIncidents(incidentResponse, Number(activeGroup), savedIncidents);
      }

      return [];
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);

export const fetchJPDAIncidentSetting = createAsyncThunk(
  'fetchJPDAIncidentSetting',
  async (controller: AbortController, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const selectedIncident = store.incidents.selectedIncidentForParcelSyncID;
      const { selectedGroup, selectedGroupID } = store.accountsInfo;
      const activeGroup: number = selectedGroup ? Number(selectedGroup) : selectedGroupID ?? 0;
      if (!selectedIncident) return;
      const pvSettingType = `selectedJPDAGroupForIncident-${selectedIncident}`;
      const endpoint = getIncidentsSettings({ pvSettingType });
      const toRet: {
        data: JPDAIncidentSettingValueType;
        selectedJPDAIncidentSettingID: number;
        isJPDA_IA_Active: boolean;
        isJPDA_PA_Active: boolean;
      } = {
        data: { pa: [], ia: [] },
        selectedJPDAIncidentSettingID: 0,
        isJPDA_IA_Active: false,
        isJPDA_PA_Active: false,
      };
      const response = await apiFetch<IncidentParcelSettingsType[]>(endpoint, controller).then(
        (res) => res?.data || null
      );
      if (response && response.length) {
        const data = response[0].pvSettingValue;
        const selectedJPDAIncidentSettingID = response[0].pvSettingID;
        if (selectedJPDAIncidentSettingID)
          toRet.selectedJPDAIncidentSettingID = Number(selectedJPDAIncidentSettingID);
        if (data) {
          const parsedData = JSON.parse(data) as JPDAIncidentSettingValueType;
          if (activeGroup && parsedData && parsedData?.ia && parsedData.ia.length) {
            toRet.isJPDA_IA_Active = parsedData.ia.includes(activeGroup);
          }
          if (activeGroup && parsedData && parsedData?.pa && parsedData.pa.length) {
            toRet.isJPDA_PA_Active = parsedData.pa.includes(activeGroup);
          }
          Object.assign(toRet, { data: parsedData });
        }
      }
      return toRet;
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);

type IncidentsFilterType = {
  accountInfo: IUserPortal;
  selectedGroupID: number;
};

function determineIncidentsFilter(payload: IncidentsFilterType) {
  const OR = [];
  if (payload.selectedGroupID) {
    const activeGroup = payload.selectedGroupID;
    OR.push({ pvGroupID: activeGroup });
  } else {
    OR.push({ pvGroupID: -1 });
  }
  return {
    where: {
      and: [
        {
          pvStatus: {
            neq: 'CLOSED',
          },
        },
        {
          or: [...OR],
        },
      ],
    },
    include: {
      relation: 'incident2groups',
      scope: { relation: 'incidents' },
    },
  };
}

function _checkExerciseStatus(incident: UserIncidentsType) {
  if (incident && incident.pvExercise) {
    // Return exercise that is in progress
    if (incident.pvExerciseStatus && incident.pvExerciseStatus === 'In Progress') {
      return true;
    }
  } else {
    //since this is not an exercise treat it as a normal incident
    return true;
  }
}

function parseSelectedIncidents(
  allIncidents: UserIncidentsType[],
  activeGroupProps: number,
  savedIncidentsInSettings: number[]
) {
  const defaultIncidents: UserIncidentsType[] = [];
  const activeGroup = activeGroupProps;

  const data: UserIncidentsType[] = [];

  for (let i = 0; i < allIncidents.length; i++) {
    const incident = allIncidents[i];
    if (savedIncidentsInSettings.includes(incident.pvIncidentID)) {
      for (let j = 0; j < allIncidents[i].incident2groups.length; j++) {
        if (allIncidents[i].incident2groups[j].pvGroupID === activeGroup) {
          data.push(allIncidents[i]);
          break;
        }
      }
    }
  }

  // Find the default incidents

  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < data[i].incident2groups.length; j++) {
      if (
        data[i].incident2groups[j].pvGroupID === activeGroup &&
        data[i].incident2groups[j].pvAutoAssign
      ) {
        defaultIncidents.push(data[i]);
        break;
      }
    }
  }

  let updatedIncidents: UserIncidentsType[] = [];
  const filledIncidents: UserIncidentsType[] = [];

  //Find the common incidents
  for (let i = 0; i < savedIncidentsInSettings.length; i++) {
    for (let j = 0; j < data.length; j++) {
      if (savedIncidentsInSettings[i] === data[j].pvIncidentID) {
        filledIncidents.push(data[j]);
        break;
      }
    }
  }

  updatedIncidents = updatedIncidents.concat(filledIncidents);
  updatedIncidents = updatedIncidents.concat(defaultIncidents);

  // here we are getting rid of exercise if they are not started
  const arr: UserIncidentsType[] = [];
  for (let i = 0; i < updatedIncidents.length; i++) {
    const incident = updatedIncidents[i];
    if (_checkExerciseStatus(incident)) {
      arr.push(incident);
    }
  }

  // Remove duplicates
  const uniqueIncidents: UserIncidentsType[] = [],
    track: { [key: string]: number } = {};
  for (let i = 0; i < arr.length; i++) {
    if (!track[arr[i].pvIncidentID]) {
      track[arr[i].pvIncidentID] = arr[i].pvIncidentID;
      uniqueIncidents.push(arr[i]);
    }
  }

  return uniqueIncidents;
}
