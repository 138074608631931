import { QueryState } from '@/common/enum';
import { Nullable } from 'interfaces/Nullable.ts';

export type DBState = {
  queryState: QueryState;
  error: Nullable<string>;
};

export const DBInitialState: DBState = {
  queryState: QueryState.UNDEFINED,
  error: null,
};
