const useStyles = () => ({
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '4px',
    width: 'calc(100vw - 10%)',
    maxWidth: '450px',
  },
});

export default useStyles;
