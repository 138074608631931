import type IEndPoint from 'interfaces/EndPointTypes.ts';
import { AuthProtect } from '@/common/enum';
import { IncidentsSettingsFilterParams } from 'interfaces/incidents.interface.ts';
import { BaseURLSelect } from '@/common/enum';

function setSettingsParams(filter: IncidentsSettingsFilterParams): IncidentsSettingsFilterParams {
  const params: IncidentsSettingsFilterParams = {};
  if (filter.pvGroupID) {
    params.pvGroupID = filter.pvGroupID;
  }

  if (filter.pvSettingType) {
    params.pvSettingType = filter.pvSettingType;
  }

  if (filter.pvAccountID) {
    params.pvAccountID = filter.pvAccountID;
  }

  return params;
}

export const getUsersSavedIncidents = (filters: string): IEndPoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `/api/incidents?filter=${filters}`,
  };
};

export const getIncidentsSettings = (filters: IncidentsSettingsFilterParams): IEndPoint => {
  const params = setSettingsParams(filters);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/api/settings',
    params,
    defaultBaseURL: BaseURLSelect.DMA,
  };
};
