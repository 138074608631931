import { createSlice } from '@reduxjs/toolkit';
import { PAInitialState, PAStateTypes } from './pa.state.ts';
import { fetchPADataSet } from 'store/pa/pa.thunk.ts';
import { QueryState } from '@/common/enum';

const initialState: PAStateTypes = PAInitialState;

export const paSlice = createSlice({
  name: 'pa',
  initialState,
  reducers: {
    setPADataSet: (state, action) => {
      state.paDataset = action.payload;
    },
    updateFetchProgress: (state, action) => {
      state.fetchProgress = action.payload;
    },
    updateSyncProgress: (state, action) => {
      state.syncProgress = action.payload;
    },
    resetProgress: (state) => {
      state.fetchProgress = 0;
      state.syncProgress = 0;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPADataSet.pending, (state) => {
        state.queryState = QueryState.AWAIT;
        state.error = null;
      })
      .addCase(fetchPADataSet.fulfilled, (state, action) => {
        state.queryState = QueryState.READY;
        state.paDataset = action.payload ?? null;
      })
      .addCase(fetchPADataSet.rejected, (state, action) => {
        state.queryState = QueryState.FAIL;
        state.error = action.error.message ?? 'Something went wrong!';
      });
  },
});

export const { updateFetchProgress, updateSyncProgress, resetProgress } = paSlice.actions;
export default paSlice.reducer;
