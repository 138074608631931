/* eslint-disable no-unused-vars */
import React from 'react';
import { Box, IconButton, useTheme, Grid } from '@mui/material';
import useStyles from './FileContainer.styles.ts';
import { Typo } from 'components/primitive';
import FileIcon from '@mui/icons-material/Description';
import PhotoIcon from '@mui/icons-material/Photo';
import ClearIcon from '@mui/icons-material/Clear';
import { FileType } from 'interfaces/ia.interface.ts';

type FileContainerProps = {
  name: string;
  bytes: string;
  readOnly?: boolean;
  item: FileType;
  downloadFile?: (item: FileType) => void;
  removeFile?: (item: FileType) => void;
  authToken: string;
  imagePreview?: boolean;
};

const palmettoBaseURL = import.meta.env.VITE_PALMETTO_ENDPOINT;

function FileContainer({ readOnly = true, imagePreview = true, ...props }: FileContainerProps) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleDownload = () => {
    if (props.downloadFile) props.downloadFile(props.item);
    return null;
  };

  const handleRemove = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (props.removeFile) props.removeFile(props.item);
    return null;
  };
  // Check if the file is an image
  const isImage =
    (props.item &&
      props.item?.cbrnDataFileName &&
      props.item?.cbrnDataFileName?.match(/\.(jpeg|jpg|gif|png)$/) !== null) ||
    (props.item &&
      props.item?.file_name &&
      props.item?.file_name?.match(/\.(jpeg|jpg|gif|png)$/) !== null);
  const imageURL =
    props?.item?.cbrnDataFileURIID &&
    `${palmettoBaseURL}${props.item.cbrnDataFileURIID}?access_token=${props.authToken}`;

  return (
    <Grid item xs={12} sm={6} md={3} margin={0}>
      <Box sx={classes.wrap}>
        <Box sx={classes.file} onClick={props.downloadFile ? handleDownload : undefined}>
          <Box>
            {props?.item?.downloadLink || isImage ? (
              <PhotoIcon sx={{ width: '24px', color: theme.palette.grey[500] }} />
            ) : (
              <FileIcon sx={{ width: '24px', color: theme.palette.grey[500] }} />
            )}
          </Box>
          <Box sx={classes.fileNameWrapper}>
            <Typo sx={classes.fileName}>{props.name}</Typo>
            <Typo sx={classes.fileSize}>{props.bytes}</Typo>
          </Box>

          <Box sx={classes.removeBtn} hidden={readOnly}>
            <IconButton
              aria-label='Delete'
              aria-haspopup='true'
              onClick={props.removeFile ? handleRemove : undefined}>
              <ClearIcon className='icon-small' />
            </IconButton>
          </Box>
        </Box>
        {isImage && imageURL && imagePreview ? (
          <Box sx={{ paddingTop: '12px' }}>
            <img src={imageURL} alt={props.name} style={{ maxWidth: '100%' }} />
          </Box>
        ) : (
          props?.item?.downloadLink && (
            <Box sx={{ paddingTop: '12px' }}>
              <img alt='photoprev' src={props.item.downloadLink} style={{ maxWidth: '100%' }} />
            </Box>
          )
        )}
        {isImage && !imagePreview ? (
          //preview not available offline
          <Box>
            <Typo>Preview not available offline</Typo>
          </Box>
        ) : null}
      </Box>
    </Grid>
  );
}

export default FileContainer;
