import type IEndPoint from 'interfaces/EndPointTypes.ts';
import { AuthProtect, BaseURLSelect } from '@/common/enum';

export const getAccountsInfo = (endURL: string): IEndPoint => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'GET',
    url: `/api/accounts${endURL}`,
  };
};

export const getUserDMASettings = (accountID: number): IEndPoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/api/damageassessment_ms' + '?pvAccountID=' + accountID,
    defaultBaseURL: BaseURLSelect.DMA,
  };
};

export const getPalmettoGroups = (): IEndPoint => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'GET',
    url: 'https://damage.palmettoeoc.com/s3db/groups.json',
    defaultBaseURL: BaseURLSelect.NULL,
  };
};

export const getPalmettoGroupsFilter = (filter?: string | null): IEndPoint => {
  const url = filter ? `/api/groups?filter=${filter}` : '/api/groups';
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url,
  };
};
