import { Theme } from '@mui/material';

const useStyles = (theme: Theme, isMobile: boolean) => ({
  container: {
    display: 'flex',
    gap: '4px',
    alignItems: 'flex-end',
  },
  suffixButton: {
    padding: isMobile ? '4px' : '8px',
  },
  suffixIcon: {
    color: theme.palette.common.white,
  },
});

export default useStyles;
