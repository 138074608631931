import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  messageStyle: {
    fontSize: '14px',
    color: theme.palette.secondary.dark,
    textAlign: 'center',
  },
});

export default useStyles;
