import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  gridContainer: {
    px: '16px',
  },
  container: {
    display: 'flex',
    padding: '16px',
    alignItems: 'center',
    gap: '4px',
  },
  groupRadioContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    padding: '12px',
    alignItems: 'flex-start',
    alignContent: 'space-between',
    gap: '4px',
    alignSelf: 'stretch',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.grey[100]}`,
    background: theme.palette.secondary.main,
    flexWrap: 'wrap',
  },
  parcelContainer: {
    display: 'flex',
    padding: '12px 16px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    bgcolor: theme.palette.grey[200],
    borderRadius: '4px',
    boxShadow: '0px 0.85px 3px 0px rgba(0, 0, 0, 0.19), 0px 0.25px 1px 0px rgba(0, 0, 0, 0.04)',
    text: {
      fontSize: '14px',
      fontWeight: 600,
      textTransform: 'capitalize',
      color: theme.palette.secondary.contrastText,
    },
  },
});

export default useStyles;
