// eslint-disable-next-line
export default (): any => {
  const inputHeight = '40px';
  return {
    styleOverrides: {
      root: {
        paddingRight: 0,
        height: inputHeight,
        backgroundColor: '#fafafa',
        '&.MuiInputBase-inputAdornedEnd': {
          paddingRight: 0,
        },
        '& .MuiInputAdornment-positionEnd': {
          position: 'absolute',
          right: '12px',
        },
        '& .MuiInputBase-input:not(.MuiInputBase-inputMultiline)': {
          paddingTop: 0,
          paddingBottom: 0,
          height: inputHeight,
          fontSize: '16px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#F5F7FA',
        },
        '& .MuiSvgIcon-root': {
          color: '#D8D8D8',
        },
        '&.MuiInputBase-multiline': {
          paddingTop: 0,
          paddingBottom: 0,
          height: 'auto',
          '& .MuiInputBase-input': {
            paddingTop: '7px',
            paddingBottom: '7px',
            height: '150px !important',
            fontSize: '16px',
          },
        },
      },
    },
  };
};
