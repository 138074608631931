/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import apiClient, { AxiosRequestConfig } from 'axios';
import { DMAFileTypes } from 'interfaces/ia.interface.ts';

const useUploadForm = <T>(hasOldData: boolean = false) => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [inProgress, setInProgress] = useState<boolean>(false);
  //already updated documents
  const [documents, setDocuments] = useState<any[] | T[]>([]);
  //local documents saved in pouch db
  const [localDocuments, setLocalDocuments] = useState<any[] | T[]>([]);

  const downloadForm = async (
    url: string,
    config: AxiosRequestConfig,
    oldFiles: DMAFileTypes[]
  ) => {
    setInProgress(true);
    const response = await apiClient
      .get(url, {
        ...config,
        onDownloadProgress: (progressEvent) => {
          const progress = Math.round((100 * progressEvent.loaded) / (progressEvent?.total ?? 0));
          setProgress(progress);
        },
      })
      .then((res) => {
        return { status: res.status, data: res.data };
      })
      .catch((res) => {
        console.error('🚀 downloadForm Hook res: ', res?.data);
        return { data: [] };
      });
    const respData = response.data;
    if (Array.isArray(respData)) {
      if (hasOldData && oldFiles.length > 0) {
        const tempFiles: DMAFileTypes[] = [];
        oldFiles.forEach((oldFiles) => {
          const found = respData.find(
            (file: DMAFileTypes) =>
              (file.cbrnDataFileName?.trim()?.toLowerCase() ?? '') ===
              oldFiles.file_name?.toLowerCase()
          );
          if (found) {
            const objFile: DMAFileTypes = {
              ...oldFiles,
              cbrnDataFileName: found.cbrnDataFileName,
              cbrnDataFileURIID: found.cbrnDataFileURIID,
              cbrnDataFileSize: found.cbrnDataFileSize,
            };
            tempFiles.push(objFile);
          } else {
            if (oldFiles && oldFiles?.file_name?.length && !oldFiles?.file_name.includes('DUMMY')) {
              tempFiles.push(oldFiles);
            }
          }
        });
        setDocuments(tempFiles);
      } else {
        setDocuments(respData);
      }
    }
    // setDocuments(JSON.parse(JSON.stringify(response.data)));
    setInProgress(false);
  };

  async function uploadForm<U>(file: File, url: string, config: AxiosRequestConfig) {
    setInProgress(true);
    const formData = new FormData();
    formData.append('form append file', file);
    // noinspection JSUnusedGlobalSymbols
    const fileUploadResponse = await apiClient.post(url, formData, {
      ...config,
      onUploadProgress: (progressEvent) => {
        const progress = Math.round((100 * progressEvent.loaded) / (progressEvent?.total ?? 0));
        setProgress(progress);
      },
    });
    setIsSuccess(true);
    setInProgress(false);
    const payload: { status: number; data: U } = {
      status: fileUploadResponse.status,
      data: fileUploadResponse.data,
    };
    return payload;
  }

  return {
    uploadForm,
    downloadForm,
    documents,
    localDocuments,
    inProgress,
    isSuccess,
    progress,
    setDocuments,
    setLocalDocuments,
  };
};

export default useUploadForm;
