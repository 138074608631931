import React from 'react';
import { Button } from 'components/primitive';
import Box from '@mui/material/Box';

const appTitle = import.meta.env.VITE_APP_TITLE ?? 'Damage Assessment';
const RedirectView: React.FC = () => {
  // noinspection HtmlUnknownTarget
  return (
    <Box className='layout vertical center vertical-center full-height'>
      {window.location.href.indexOf('access_token') === -1 ? (
        <div className='layout vertical logincontainer'>
          <div className='layout horizontal center center-justified app-logo-container'>
            <img className='app-logo' src='/images/launcher.png' alt='Palmetto Logo' />
          </div>
          <div className='layout horizontal center-justified'>
            <span className='app-title'>{appTitle}</span>
          </div>
          <div
            style={{
              textAlign: 'center',
              height: '50px',
              paddingTop: '24px',
              color: 'rgba(0,0,0,67)',
              fontSize: '14px',
            }}>
            Please use the Portal to access this site.
          </div>
          <div className='layout horizontal center-justified'>
            <Button
              className='flex login-btn'
              style={{ maxWidth: '220px' }}
              onClick={() => {
                window.location.href = 'https://portal.palmettoeoc.com';
              }}>
              NAVIGATE TO PORTAL
            </Button>
          </div>
          <div
            style={{
              textAlign: 'center',
              paddingLeft: '64px',
              paddingRight: '64px',
              paddingTop: '44px',
              color: 'rgba(107,107,107,30)',
              fontSize: '14px',
            }}>
            Access to this site is now available only through the portal on the following link:{' '}
          </div>
          <div
            style={{ fontSize: '16px', paddingTop: '4px' }}
            className='layout horizontal center-justified'>
            <a target='_blank' rel='noopener noreferrer' href='https://portal.palmettoeoc.com'>
              portal.palmettoeoc.com
            </a>
          </div>
        </div>
      ) : (
        <></>
      )}
      <p style={{ fontSize: '12px', color: 'rgba(0,0,0,0.54)', textAlign: 'center' }}>
        All data in Palmetto is to be considered FOUO and not for public dissemination.
      </p>
    </Box>
  );
};

export default RedirectView;
