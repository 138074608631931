import { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { IADetails } from 'interfaces/ia.interface.ts';
import { Loading } from 'components/primitive';
import DetailsPage from './IADetailsPage.tsx';
import { ParcelData } from 'components/fragment/ParcelViewModal/ParcelViewModal.props.tsx';

const IADetailsContainer: FC = () => {
  const params = useParams();
  const location = useLocation();
  const dataState = location.state?.data;
  const parcelDataState = location.state?.parcelData;
  const markerDataState = location.state?.markerData;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<IADetails>();
  const [parcelData, setParcelData] = useState<ParcelData>();
  const [markerData, setMarkerData] = useState<any>();
  const [newEntry, setNewEntry] = useState<boolean>(true);

  useEffect(() => {
    if (dataState) {
      setData(dataState);
      setNewEntry(false);
      setLoading(false);
    } else if (parcelDataState) {
      setParcelData(parcelDataState);
      setLoading(false);
    } else if (markerDataState) {
      setMarkerData(markerDataState);
      setLoading(false);
    }
  }, [params, dataState, parcelDataState, markerDataState]);

  return (
    <>
      {loading && <Loading message={'Loading data'} />}
      {!loading && (
        <DetailsPage
          data={data}
          newEntry={newEntry}
          parcelId={params.parcelId || null}
          parcelData={parcelData}
          markerData={markerData}
        />
      )}
    </>
  );
};

export default IADetailsContainer;
