import { Nullable } from 'interfaces/Nullable.ts';
import { QueryState } from '@/common/enum';
import { IUserPortal } from 'interfaces/UserAccountInfo.interface.ts';
import SitePermissionTypes from '@/common/enum/SitePermissionTypes.ts';

export type AuthTokenTypes = {
  queryState: QueryState;
  token: Nullable<string>;
  tokenVerification: Nullable<IUserPortal>;
  userId: Nullable<number>;
  accessType: Nullable<SitePermissionTypes>;
  error: Nullable<string>;
  username: Nullable<string>;
};

export const AuthTokenInitialState: AuthTokenTypes = {
  queryState: QueryState.UNDEFINED,
  token: null,
  tokenVerification: null,
  userId: null,
  accessType: null,
  error: null,
  username: null,
};
