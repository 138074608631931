import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BuildingDamageTypes } from '@/data/LegendValues/BuildingDamage.ts';

export interface IAmultipleFormData {
  structure_type: string;
  building_damage: BuildingDamageTypes;
}

export const defaultValues: IAmultipleFormData = {
  structure_type: '',
  building_damage: BuildingDamageTypes.Empty,
};

function useIAmultiForm() {
  const validationSchema = useMemo(
    () =>
      object().shape({
        structure_type: string().required('Please select structure type'),
        building_damage: string().required('Please select building damage'),
      }),
    []
  );

  return useForm<any>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
}

export default useIAmultiForm;
