import theme from '@/theme';

export const useSelectTeamModal = () => ({
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '4px',
    width: '90vw',
    maxWidth: '600px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    padding: '16px',
    height: '60vh',
    maxHeight: '600px',
    width: '100%',
    overflow: 'auto',
  },
  groupRadioContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  },
  controllerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  radioGroupWrapper: {
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    paddingRight: '8px',
    marginLeft: 'unset',
    width: '100%',
    my: '4px',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '16px',
    mt: '10px',
  },
  footerWrapper: {
    display: 'flex',
    width: '100%',
    gap: '8px',
  },
  title: {
    fontSize: '14px',
    fontWeight: 600,
    padding: '16px',
    color: theme.palette.grey[400],
  },
});
