import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import { MapLayout } from 'components/layout';
import AuthPage from '@/auth';
import LoginPage from 'pages/Login';
import RedirectPage from 'pages/Redirect';
import IADetailsContainer from 'pages/IA/Details/IADetailsContainer.tsx';
import PADetailsContainer from 'pages/PA/Details/PADetailsContainer.tsx';
import IAMultiDetails from 'pages/IAmultiple/Details';
import PAMultiDetails from 'pages/PAmultiple/Details';
import LoadingContainer from 'pages/Loading';
import QueueContainer from 'pages/Queue';
import InfoView from 'pages/Info';

const JSXRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<AuthPage />}>
      <Route path='loading'>
        <Route index element={<LoadingContainer />} />
      </Route>
      <Route path='map' element={<MapLayout />}>
        <Route path='ia'>
          <Route path=':parcelId?/:assessmentId?' element={<IADetailsContainer />} />
        </Route>
        <Route path='iamulti' element={<IAMultiDetails />} />
        <Route path='pamulti' element={<PAMultiDetails />} />
        <Route path='pa'>
          <Route path=':parcelId?/:assessmentId?' element={<PADetailsContainer />} />
        </Route>
        <Route path='queue' element={<QueueContainer />} />
      </Route>
      <Route path='login' element={<LoginPage />} />
      <Route path='info' element={<InfoView />} />
      <Route path='*' element={<RedirectPage />} />
    </Route>
  )
);

export default JSXRoutes;
