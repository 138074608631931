import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthTokenInitialState, AuthTokenTypes } from './authToken.state.ts';
import { cognitoLogin, verifyToken } from 'store/authToken/authToken.thunk.ts';
import QueryState from '@/common/enum/QueryState.ts';
import SitePermissionTypes from '@/common/enum/SitePermissionTypes.ts';

const initialState: AuthTokenTypes = AuthTokenInitialState;

export const authTokenSlice = createSlice({
  name: 'authToken',
  initialState,
  reducers: {
    setAuthCreds: (
      state,
      action: PayloadAction<{
        id: string;
        userId: number;
        accessType: SitePermissionTypes;
        username: string;
      }>
    ) => {
      state.token = action.payload.id;
      state.userId = action.payload.userId;
      state.accessType = action.payload.accessType;
      state.username = action.payload.username;
    },
    setAuthToken: (state, action) => {
      state.token = action.payload;
    },
    setErrorMsg: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    resetAuthToken: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyToken.pending, (state) => {
        state.queryState = QueryState.AWAIT;
        state.error = null;
        state.token = null;
        state.tokenVerification = null;
      })
      .addCase(verifyToken.fulfilled, (state, action: any) => {
        state.queryState = QueryState.READY;
        if (action.payload) state.tokenVerification = action.payload;
      })
      .addCase(verifyToken.rejected, (state, action) => {
        state.queryState = QueryState.FAIL;
        state.error = action.error.message ?? 'Something went wrong';
        state.tokenVerification = null;
      })
      .addCase(cognitoLogin.pending, (state) => {
        state.queryState = QueryState.AWAIT;
        state.error = null;
        state.token = null;
        state.tokenVerification = null;
      })
      .addCase(cognitoLogin.fulfilled, (state, action: any) => {
        state.queryState = QueryState.READY;
        if (action.payload) state.tokenVerification = action.payload;
      })
      .addCase(cognitoLogin.rejected, (state, action) => {
        state.queryState = QueryState.FAIL;
        state.error = action.error.message ?? 'Something went wrong';
        state.tokenVerification = null;
      });
  },
});
export const { resetAuthToken } = authTokenSlice.actions;
export default authTokenSlice.reducer;
