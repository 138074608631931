import { Nullable } from 'interfaces/Nullable.ts';
import { TeamInterface } from 'interfaces/team.interface.ts';

export type TeamsTypes = {
  loading: boolean;
  entities: Nullable<TeamInterface[]>;
  error: Nullable<string>;
};

export const TeamsInitialState: TeamsTypes = {
  loading: false,
  entities: [],
  error: null,
};
