import { useEffect, useState } from 'react';
import { HeaderComponent } from '@zawarski/palmetto-ui-components';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { Button, Modal, Typo } from 'components/primitive';

import { ParcelViewModalProps } from 'components/fragment/ParcelViewModal/ParcelViewModal.props.tsx';
import { IADetails } from 'interfaces/ia.interface.ts';
import { useAppSelector } from 'store/hooks.ts';
import { iaDataSetSelectorFilter } from 'store/ia/ia.selector.ts';
import { paDataSetSelectorFilter } from 'store/pa/pa.selector.ts';
import useStylesParcelView from 'components/fragment/ParcelViewModal/ParcelViewModal.style.ts';
import { useNavigate } from 'react-router-dom';
import { DateTimeFormat } from '@/utilities/FomattingUtils.ts';
import { shallowEqual } from 'react-redux';

export function ParcelViewModal({
  onClose,
  headerTitle,
  optionData,
  categorySelected,
}: ParcelViewModalProps) {
  const navigate = useNavigate();
  const [dataSet, setDataSet] = useState<any>([]);
  const iaDataSet: IADetails[] | undefined = useAppSelector(
    iaDataSetSelectorFilter(optionData.PIN),
    shallowEqual
  );
  const paDataSet = useAppSelector(paDataSetSelectorFilter(optionData.PIN), shallowEqual);
  const theme = useTheme();
  const classes = useStylesParcelView();

  useEffect(() => {
    if (categorySelected === 'pa') {
      setDataSet(paDataSet);
    } else {
      setDataSet(iaDataSet);
    }
  }, [categorySelected, paDataSet, iaDataSet]);

  return (
    <Modal open={true} onClose={undefined}>
      <>
        <HeaderComponent
          title={`${headerTitle}${optionData.SiteAddress?.trim() ? ' - ' + optionData.SiteAddress : ''}`}
          icon={
            <IconButton
              onClick={() => {
                onClose(false);
              }}>
              <CloseIcon className='icon' />
            </IconButton>
          }
        />
        <Box sx={classes.content}>
          {dataSet && dataSet?.length !== 0 && (
            <TableContainer component={Paper}>
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    {categorySelected === 'ia' && <TableCell align='left'>Team Member</TableCell>}
                    <TableCell align='left'>Created At</TableCell>
                    <TableCell align='left'>Updated At</TableCell>
                    <TableCell align='left'>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataSet &&
                    dataSet.map((data: any, index: number) => {
                      return (
                        <TableRow
                          key={index}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          {data.assessment_team_member && (
                            <TableCell component='th' scope='row' align='justify'>
                              {data.assessment_team_member}
                            </TableCell>
                          )}
                          <TableCell align='justify'>
                            {DateTimeFormat({
                              dte: data.created_at,
                              format: 'YYYY-MM-DD HH:mm:ss',
                            })}
                          </TableCell>
                          <TableCell align='justify'>
                            {DateTimeFormat({
                              dte: data.updated_at,
                              format: 'YYYY-MM-DD HH:mm:ss',
                            })}
                          </TableCell>
                          <TableCell align='justify'>
                            <Button
                              onClick={() => {
                                navigate(`/map/${categorySelected}/${optionData.PIN}/${data.id}`, {
                                  state: { data: data },
                                });
                                onClose(false);
                              }}>
                              Edit
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {dataSet?.length === 0 && (
            <Box sx={classes.title}>
              <Typo variant='body2'>No items found</Typo>
            </Box>
          )}
        </Box>
        <Box sx={classes.footer}>
          <Box sx={classes.footerWrapper}>
            <Button
              fullWidth
              neutral
              sx={{ color: theme.palette.text.secondary }}
              onClick={() => {
                onClose(false);
              }}>
              CANCEL
            </Button>
            <Button
              fullWidth
              onClick={() => {
                navigate(`/map/${categorySelected}/${optionData.PIN}`, {
                  state: { parcelData: optionData },
                });
                onClose(false);
              }}>
              ADD NEW
            </Button>
          </Box>
        </Box>
      </>
    </Modal>
  );
}
