import { Nullable } from 'interfaces/Nullable.ts';

export type ConnectionState = {
  isInitApiLoading: boolean;
  health: boolean;
  error: Nullable<string>;
  isWSConnected: boolean;
};

export const ConnectionInitialState: ConnectionState = {
  isInitApiLoading: false,
  health: false,
  error: null,
  isWSConnected: false,
};
