import { useAppDispatch } from 'store/hooks.ts';
import { useEffect, useState } from 'react';
import { setHealth } from 'store/connection/connection.actions.ts';
import { fetchIADataSet } from 'store/ia/ia.thunk.ts';
import { fetchPADataSet } from 'store/pa/pa.thunk.ts';

const useStartHealthChecker = () => {
  const [checkIsOnline, setCheckIsOnline] = useState(navigator.onLine);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const goOnline = () => setCheckIsOnline(true);
    const goOffline = () => setCheckIsOnline(false);

    window.addEventListener('online', goOnline);
    window.addEventListener('offline', goOffline);
  }, []);

  useEffect(() => {
    dispatch(setHealth(checkIsOnline));
    if (checkIsOnline) {
      const fetchData = async () => {
        await dispatch(fetchIADataSet());
        await dispatch(fetchPADataSet());
      };
      fetchData();
    }
  }, [checkIsOnline]);
};

export default useStartHealthChecker;
