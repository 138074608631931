import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
// import * as AWS from 'aws-sdk/global';
import {
  AuthenticationDetails,
  CognitoIdToken,
  CognitoUser,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';
import { getAccountFromIdToken } from '@/utilities';
import { IUserPortal, UserAuthCreds } from '@/interfaces/UserAccountInfo.interface';
import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';
import type { RootState } from '@/store';

// AWS.config.region = 'us-east-1';
const USER_POOL_ID = 'us-east-1_3f1efBpoo';
const USER_POOL_CLIENT_ID = '4i0bpro8bq9cusqkigt1j4gb9f';
const USER_POOL = new CognitoUserPool({
  UserPoolId: USER_POOL_ID,
  ClientId: USER_POOL_CLIENT_ID,
});
declare global {
  interface Window {
    __PALMETTO_POUCH_DB_AUTH_INSTANCE__: any;
  }
}
let dbInstance = window.__PALMETTO_POUCH_DB_AUTH_INSTANCE__ || null;

export function setupAuthorizationDatabaseAndSaveToken(payload: UserAuthCreds) {
  return new Promise((resolve, reject) => {
    if (!dbInstance) {
      PouchDB.plugin(PouchDBFind);
      dbInstance = new PouchDB('auth_db_v2');
      window.__PALMETTO_POUCH_DB_AUTH_INSTANCE__ = dbInstance;
    }
    dbInstance.info().then(function () {
      _checkIfAuthAlreadyExists((notFound: boolean | null, doc?: any) => {
        if (notFound) {
          _storeAuthToken(payload, (error: any, response: any) => {
            if (error) {
              reject(error);
            } else {
              resolve(response);
            }
          });
          return;
        } else {
          resolve(doc);
        }
      });
    });
  });
}

const _storeAuthToken = (payload: UserAuthCreds, callback: (error: any, response: any) => void) => {
  dbInstance
    .get('auth')
    .then(function (doc: any) {
      return dbInstance.put({
        _rev: doc._rev,
        palmettoToken: JSON.stringify(payload),
        _id: 'auth',
      });
    })
    .then((response: any) => {
      // handle response
      callback(null, response);
    })
    .catch(async (err: any) => {
      if (err.name === 'not_found') {
        const response = await dbInstance.put({
          palmettoToken: JSON.stringify(payload),
          _id: 'auth',
        });
        callback(null, response);
      }
    });
};

const _checkIfAuthAlreadyExists = (callback: (notFound: boolean | null, doc?: any) => void) => {
  dbInstance.find({ selector: { _id: 'auth' } }).then(function (docs: any) {
    if (docs.docs[0] && docs.docs[0].palmettoToken) {
      callback(null, docs.docs[0]);
    } else {
      const notFound = true;
      callback(notFound);
    }
  });
};

const baseURL = 'https://portal.palmettoeoc.com/prod/public/api/cognito/account';

function SetNewCognitoUser(payload: {
  username: string;
  password: string;
  activeId?: string;
}): Promise<CognitoUser | null> {
  return new Promise((res, rej) => {
    try {
      const currentUser = new CognitoUser({
        Username: payload.username,
        Pool: USER_POOL,
      });
      // !:NEED THIS TO TRIGGER USER MIGRATION
      currentUser.setAuthenticationFlowType('USER_SRP_AUTH');
      currentUser.authenticateUser(
        new AuthenticationDetails({
          Username: payload.username,
          Password: payload.password,
          ClientMetadata: {
            ActiveGroupID: payload.activeId ? payload.activeId.toString() : '0',
          },
        }),
        {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          onSuccess(_session) {
            // COGNITO_USER = currentUser;
            return res(currentUser);
          },
          onFailure(error) {
            //	? code: NotAuthorizedException
            //	? name: NotAuthorizedException
            //	? message: Incorrect username or password.
            //	? code: UserNotFoundException
            //  ? name: UserNotFoundException
            let message = 'Un-authorize User Account';
            const listPropertyNames = Object.keys(error);
            // eslint-disable-next-line no-restricted-syntax
            for (const key in listPropertyNames) {
              if (key === 'NotAuthorizedException') {
                message = 'Incorrect username or password.';
              }
            }
            return rej(message);
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  });
}

export const cognitoLogin = createAsyncThunk(
  'COGNITO_LOGIN',
  async (payload: { username: string; password: string; activeId?: string }, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const { health } = store.connection;
      if (health) {
        const userCognitoAccount: CognitoUser | null = await SetNewCognitoUser(payload)
          .then((session) => session)
          .catch((e) => {
            console.log('checkActiveSession error >>>', e);
            throw e;
          });

        if (userCognitoAccount) {
          let idToken: CognitoIdToken | undefined;
          if (userCognitoAccount?.getSignInUserSession()) {
            idToken = userCognitoAccount?.getSignInUserSession()?.getIdToken();
          }
          if (!idToken) {
            // noinspection ExceptionCaughtLocallyJS
            throw Error('No Id Token');
          }

          let accountInfo: Partial<IUserPortal> = getAccountFromIdToken(idToken);
          accountInfo = JSON.parse(accountInfo as string);
          // accountInfo.jwToken = idToken.jwtToken;
          return accountInfo as IUserPortal;
        }
      }
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);

export const verifyToken = createAsyncThunk('VERIFY_TOKEN', async (token: string, thunkAPI) => {
  try {
    const store = thunkAPI.getState() as RootState;
    const { health } = store.connection;
    if (health) {
      const headers = {
        Authorization: token,
      };
      const tokenVerification = await axios.get(baseURL, { headers }).catch((error) => {
        throw error?.response?.data;
      });
      if (tokenVerification) {
        return tokenVerification.data as IUserPortal;
      } else {
        throw Error('Invalid Token');
      }
    }
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});

/************DANGER ZONE ******************/

export const destroyAuthDB = createAsyncThunk('DESTROY_AUTH_DB', async () => {
  try {
    if (window.__PALMETTO_POUCH_DB_AUTH_INSTANCE__) {
      await window.__PALMETTO_POUCH_DB_AUTH_INSTANCE__.destroy();
      window.location.reload();
    } else {
      throw new Error('Database instance not found');
    }
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else if (typeof error === 'object' && error !== null && 'message' in error) {
      message = String(error.message);
    }
    console.error('Error destroying database:', message);
    throw Error(message);
  }
});
