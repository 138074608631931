import { createTheme } from '@mui/material/styles';
import MuiButtonStyle from './component/MuiButton';
import MuiTabs from './component/MuiTabs.ts';
import MuiTextField from './component/MuiTextField.ts';
import MuiOutlinedInput from 'theme/component/MuiOutlinedInput.ts';
import typography from './typography';
import customPallet from './pallet';

const theme = createTheme({
  palette: customPallet,
  typography,
  components: {
    MuiButton: MuiButtonStyle(customPallet),
    MuiTextField: MuiTextField(customPallet),
    MuiTabs: MuiTabs(customPallet),
    MuiOutlinedInput: MuiOutlinedInput(),
  },
});

export default theme;
