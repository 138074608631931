import DefaultPaletteOptions from '../defaultPallete';

const pallet: DefaultPaletteOptions = {
  primary: {
    light: '#E2EAFF', // light blue
    main: '#2961FD', // main blue
    dark: '#1C43B1', // dark blue
  },
  secondary: {
    light: '#C2C2C2',
    main: '#F0F0F0',
    dark: '#E0E0E0',
    contrastText: '#1F1F1F',
  },
  grey: {
    50: '#EBEBEB',
    100: '#E0E0E0',
    200: '#BDBDBD',
    300: '#9E9E9E',
    400: '#707070',
    500: '#474747',
  },
  error: {
    light: '#e57373',
    main: '#D0021B',
    dark: '#d32f2f',
  },
  text: {
    primary: '#000',
    secondary: '#767676',
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
};

export default pallet;
