import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAInitialState, IAStateTypes } from './ia.state';
import { fetchIADataSet, saveIAData } from 'store/ia/ia.thunk.ts';
import { QueryState } from '@/common/enum';
import { IADetails } from 'interfaces/ia.interface.ts';

const initialState: IAStateTypes = IAInitialState;

export const iaSlice = createSlice({
  name: 'ia',
  initialState,
  reducers: {
    setCurrentEntry: (state, action: PayloadAction<IADetails | null>) => {
      state.currentEntry = action.payload;
    },
    setIADataSet: (state, action: PayloadAction<IADetails[] | null>) => {
      state.iaDataset = action.payload || [];
    },
    updateFetchProgress: (state, action) => {
      state.fetchProgress = action.payload;
    },
    updateSyncProgress: (state, action) => {
      state.syncProgress = action.payload;
    },
    resetProgress: (state) => {
      state.fetchProgress = 0;
      state.syncProgress = 0;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchIADataSet.pending, (state) => {
        state.queryState = QueryState.AWAIT;
        state.error = null;
      })
      .addCase(fetchIADataSet.fulfilled, (state, action) => {
        state.queryState = QueryState.READY;
        state.iaDataset = action.payload ?? null;
      })
      .addCase(fetchIADataSet.rejected, (state, action) => {
        state.queryState = QueryState.FAIL;
        state.error = action.error.message ?? null;
      })
      .addCase(saveIAData.pending, (state) => {
        state.queryState = QueryState.AWAIT;
        state.error = null;
      })
      .addCase(saveIAData.fulfilled, (state) => {
        state.queryState = QueryState.READY;
        state.currentEntry = null;
      })
      .addCase(saveIAData.rejected, (state, action) => {
        state.queryState = QueryState.FAIL;
        state.error = action.error.message ?? null;
      });
  },
});

export const { updateFetchProgress, updateSyncProgress, resetProgress } = iaSlice.actions;
export default iaSlice.reducer;
