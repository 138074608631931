import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  wrap: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    padding: '12px',
    // pr: '36px',
    // gap: '12px',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.grey[100]}`,
    background: theme.palette.common.white,
    cursor: 'pointer',
    position: 'relative',
  },
  file: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px',
    pr: '36px',
    gap: '12px',
    borderRadius: '4px',
    // border: `1px solid ${theme.palette.grey[100]}`,
    background: theme.palette.common.white,
    cursor: 'pointer',
    position: 'relative',
  },
  fileNameWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  fileName: {
    fontSize: '14px',
    fontWeight: 700,
    color: theme.palette.grey[500],
    textTransform: 'uppercase',
  },
  fileSize: {
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette.grey[400],
  },
  removeBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

export default useStyles;
