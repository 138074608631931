import { DMAFileTypes } from 'interfaces/ia.interface.ts';
import apiClient, { AxiosRequestConfig } from 'axios';
import { DateTimeFormat } from '@/utilities/FomattingUtils.ts';

const palmettoBaseURL = import.meta.env.VITE_MAIN_PALMETTO_ENDPOINT;

export function bytesToSize(bytes: number) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  // eslint-disable-next-line
  if (Number(bytes) === 0) return '0 Byte';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const value = bytes / Math.pow(1024, i);
  return Math.round(value) + ' ' + sizes[i];
}

export const getProperKeyNameDummySearchString = (name: string) => {
  if (name && name.indexOf('_DUMMYSEARCHSTRING_') >= 0) {
    return name.substring(name.indexOf('_DUMMYSEARCHSTRING_') + 19);
  }
  return name;
};

export function spliceDate(name: string) {
  //splice out the dummy string if it exists (If it does not it's an old file)
  if (name && name.indexOf('_DUMMYSEARCHSTRING_') >= 0) {
    return name.substring(name.indexOf('_DUMMYSEARCHSTRING_') + 19);
  }
  return name;
}

type AddOrDeleteFilesFuncProps = {
  filesToAdd: DMAFileTypes[];
  filesToDelete: DMAFileTypes[];
  uuid: string;
  authToken: string;
};
export const addOrDeleteFilesFunc = async ({
  filesToAdd = [],
  filesToDelete = [],
  uuid,
  authToken,
}: AddOrDeleteFilesFuncProps) => {
  const calls = [];
  for (let i = 0; i < filesToAdd.length; i++) {
    const url = `${palmettoBaseURL}/api/files/updateID?access_token=${authToken}`;
    const obj = {
      pvDataID: filesToAdd[i].pvDataID,
      pvGlobalGroupID: uuid,
    };
    calls.push(
      apiClient.put(url, obj, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
    );
  }

  for (let i = 0; i < filesToDelete.length; i++) {
    const url = `${palmettoBaseURL}/api/files/delete?access_token=${authToken}`;
    const obj = {
      globalid: filesToDelete[i].pvGlobalID,
    };
    calls.push(
      apiClient.put(url, obj, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
    );
  }

  await Promise.all(calls);

  return filesToAdd.length - filesToDelete.length;
};

export const prepareDMAFilesData = (files: DMAFileTypes[], formType: 'ia' | 'pa') => {
  const arr: {
    file_key: string;
    file_size: number;
    file_type: string;
    file_name: string;
    form_type: 'ia' | 'pa';
    pvGlobalID: string;
  }[] = [];

  files.forEach((file) => {
    arr.push({
      file_key: file.cbrnDataFileName ?? '',
      file_size: file.cbrnDataFileSize ?? 0,
      file_type: file.cbrnMIMEContentCodeSimpleType ?? '',
      file_name: file.cbrnDataFileName ?? '',
      form_type: formType,
      pvGlobalID: file.pvGlobalID ?? '',
    });
  });

  return arr;
};

export const preparePAFilesData = (files: DMAFileTypes[]) => {
  const arr: {
    file_key: string;
    file_size: number;
    file_type: string;
    deleted: number;
    file_name: string;
    user_id: number;
    pvGlobalID: string;
    updated_at: string;
    created_at: string;
    id: string | null;
  }[] = [];

  files.forEach((file) => {
    arr.push({
      id: file.form_id ?? null,
      file_key: file.cbrnDataFileName ?? '',
      file_size: file.cbrnDataFileSize ?? 0,
      file_type: file.cbrnMIMEContentCodeSimpleType ?? '',
      file_name: file.cbrnDataFileName ?? '',
      user_id: file.pvUserID ?? 0,
      deleted: file.deleted ?? 0,
      pvGlobalID: file.pvGlobalID ?? '',
      updated_at: DateTimeFormat({ dte: file.updated_at, format: 'YYYY-MM-DD HH:mm:ss' }),
      created_at: DateTimeFormat({ dte: file.created_at, format: 'YYYY-MM-DD HH:mm:ss' }),
    });
  });

  return arr;
};

export const saveFileToPalmettoDB = async (
  file: File,
  authToken: string,
  pvGlobalGroupID: string,
  userid: number,
  username: string,
  uploadForm: <U>(
    file: File,
    url: string,
    config: AxiosRequestConfig<any>
  ) => Promise<{ status: number; data: U }>
) => {
  try {
    const url = `${palmettoBaseURL}/api/files/uploadWithoutID?access_token=${authToken}&filter=${JSON.stringify({ where: { pvGlobalGroupID: pvGlobalGroupID } })}`;
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        uuid: pvGlobalGroupID,
        userid: userid,
        username: username,
      },
    };

    const uploadedFile = await uploadForm<any>(file, url, config);
    if (uploadedFile?.data?.cbrnMIMEContentCodeSimpleType?.includes('image')) {
      uploadedFile.data.downloadLink = URL.createObjectURL(file);
    }
    // if (uploadedFile.status === 200) tempUploadedFiles.push(uploadedFile.data);

    return uploadedFile.data;
  } catch (error) {
    console.log('onDrop Upload error: ', error);
    throw new Error('Error uploading files');
  }
};
