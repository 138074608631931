import { FC } from 'react';
import { IconButton, useTheme } from '@mui/material';
import useStyles from './ModalLegends.styles.ts';
import { Typo } from 'components/primitive';
import Box from '@mui/material/Box';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import Drawer from '@mui/material/Drawer';
import { LegendStatus, LegendTypes } from './Constants.tsx';
import CloseIcon from '@mui/icons-material/Close';

type ModalMapIdProps = {
  isOpen: boolean;
  onClose: () => void;
};
const ModalLegends: FC<ModalMapIdProps> = ({ isOpen, onClose }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      anchor='right'
      variant='temporary'
      hideBackdrop={true}
      sx={classes.drawerContainer}>
      <>
        <Box sx={classes.headerContainer}>
          <FlagCircleIcon fontSize={'large'} sx={{ color: theme.palette.common.white }} />
          <Typo style={{ color: 'white', fontSize: 14, fontWeight: 500 }}>Legends</Typo>
          <IconButton onClick={() => onClose()} style={{ marginLeft: 'auto' }}>
            <CloseIcon className='icon' />
          </IconButton>
        </Box>
        <Box sx={classes.content}>
          <Box sx={classes.legendContainer}>
            <Typo sx={classes.title}>Type</Typo>
            {LegendTypes.map((legend) => (
              <Box key={legend.id} sx={classes.layerContainer}>
                {legend.icon}
                <Box sx={classes.layerInfo.container}>
                  <Typo sx={classes.layerInfo.title}>{legend.title}</Typo>
                  <Typo sx={classes.layerInfo.subTitle}>{legend.subTitle}</Typo>
                </Box>
              </Box>
            ))}
          </Box>
          <Box sx={classes.legendContainer}>
            <Typo sx={classes.title}>Status</Typo>
            {LegendStatus.map((legend) => (
              <Box key={legend.id} sx={classes.layerContainer}>
                {legend.icon}
                <Box sx={classes.layerInfo.container}>
                  <Typo sx={classes.layerInfo.title}>{legend.title}</Typo>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </>
    </Drawer>
  );
};

export default ModalLegends;
