import { FileType } from 'interfaces/ia.interface.ts';
import { saveOfflineFile } from 'store/file_db/file_db.thunk.ts';
import moment from 'moment/moment';
import { findByQuery, putDocument } from 'store/db/db.thunk.ts';
import { setIADataSet } from 'store/ia/ia.actions.ts';
import { setPADataSet } from 'store/pa/pa.actions.ts';

export const saveFilesToPouchDB = async (
  uploadFiles: FileType[],
  pvGlobalGroupID: string,
  dispatch: any
) => {
  const localUploadedFiles: any[] = [];
  // try {
  for (let i = 0; i < uploadFiles.length; i++) {
    const file = uploadFiles[i];
    const file_name = file.name;
    const fileKey = '' + new Date().getTime() + '__' + file_name;
    await dispatch(
      saveOfflineFile({
        key: fileKey,
        file,
        pvGlobalGroupID,
        name: file.name,
        size: file.size,
      })
    );
    localUploadedFiles.push(file);
  }
  return localUploadedFiles;
  // } catch (error) {
  //   throw error;
  // }
};

//update pouch db item with response from sns
export const updatePouchDBItem = async (
  item: any,
  category: string,
  dispatch: any,
  userId: number
) => {
  item.dma_category = category;
  if (item?.utilities_out)
    item.utilities_out = item.utilities_out.split(',').map((item: any) => item.trim());
  if (item?.insurance_type)
    item.insurance_type = item.insurance_type.split(',').map((item: any) => item.trim());

  item.created_at = moment(item.created_at, 'YYYY-MM-DD HH:mm:ss').toISOString();
  item.updated_at = moment(item.updated_at, 'YYYY-MM-DD HH:mm:ss').toISOString();

  const foundDoc = (await findByQuery({
    selector: { pvGlobalID: item.pvGlobalID },
  })) as any;
  if (foundDoc[0]?.isUnsaved || Number(item.user_id) === userId) {
    //item conflicts with unsaved local item or is same user
    return;
  } else {
    const updatedObject = foundDoc[0] ? { ...foundDoc[0], ...item } : item;
    await putDocument(updatedObject).then(async () => {
      const getItemsFromPouchDB = (await findByQuery({
        selector: { dma_category: category },
      })) as any;
      if (category === 'ia') {
        dispatch(setIADataSet(getItemsFromPouchDB));
      } else if (category === 'pa') {
        dispatch(setPADataSet(getItemsFromPouchDB));
      }
    });
  }
};
