import { createSlice } from '@reduxjs/toolkit';
import { IncidentsInitialState, IncidentsStateTypes } from './incidents.state.ts';
import {
  fetchIncidentForParcelSync,
  fetchJPDAIncidentSetting,
  // fetchSelectedIncidentSetting,
  fetchUsersSavedIncidents,
} from 'store/incidents/incidents.thunk.ts';
import QueryState from '@/common/enum/QueryState.ts';
// import { UserIncidentsType } from 'interfaces/incidents.interface.ts';

const initialState: IncidentsStateTypes = IncidentsInitialState;

export const incidentsSlice = createSlice({
  name: 'incidents',
  initialState,
  reducers: {
    resetIncidents: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchIncidentForParcelSync.pending, (state) => {
        state.queryState = QueryState.AWAIT;
        state.error = null;
        state.selectedIncidentForParcelSyncSettingID = null;
        state.selectedIncidentForParcelSyncID = null;
      })
      .addCase(fetchIncidentForParcelSync.fulfilled, (state, action) => {
        const incident = Array.isArray(action.payload) ? action.payload[0] : action.payload;
        state.queryState = QueryState.READY;
        state.selectedIncidentForParcelSyncSettingID = incident?.pvSettingID ?? null;
        state.selectedIncidentForParcelSyncID = incident?.pvSettingValue ?? null;
      })
      .addCase(fetchUsersSavedIncidents.fulfilled, (state, action) => {
        state.userIncidents = action.payload ?? null;
        const selectedIncidentParcelSyncId = state?.selectedIncidentForParcelSyncID
          ? parseInt(state.selectedIncidentForParcelSyncID)
          : null;
        if (selectedIncidentParcelSyncId) {
          state.selectedIncidentForParcelSync =
            action.payload?.find(
              (incident) => incident.pvIncidentID === selectedIncidentParcelSyncId
            ) ?? null;
        }
      })
      // .addCase(fetchSelectedIncidentSetting.fulfilled, (state, action) => {
      //   const data = action.payload || [];
      //   if (Array.isArray(data) && data.length) {
      //     const settingValue = data[0].pvSettingValue;
      //     if (settingValue.length > 0) {
      //       const IncidentValue = JSON.parse(data[0].pvSettingValue) as UserIncidentsType;
      //       state.selectedIncidentSettingID = data[0].pvSettingID;
      //       state.selectedIncident = IncidentValue;
      //     }
      //   } else {
      //     // *: No saved incident setting found for the user
      //     // *: Set the default incident setting
      //     if (state.userIncidents) {
      //       state.selectedIncident = state.userIncidents[0];
      //     }
      //   }
      // })
      .addCase(fetchJPDAIncidentSetting.fulfilled, (state, action) => {
        state.selectedJPDAIncidentSettingID = action.payload?.selectedJPDAIncidentSettingID ?? null;
        state.selectedJPDAIncidentSettingValue = action.payload?.data ?? null;
        state.isJPDA_IA_Active = action.payload?.isJPDA_IA_Active ?? false;
        state.isJPDA_PA_Active = action.payload?.isJPDA_PA_Active ?? false;
      });
  },
});

export const { resetIncidents } = incidentsSlice.actions;
export default incidentsSlice.reducer;
