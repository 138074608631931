import { Button } from 'components/primitive';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
const appTitle = import.meta.env.VITE_APP_TITLE ?? 'Damage Assessment';
const InfoView: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const message = location.state?.message;
  const buttonText = location.state?.buttonText;
  const action = location.state?.action;

  const handleOnClick = () => {
    if (action === 'reload') {
      navigate('/loading');
    }
  };

  return (
    <Box className='layout vertical center vertical-center full-height'>
      {window.location.href.indexOf('access_token') === -1 ? (
        <div className='layout vertical logincontainer'>
          <div className='layout horizontal center center-justified app-logo-container'>
            <img className='app-logo' src='/images/launcher.png' alt='Palmetto Logo' />
          </div>
          <div className='layout horizontal center-justified'>
            <span className='app-title'>{appTitle}</span>
          </div>
          <div
            style={{
              textAlign: 'center',
              height: '50px',
              paddingTop: '24px',
              color: 'rgba(0,0,0,67)',
              fontSize: '14px',
            }}>
            {message}
          </div>
          <div className='layout horizontal center-justified'>
            <Button
              className='flex login-btn'
              style={{ maxWidth: '220px' }}
              onClick={handleOnClick}>
              {buttonText}
            </Button>
          </div>
        </div>
      ) : (
        <></>
      )}
      <p style={{ fontSize: '12px', color: 'rgba(0,0,0,0.54)', textAlign: 'center' }}>
        All data in Palmetto is to be considered FOUO and not for public dissemination.
      </p>
    </Box>
  );
};

export default InfoView;
