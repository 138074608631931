import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  content: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: '100vh',
    bgcolor: theme.palette.grey[100],
  },
  emptyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '32px',
    padding: '64px',
    height: '65%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
    alignItems: 'center',
    borderRadius: '8px',
    gap: '16px',
    padding: '24px',
    bgcolor: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.grey[100]}`,
  },
  text: {
    color: theme.palette.grey[500],
    fontSize: '14px',
    textAlign: 'center',
  },
});

export default useStyles;
