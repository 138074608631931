import { createSlice } from '@reduxjs/toolkit';

import { TeamInitialState, TeamTypes } from 'store/team/team.state.ts';

const initialState: TeamTypes = TeamInitialState;

export const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    setSelectedTeamState: (state, action) => {
      state.entities = action.payload;
    },
  },
});

export default teamSlice.reducer;
