import { Outlet, useLocation } from 'react-router-dom';
import MapPage from 'pages/Map';

const MapLayout = () => {
  const location = useLocation();
  return (
    <>
      <MapPage />
      <Outlet key={location.key} />
    </>
  );
};

export default MapLayout;
