import { createSlice } from '@reduxjs/toolkit';
import { DBInitialState, DBState } from './db.state.ts';

const initialState: DBState = DBInitialState;

export const dbSlice = createSlice({
  name: 'dbSlice',
  initialState,
  reducers: {
    resetDB: () => {
      return DBInitialState;
    },
  },
  extraReducers() {},
});

export default dbSlice.reducer;
