import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import QueueView from './QueueView.tsx';
import { QueueProps } from './QueueProps.ts';
import { useAppDispatch, useAppSelector } from 'store/hooks.ts';
import { mapInfoSelector } from 'store/map/map.selector.ts';
import { findByQuery, putDocument } from 'store/db/db.thunk.ts';
import { findFileByQuery, updateOfflineFile } from 'store/file_db/file_db.thunk.ts';
import { FileType } from 'interfaces/ia.interface.ts';
import {
  addOrDeleteFilesFunc,
  DateTimeFormat,
  prepareDMAFilesData,
  preparePAFilesData,
  saveFileToPalmettoDB,
} from '@/utilities';
import { toast } from 'react-toastify';
import { ToastMessage } from 'components/primitive';
import { ToastStatus } from '@/common/enum';
import { fetchIADataSet, saveIAData } from 'store/ia/ia.thunk.ts';
import { selectConnectionHealth } from 'store/connection/connection.selector.ts';
import { selectAuthToken } from 'store/authToken/authToken.selector.ts';
import { selectAccount } from 'store/accountsInfo/accountsInfo.selector.ts';
import { useUploadForm } from '@/hooks';
import { fetchPADataSet, savePAData } from 'store/pa/pa.thunk.ts';

const QueueContainer: React.FC = () => {
  const navigate = useNavigate();
  const categorySelected = useAppSelector(mapInfoSelector).category;
  const authToken = useAppSelector(selectAuthToken);
  const palmettoUser = useAppSelector(selectAccount);
  const { uploadForm } = useUploadForm<FileType>();
  const dispatch = useAppDispatch();
  const healthRef = useRef(false);
  healthRef.current = useAppSelector(selectConnectionHealth);
  const [items, setItems] = useState<any[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [dataSaved, setDataSaved] = useState<boolean>(false);
  const [unsavedItems, setUnsavedItems] = useState(0);
  const [savedItems, setSavedItems] = useState(1);
  const [unsavedFilesForItem, setUnsavedFilesForItem] = useState(0);
  const [savedFilesForItem, setSavedFilesForItem] = useState(1);

  const goBack = () => {
    navigate(-1);
  };

  const onSyncData = async () => {
    if (healthRef.current) {
      //get all unsaved data from local db
      const localSavedData = (await findByQuery({
        selector: { isUnsaved: true, dma_category: categorySelected },
      })) as any[];
      setUnsavedItems(localSavedData.length);

      for (let i = 0; i < localSavedData.length; i++) {
        setSavedFilesForItem(0);
        const item = localSavedData[i];
        const pvGlobalID = item.pvGlobalID;
        const deletedFiles = item?.filesToDelete?.length ? item.filesToDelete : [];
        //find files associated with the globalId
        const filesArr = (await findFileByQuery({
          selector: { pvGlobalGroupID: pvGlobalID, isUnsaved: true },
        })) as any;
        setUnsavedFilesForItem(filesArr.length);

        const savedFiles: FileType[] = [];
        if (!authToken || !palmettoUser || !pvGlobalID) return;
        //save files to palmetto and flag them as saved in local db
        for (let i = 0; i < filesArr.length; i++) {
          const file = filesArr[i];
          const binaryFile = new File([file.file], file.name, { type: file.type });
          try {
            await saveFileToPalmettoDB(
              binaryFile,
              authToken,
              pvGlobalID,
              palmettoUser.id,
              palmettoUser.username,
              uploadForm
            ).then((res) => {
              savedFiles.push(res);
              //update the file in local db to reflect that it has been saved
              file.isUnsaved = false;
              updateOfflineFile(file).then(() => {
                setSavedFilesForItem((prev) => {
                  if (prev < filesArr.length) {
                    return prev + 1;
                  } else {
                    return prev;
                  }
                });
              });
            });
          } catch {
            toast.error(
              <ToastMessage status={ToastStatus.ERROR} message={`Error saving file ${file.name}`} />
            );
          }
        }
        delete item.isUnsaved;
        const payload = {
          ...item,
          deleted: 0,
          user_id: palmettoUser?.id ?? 0,
          files:
            categorySelected === 'ia'
              ? prepareDMAFilesData(savedFiles, 'ia')
              : preparePAFilesData(savedFiles),
        } as any;
        if (categorySelected === 'pa') {
          payload.date = DateTimeFormat({ dte: item.date, format: 'YYYY-MM-DD HH:mm:ss' });
        }
        try {
          await addOrDeleteFilesFunc({
            filesToAdd: savedFiles || [],
            filesToDelete: deletedFiles,
            uuid: pvGlobalID,
            authToken,
          });

          if (healthRef.current) {
            if (categorySelected === 'ia') {
              await dispatch(saveIAData(payload)).then(() => {
                setSavedItems((prev) => {
                  if (prev < localSavedData.length) {
                    return prev + 1;
                  } else {
                    return prev;
                  }
                });
              });
            } else {
              await dispatch(savePAData(payload)).then(() => {});
              setSavedItems((prev) => {
                if (prev < localSavedData.length) {
                  return prev + 1;
                } else {
                  return prev;
                }
              });
            }
            delete item.isUnsaved;
            item.filesToDelete = [];
            const updatedItem = { ...item };
            await putDocument(updatedItem);
          } else {
            toast.error(<ToastMessage status={ToastStatus.ERROR} message='Connection Error' />);
            setIsSaving(false);
          }
        } catch (error) {
          setIsSaving(false);
          console.log(error);
          toast.error(
            <ToastMessage status={ToastStatus.ERROR} message='Encountered System Error' />
          );
        }
      }
    } else {
      toast.error(<ToastMessage status={ToastStatus.ERROR} message='Connection Error' />);
      setIsSaving(false);
    }
  };

  const handleSyncData = async () => {
    setIsSaving(true);
    await onSyncData();
    const fetchData = async () => {
      await dispatch(fetchIADataSet());
      await dispatch(fetchPADataSet());
    };
    await fetchData();
    setDataSaved(true);
  };

  const combinedProps: QueueProps = {
    goBack,
    items,
    categorySelected,
    handleSyncData,
    isSaving,
    unsavedItems,
    savedItems,
    unsavedFilesForItem,
    savedFilesForItem,
  };

  useEffect(() => {
    if (!categorySelected) return;
    const getData = async () => {
      const dataFromPouchDB = (await findByQuery({
        selector: { dma_category: categorySelected, isUnsaved: true },
      })) as any;
      setItems(dataFromPouchDB);
    };
    getData().then(() => {
      setIsSaving(false);
    });
  }, [categorySelected, dataSaved]);

  return <QueueView {...combinedProps} />;
};

export default QueueContainer;
