import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    bgcolor: theme.palette.secondary.main,
    gap: '4px',
    alignItems: 'flex-start',
    alignContent: 'space-between',
    borderRadius: '6px',
    border: '1px solid #E0E0E0',
    padding: '8px',
    position: 'relative',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    flexWrap: 'wrap',
  },
  mediaCapture: {
    width: '320px',
    height: '320px',
  },
});

export default useStyles;
