// import { RootState } from '@/store';

import type { RootState } from '@/store';
import { TeamInterface } from 'interfaces/team.interface.ts';

export const teamsSelector = (state: RootState): TeamInterface[] => {
  const teams = state.teams.entities;
  if (!teams) {
    return [];
  }
  return teams.slice().sort((a, b) => a.team_name.localeCompare(b.team_name));
};
