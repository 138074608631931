/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { MapPageViewProps } from './Map.props.ts';
import useStyles from './Map.styles.ts';
import { DualTitleHeaderComponent } from '@zawarski/palmetto-ui-components';
import Box from '@mui/material/Box';
import { LinearProgress, useTheme, Menu, MenuItem, Typography } from '@mui/material';
import Map from 'components/fragment/Map/Map.tsx';
import { SuffixButtons } from './components';
import { Button, Typo, ModalSelect } from 'components/primitive';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { FormProvider } from 'react-hook-form';
import { ConfirmModal, SelectTeamModal } from 'components/fragment';
import { useAppSelector } from 'store/hooks.ts';
import { iaProgressSelector, iaSyncProgressSelector } from 'store/ia/ia.selector.ts';
import { paProgressSelector, paSyncProgressSelector } from 'store/pa/pa.selector.ts';
import { useMediaQuery } from '@/hooks';
import { MOBILE_SIZE } from 'hooks/useMediaQuery.ts';
import CloseIcon from '@mui/icons-material/Close';

const MapPageView: React.FC<MapPageViewProps> = ({
  data,
  needsDownload,
  handleDownloadParcel,
  isFetching,
  isIAFetching,
  isPAFetching,
  center,
  roadData,
  parcelData,
  form,
  handleModalAction,
  categorySelected,
  dropMarkerMode,
  quickAssessmentMode,
  iaDataSet,
  paDataSet,
  activeModal,
  setActiveModal,
  processingData,
  handleHeaderMenuClick,
  headerMenuAnchorEl,
  handleHeaderCloseMenu,
  headerMenuOptions,
  mapID,
  confirmModalInfo,
  handleOnConfirm,
  confirmLoading,
  parcelsToResetStyle,
  nonParcelMode,
  countyGroupName,
  // selectedApplicant,
  isDataLoading,
  hasFetchedGroups,
  noCoordsPaData,
  setNoCoordsPaData,
  applicantsOptions,
  health,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const iaProgress = useAppSelector(iaProgressSelector);
  const iaSyncProgress = useAppSelector(iaSyncProgressSelector);
  const paProgress = useAppSelector(paProgressSelector);
  const paSyncProgress = useAppSelector(paSyncProgressSelector);
  const { isMobileDevice, windowDimensions } = useMediaQuery();
  const isMobile =
    isMobileDevice || (windowDimensions?.width !== null && windowDimensions.width < MOBILE_SIZE);
  const categoryName = categorySelected === 'ia' ? 'Individual Assistance' : 'Public Assistance';
  const groupName = data.groupName.toLowerCase();

  const title = isMobile
    ? 'Damage Assessment'
    : `Damage Assessment ${countyGroupName ? 'for ' + countyGroupName.charAt(0).toUpperCase() + countyGroupName.slice(1) : ''}${categorySelected ? ', ' + categoryName : ''}`;
  const subTitle = `${countyGroupName ? 'for ' + countyGroupName.charAt(0).toUpperCase() + countyGroupName.slice(1) : ''}${categorySelected ? ', ' + categoryName : ''}`;
  return (
    <Box className='layout vertical full-height' style={{ position: 'relative' }}>
      <DualTitleHeaderComponent
        titleBig={
          <Typo style={{ fontSize: isMobile ? 16 : 20, fontWeight: 500, marginLeft: '16px' }}>
            {title}
          </Typo>
        }
        titleSmall={
          isMobile ? (
            <Typo style={{ fontSize: isMobile ? 12 : 16, fontWeight: 500, marginLeft: '16px' }}>
              {subTitle}
            </Typo>
          ) : (
            ''
          )
        }
        suffix={
          !needsDownload ? <SuffixButtons handleHeaderMenuClick={handleHeaderMenuClick} /> : null
        }
      />
      {isDataLoading && (
        <Box
          sx={{
            width: '100%',
            padding: '16px',
            boxSizing: 'border-box',
          }}>
          {isIAFetching && iaSyncProgress ? (
            <Typography variant='body1' sx={{ display: 'inline' }}>
              Updating local IA Data: {iaSyncProgress}%
            </Typography>
          ) : isPAFetching && paSyncProgress ? (
            <Typography variant='body1' sx={{ display: 'inline' }}>
              Updating local PA Data: {paSyncProgress}%
            </Typography>
          ) : (
            <Typography variant='body1' sx={{ display: 'inline' }}>
              Fetching {isIAFetching ? 'IA ' : 'PA '}Data: {isIAFetching ? iaProgress : paProgress}%
            </Typography>
          )}
          {!iaDataSet?.length && !paDataSet?.length && (
            <Typography variant='body1'>
              {'Data on map will be visible after the update.'}
            </Typography>
          )}
          <LinearProgress
            variant='determinate'
            value={
              isIAFetching
                ? iaSyncProgress
                  ? iaSyncProgress
                  : iaProgress
                : paSyncProgress
                  ? paSyncProgress
                  : paProgress
            }
            sx={{ height: '10px', borderRadius: '5px' }}
          />
        </Box>
      )}
      {!!noCoordsPaData && !!paDataSet?.length && categorySelected === 'pa' && !isDataLoading && (
        <Box
          sx={{
            width: '100%',
            padding: '16px',
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Typography variant='body1' sx={{ display: 'inline' }}>
            {`There are ${noCoordsPaData} PA items missing coordinates out of a total of ${paDataSet?.length}. These items will not show
            on map.`}
          </Typography>
          <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => setNoCoordsPaData(0)} />
        </Box>
      )}
      {needsDownload && (
        <Box sx={classes.emptyContainer}>
          <Box sx={classes.wrapper}>
            {!isFetching && hasFetchedGroups && (!groupName || groupName === 'scemd') ? (
              <>
                <Typo sx={classes.text}>
                  Please select a county from the main Damage Assessment.
                </Typo>
              </>
            ) : (
              <>
                <CloudDownloadIcon sx={{ color: '#C2C2C2', fontSize: '42px' }} />
                <Typo sx={classes.text}>
                  Download{' '}
                  <span style={{ textTransform: 'uppercase', fontSize: 16, fontWeight: 700 }}>
                    {groupName}
                  </span>{' '}
                  {nonParcelMode ? 'road' : 'parcel'} data to use Damage Assessment application.
                  This may take a few minutes.
                </Typo>
                {!isFetching ? (
                  <Button onClick={handleDownloadParcel}>DOWNLOAD</Button>
                ) : (
                  <Box sx={{ width: '75%' }}>
                    <LinearProgress />
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
      )}
      {!needsDownload && roadData && (
        <>
          <Box sx={classes.content}>
            <Map
              groupName={groupName}
              center={center}
              data={{ parcel: parcelData, roads: roadData }}
              dropMarkerMode={dropMarkerMode}
              quickAssessmentMode={quickAssessmentMode}
              iaDataSet={iaDataSet}
              paDataSet={paDataSet}
              categorySelected={categorySelected}
              mapID={mapID}
              parcelsToResetStyle={parcelsToResetStyle}
              nonParcelMode={nonParcelMode}
              isDataLoading={isDataLoading}
            />
          </Box>
          {activeModal === 'category' && (
            <FormProvider {...form}>
              <ModalSelect
                headerTitle={'Select category'}
                isSaving={processingData.applicant}
                optionData={'categoryOptions'}
                onClose={() => {}}
                onConfirm={handleModalAction}
                hideClose={true}
                secondOptionsData={applicantsOptions}
                health={health}
              />
            </FormProvider>
          )}
          {activeModal === 'team' && <SelectTeamModal onClose={setActiveModal} />}
          {activeModal === 'confirmUpdate' && (
            <ConfirmModal
              onConfirm={handleOnConfirm}
              onClose={setActiveModal}
              title={confirmModalInfo.title}
              message={confirmModalInfo.message}
              allowProceed={confirmModalInfo.allowProceed}
              loading={confirmLoading}
            />
          )}
          {activeModal === 'about' && (
            <ConfirmModal
              onConfirm={handleOnConfirm}
              onClose={setActiveModal}
              title={confirmModalInfo.title}
              message={confirmModalInfo.message}
              allowProceed={confirmModalInfo.allowProceed}
              loading={confirmLoading}>
              {confirmModalInfo.children}
            </ConfirmModal>
          )}
          {activeModal === 'confirmLogout' && (
            <ConfirmModal
              onConfirm={handleOnConfirm}
              onClose={setActiveModal}
              title={confirmModalInfo.title}
              message={confirmModalInfo.message}
              allowProceed={confirmModalInfo.allowProceed}
              loading={confirmLoading}
            />
          )}
          {activeModal === 'applicants' && !needsDownload && (
            <FormProvider {...form}>
              <ModalSelect
                headerTitle={'Select applicant for Public Assistance'}
                isSaving={processingData.applicant}
                optionData={'applicantsOptions'}
                onClose={setActiveModal}
                onConfirm={handleModalAction}
                hideClose={true}
                health={health}
              />
            </FormProvider>
          )}
        </>
      )}
      <Menu
        id='overflow_menu'
        anchorEl={headerMenuAnchorEl}
        open={Boolean(headerMenuAnchorEl)}
        onClose={handleHeaderCloseMenu}>
        {headerMenuOptions.map((item: any, index: number) => (
          <MenuItem
            key={index}
            onClick={() => {
              item.onClick();
              handleHeaderCloseMenu();
            }}>
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default MapPageView;
