/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { AxiosRequestConfig } from 'axios';
import { DMAFileTypes, FileType } from 'interfaces/ia.interface.ts';
import { bytesToSize, getProperKeyNameDummySearchString, spliceDate } from '@/utilities';
import { Box, useTheme, Grid } from '@mui/material';
import useStyles from './Documents.styles';
import { Typo } from 'components/primitive';
import { DropFileZone, FileContainer } from 'components/fragment';
import { useUploadForm } from '@/hooks';
import { useAppSelector } from 'store/hooks.ts';
import { selectConnectionHealth } from 'store/connection/connection.selector.ts';
import { findFileByQuery } from 'store/file_db/file_db.thunk.ts';
import { findByQuery } from 'store/db/db.thunk.ts';
import { IUserPortal } from 'interfaces/UserAccountInfo.interface.ts';

type DocumentsProps = {
  readOnly: boolean;
  uuid: string;
  user: IUserPortal;
  authToken: string;
  pvDataID: number | string;
  title?: string;
  defaultHeaderTitle?: string;
  defaultReadOnlyTitleTitle?: string;
  filesAdded: DMAFileTypes[] | any[];
  setFilesAdded: React.Dispatch<React.SetStateAction<any>>;
  filesDeletedCallback: React.Dispatch<React.SetStateAction<any>>;
  filesDeleted: DMAFileTypes[] | any[];
  localFilesDeletedCallback: React.Dispatch<React.SetStateAction<any>>;
  hasOldData?: boolean;
  files?: DMAFileTypes[];
};

const palmettoBaseURL = import.meta.env.VITE_PALMETTO_ENDPOINT;
const Documents: React.FC<DocumentsProps> = ({
  readOnly,
  title = 'Documents',
  defaultHeaderTitle = 'File Upload',
  defaultReadOnlyTitleTitle = 'Please upload a document.',
  setFilesAdded,
  filesAdded,
  filesDeletedCallback,
  filesDeleted,
  localFilesDeletedCallback,
  hasOldData,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const {
    progress,
    downloadForm,
    documents: uploadedDocuments,
    inProgress,
    setLocalDocuments,
    localDocuments,
  } = useUploadForm<DMAFileTypes>(!!hasOldData);
  const healthRef = useRef(false);
  healthRef.current = useAppSelector(selectConnectionHealth);
  const [filesDocDeleted, setFilesDocDeleted] = useState<FileType[]>([]);
  const [uploadedFilesFromPouch, setUploadedFilesFromPouch] = useState<any[]>([]);
  const [filteredUploadedFiles, setFilteredUploadedFiles] = useState<any[]>([]);

  useEffect(() => {
    if (uploadedDocuments?.length && filesDeleted?.length) {
      //filter the response to remove files that are set to be deleted
      const filteredFiles = uploadedDocuments.filter(
        (file) => !filesDeleted.some((deletedFile) => deletedFile.pvGlobalID === file.pvGlobalID)
      );
      setFilteredUploadedFiles(filteredFiles);
    } else {
      setFilteredUploadedFiles(uploadedDocuments);
    }
  }, [uploadedDocuments, filesDeleted]);

  useEffect(() => {
    const fetchData = (url: string) => {
      const config: AxiosRequestConfig = {
        headers: { 'Content-Type': 'application/json' },
      };
      downloadForm(url, config, props.files || []).catch((err) => {
        console.error('🚀 Documents.tsx fetchData err: ', err);
      });
    };

    const getPouchDBFiles = async (pvGlobalID: string) => {
      const filesArr = (await findFileByQuery({
        selector: { pvGlobalGroupID: pvGlobalID, isUnsaved: true },
      })) as any[];
      return filesArr || [];
    };

    const getPouchDBItemFiles = async (pvGlobalID: string) => {
      const item = (await findByQuery({
        selector: { pvGlobalID: pvGlobalID },
      })) as any;
      if (item[0].files?.length) {
        return item[0].files;
      } else return [];
    };

    if (props.uuid && props.authToken) {
      //if online fetch files from palmetto, else serve from PouchDB
      if (healthRef.current) {
        const url = `${palmettoBaseURL}/api/files?access_token=${props.authToken}&filter=${JSON.stringify({ where: { pvGlobalGroupID: props.uuid } })}`;
        fetchData(url);
      } else {
        getPouchDBItemFiles(props.uuid).then((files) => {
          setUploadedFilesFromPouch(files);
        });
      }
      getPouchDBFiles(props.uuid).then((localDocs) => {
        for (let i = 0; i < localDocs.length; i++) {
          const doc = localDocs[i];
          const extensions = ['jpeg', 'jpg', 'png', 'bmp'];
          if (extensions.some((extension) => doc.name.includes(extension))) {
            const binaryFile = new File([doc.file], doc.name, { type: doc.type });
            doc.downloadLink = URL.createObjectURL(binaryFile);
          }
        }
        setLocalDocuments(localDocs);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.uuid, props.authToken]);

  const downloadFile = (item: DMAFileTypes) => {
    const payload = item;
    const a = document.createElement('a');
    a.href =
      (palmettoBaseURL || window.origin) +
      payload?.cbrnDataFileURIID +
      '?access_token=' +
      props.authToken;
    a.target = '_blank';
    a.download = spliceDate(payload?.cbrnDataFileURIID ?? '');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const removeFile = (fileToRemove: FileType) => {
    const arr = filteredUploadedFiles;
    const fileToDelete: any[] | FileType[] = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].pvDataID === fileToRemove.pvDataID) {
        fileToDelete.push(arr[i]);
        arr.splice(i, 1);
      }
    }
    setFilteredUploadedFiles((prevFiles) =>
      prevFiles.filter((file) => file.pvDataID !== fileToRemove.pvDataID)
    );
    const arr2 = [...filesDocDeleted, ...fileToDelete];
    setFilesDocDeleted(arr2);
    filesDeletedCallback && filesDeletedCallback(() => arr2);
  };

  const removeAlreadyUploadedFilesOffline = (fileToRemove: any) => {
    const arr = uploadedFilesFromPouch;
    const fileToDelete: any[] | FileType[] = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].pvGlobalID === fileToRemove.pvGlobalID) {
        fileToDelete.push(arr[i]);
        arr.splice(i, 1);
      }
    }
    setUploadedFilesFromPouch((prevFiles) =>
      prevFiles.filter((file) => file.pvGlobalID !== fileToRemove.pvGlobalID)
    );
    const arr2 = [...filesDocDeleted, ...fileToDelete];
    setFilesDocDeleted(arr2);
    filesDeletedCallback && filesDeletedCallback(() => arr2);
  };

  const removeLocalFile = async (fileToRemove: any) => {
    const arr = localDocuments;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i]._id === fileToRemove._id) {
        localFilesDeletedCallback((prevFiles: any) => [...prevFiles, arr[i]]);
        const updatedLocalDocuments = arr.filter((file) => file._id !== fileToRemove._id);
        setLocalDocuments(updatedLocalDocuments);
      }
    }
  };

  const removeNewAddedFiles = (fileToRemove: any) => {
    const updatedFiles = filesAdded?.filter((file: any) => file.localId !== fileToRemove.localId);
    setFilesAdded(updatedFiles);
  };

  const localFilesRender = localDocuments?.map((file: any, idx: number) => {
    return (
      <FileContainer
        readOnly={readOnly}
        authToken={props.authToken}
        key={idx}
        name={getProperKeyNameDummySearchString(file?.name ?? '')}
        item={file}
        bytes={bytesToSize(Number(file?.size) ?? 0)}
        removeFile={(item) => removeLocalFile(item)}
      />
    );
  });

  const filesAddedRender = filesAdded?.map((file: any, idx: number) => {
    return (
      <FileContainer
        readOnly={readOnly}
        authToken={props.authToken}
        key={idx}
        name={getProperKeyNameDummySearchString(file?.name ?? '')}
        item={file}
        bytes={bytesToSize(Number(file?.size) ?? 0)}
        removeFile={(item) => removeNewAddedFiles(item)}
      />
    );
  });

  const uploadedFilesRender = filteredUploadedFiles?.map((file, index) => {
    if (!file?.pvVoid) {
      return (
        <FileContainer
          readOnly={readOnly}
          authToken={props.authToken}
          key={index}
          item={file}
          name={getProperKeyNameDummySearchString(file?.cbrnDataFileName ?? '')}
          bytes={bytesToSize(file?.cbrnDataFileSize ?? 0)}
          downloadFile={downloadFile}
          removeFile={(item) => removeFile(item)}
        />
      );
    }
    return <></>;
  });

  const uploadedFilesFromPouchRender = uploadedFilesFromPouch?.map((file, index) => {
    return (
      <FileContainer
        readOnly={readOnly}
        authToken={props.authToken}
        key={index}
        item={file}
        name={getProperKeyNameDummySearchString(file?.file_name || file?.cbrnDataFileName || '')}
        bytes={bytesToSize(file?.file_size || file?.cbrnDataFileSize || 0)}
        downloadFile={downloadFile}
        removeFile={(item) => removeAlreadyUploadedFilesOffline(item)}
        imagePreview={false}
      />
    );
  });

  return (
    <Box sx={classes.container} hidden={inProgress}>
      <Typo sx={classes.title}>
        {filteredUploadedFiles.length || localDocuments.length
          ? title
          : readOnly
            ? defaultReadOnlyTitleTitle
            : defaultHeaderTitle}
      </Typo>
      <Box sx={classes.content}>
        <Grid container spacing={2}>
          <>
            {!inProgress && healthRef && filteredUploadedFiles?.length ? uploadedFilesRender : null}
          </>
          <>
            {!inProgress && !healthRef.current && uploadedFilesFromPouchRender?.length
              ? uploadedFilesFromPouchRender
              : null}
          </>
          <>{!inProgress && localDocuments?.length ? localFilesRender : null}</>
          <>{!inProgress && filesAdded?.length ? filesAddedRender : null}</>
        </Grid>
        {filteredUploadedFiles.length === 0 && localDocuments.length === 0 ? (
          <Box sx={classes.empty}>
            <Typo sx={classes.emptyText}>No document added</Typo>
          </Box>
        ) : null}
      </Box>
      {!readOnly && (
        <Box sx={classes.content}>
          <DropFileZone
            readOnly={readOnly}
            uuid={props.uuid}
            authToken={props.authToken}
            user={props.user}
            setFilesAdded={setFilesAdded}
            filesDeletedCallback={filesDeletedCallback}
            inProgress={inProgress}
            uploadedDocuments={filteredUploadedFiles}
            localDocuments={localDocuments}
            progress={progress}
          />
        </Box>
      )}
    </Box>
  );
};

export default Documents;
