import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import authTokenReducer from './authToken/authToken.slice.ts';
import userAccountReducer from './userAccount/userAccount.slice.ts';
import mapReducer from './map/map.slice.ts';
import accountsInfoReducer from './accountsInfo/accountsInfo.slice.ts';
import incidentsReducer from './incidents/incidents.slice.ts';
import connectionReducer from './connection/connection.slice.ts';
import iaReducer from './ia/ia.slice.ts';
import paReducer from './pa/pa.slice.ts';
import dbReducer from './db/db.slice.ts';
import applicantsReducer from './applicants/applicants.slice.ts';
import teamsReducer from './teams/teams.slice.ts';
import teamReducer from './team/team.slice.ts';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: [
    'authToken',
    // 'accountsInfo',
    // 'incidents',
    'userAccount',
    'map',
    // 'ia',
    // 'pa',
    'db',
    // 'applicants',
    'team',
    'teams',
  ],
};

const rootReducer = combineReducers({
  authToken: authTokenReducer,
  accountsInfo: accountsInfoReducer,
  incidents: incidentsReducer,
  userAccount: userAccountReducer,
  map: mapReducer,
  connection: connectionReducer,
  ia: iaReducer,
  pa: paReducer,
  db: dbReducer,
  applicants: applicantsReducer,
  teams: teamsReducer,
  team: teamReducer,
});

const NODE_ENV = import.meta.env.MODE;
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
// noinspection JSUnusedGlobalSymbols
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action>;
