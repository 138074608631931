import { CountiesWithParcelData } from '@/data';

export function isCountyWithParcel(groupName: string) {
  if (groupName) {
    return CountiesWithParcelData.map((county) => county.toLowerCase()).includes(
      groupName.toLowerCase().trim()
    );
  }
  return false;
}
