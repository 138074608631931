import theme from '@/theme';

const useReloadPromptView = () => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    padding: '16px',
    maxHeight: '600px',
    // minWidth: '450px',
    width: '100%',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '16px',
    mt: '10px',
  },
  footerWrapper: {
    display: 'flex',
    width: '100%',
    gap: '8px',
  },
  title: {
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.grey[400],
    padding: '0',
  },
});

export default useReloadPromptView;
