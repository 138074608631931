import React from 'react';
import Modal, { ModalProps } from '@mui/material/Modal';
import { Backdrop, Fade, Paper } from '@mui/material';
import useStyles from './Modal.styles.ts';

interface ModalContainerProps extends ModalProps {
  contentStyle?: object;
}

const ModalContainer: React.FC<ModalContainerProps> = (props) => {
  const classes = useStyles();
  return (
    <Modal
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      {...props}>
      <Fade in={props.open}>
        <Paper sx={props.contentStyle ? props.contentStyle : classes.modalContent} elevation={1}>
          {props.children}
        </Paper>
      </Fade>
    </Modal>
  );
};

export default ModalContainer;
