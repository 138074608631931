import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { array, number, object, string } from 'yup';
import { Nullable } from 'interfaces/Nullable.ts';
import { yupResolver } from '@hookform/resolvers/yup';
import { BuildingDamageTypes } from '@/data/LegendValues/BuildingDamage.ts';

export interface IAFormData {
  id: string;
  incident_id: number;
  user_id: Nullable<number>;
  group_id: number;
  user_group_id: Nullable<number>;
  pvGlobalID: string;
  parcel_id: Nullable<string>;
  occupant_name: Nullable<string>;
  address: Nullable<string>;
  address2: Nullable<string>;
  city: Nullable<string>;
  isolated: 'Yes' | 'No' | null;
  gvnt_owned_property: null;
  req_dnr_assessment: null;
  county: Nullable<string>;
  state: Nullable<string>;
  zipCode: Nullable<string>;
  latitude: Nullable<number>;
  longitude: Nullable<number>;
  structure_type: string;
  residence_status: string;
  building_damage: BuildingDamageTypes;
  posting: string;
  insurance_type: string[];
  utilities_out: Nullable<string[]>;
  water_level: Nullable<string>;
  house_type: string;
  replacement_cost: Nullable<number>;
  estimated_loss: Nullable<number>;
  criteria_met: string;
  comments: string;
  assessment_team_member: string;
  water_level_units: string;
  jpda_status: Nullable<string>;
  jpda_assessment: Nullable<string>;
  pvParcelGeometryObject: Nullable<object>;
  applicant_name: Nullable<string>;
  state_modified: null;
  has_files?: number;
}

export const defaultValues: IAFormData = {
  id: '',
  incident_id: 0,
  group_id: 0,
  user_id: 0,
  user_group_id: null,
  occupant_name: '',
  address: '',
  address2: '',
  city: '',
  county: '',
  state: '',
  zipCode: '',
  latitude: 0,
  longitude: 0,
  structure_type: '',
  residence_status: 'unknown',
  building_damage: BuildingDamageTypes.Empty,
  isolated: null,
  insurance_type: ['Unknown'],
  water_level: '',
  house_type: '',
  gvnt_owned_property: null,
  req_dnr_assessment: null,
  replacement_cost: 0,
  estimated_loss: 0,
  criteria_met: 'no',
  comments: '',
  assessment_team_member: '',
  parcel_id: null,
  posting: '',
  pvGlobalID: '',
  pvParcelGeometryObject: null,
  state_modified: null,
  water_level_units: 'feet',
  utilities_out: [],
  applicant_name: '',
  jpda_status: '',
  jpda_assessment: '',
  has_files: 0,
};

function useIAForm() {
  const validationSchema = useMemo(
    () =>
      object().shape({
        id: string(),
        pvGlobalID: string(),
        parcel_id: string().nullable(),
        occupant_name: string().nullable(),
        address: string().nullable(),
        address2: string().nullable(),
        city: string().nullable(),
        county: string().nullable(),
        state: string().nullable(),
        zipCode: string()
          .nullable()
          .test(
            'length',
            'Zip code must be exactly 5 characters',
            (val) => !val || val.length === 5
          ),
        latitude: number().nullable(),
        longitude: number().nullable(),
        structure_type: string().required('Please select structure type'),
        residence_status: string().oneOf(
          ['owned', 'rented', 'unknown'],
          'Please select occupancy type'
        ),
        building_damage: string().required('Please select building damage'),
        insurance_type: array().min(1, 'Please select insurance'),
        water_level: string().nullable(),
        house_type: string().nullable(),
        replacement_cost: number().nullable(),
        estimated_loss: number().nullable(),
        criteria_met: string().oneOf(['no', 'yes'], 'Please select criteria met'),
        comments: string().nullable(),
        assessment_team_member: string().required('Please enter assessment team member'),
        posting: string().nullable(),
        utilities_out: array().nullable(),
      }),
    []
  );

  return useForm<any>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
}

export default useIAForm;
