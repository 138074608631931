import { createAsyncThunk } from '@reduxjs/toolkit';

import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import { getTeamsWithFilter } from '@/services/teams.endpoints.ts';
import type { RootState } from '@/store';
import { GetTeamsInterface } from 'interfaces/team.interface.ts';

export const fetchAllTeams = createAsyncThunk('fetchAllTeams', async (_arg: void, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;

    const { selectedGroupID } = state.accountsInfo;
    const { selectedIncidentForParcelSyncID } = state.incidents;

    if (!selectedGroupID || !selectedIncidentForParcelSyncID)
      throw Error('Selected group or incident is not defined');

    const endpoint = getTeamsWithFilter(selectedGroupID, selectedIncidentForParcelSyncID);
    return await apiFetch<GetTeamsInterface>(endpoint).then((res) => res?.data || null);
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});
