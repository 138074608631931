import { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Loading } from 'components/primitive';
import IAmultiDetailsPage from './IAmultiDetailsPage.tsx';
import { ParcelData } from 'components/fragment/ParcelViewModal/ParcelViewModal.props.tsx';

const IAmultiDetailsContainer: FC = () => {
  const params = useParams();
  const location = useLocation();
  const multiParcelsDataState = location.state?.multiParcelsData;
  const [loading, setLoading] = useState(true);
  const [multiParcelsData, setMultiParcelsData] = useState<ParcelData[]>([]);

  useEffect(() => {
    if (multiParcelsDataState) {
      setMultiParcelsData(multiParcelsDataState);
      setLoading(false);
    }
  }, [params, multiParcelsDataState]);

  return (
    <>
      {loading && <Loading message={'Loading data'} />}
      {!loading && <IAmultiDetailsPage multiParcelsData={multiParcelsData} />}
    </>
  );
};

export default IAmultiDetailsContainer;
