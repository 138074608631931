import { createSlice } from '@reduxjs/toolkit';
import { ConnectionInitialState, ConnectionState } from './connection.state.ts';

const initialState: ConnectionState = ConnectionInitialState;

export const checkHealthSlice = createSlice({
  name: 'checkHealth',
  initialState,
  reducers: {
    setHealth: (state, data) => {
      state.health = data.payload;
    },
    setIsInitApiLoading: (state, data) => {
      state.isInitApiLoading = data.payload;
    },
    setWSConnection: (state, data) => {
      state.isWSConnected = data.payload;
    },
  },
});

export default checkHealthSlice.reducer;
