import { Button, FormGroup, Modal, RadioButton, Typo } from 'components/primitive';
import { HeaderComponent } from '@zawarski/palmetto-ui-components';
import { Grid, IconButton, RadioGroup, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { SelectTeamModalProps } from 'components/fragment/SelectTeamModal/SelectTeamModal.props.tsx';
import { useSelectTeamModal } from 'components/fragment/SelectTeamModal/SelectTeamModal.style.ts';
import { useAppDispatch, useAppSelector } from 'store/hooks.ts';
import { teamsSelector } from 'store/teams/teams.selector.ts';
import { setSelectedTeamState } from 'store/team/team.actions.ts';
import { teamSelectedSelector } from 'store/team/team.selector.ts';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export function SelectTeamDialog({ onClose }: SelectTeamModalProps) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const classes = useSelectTeamModal();
  const teams = useAppSelector(teamsSelector);
  const selectedTeam = useAppSelector(teamSelectedSelector);

  const validationSchema = useMemo(
    () =>
      object().shape({
        teamID: string().required('Please select a team'),
      }),
    []
  );

  const { control, handleSubmit } = useForm<{
    teamID: string;
  }>({
    defaultValues: {
      teamID: selectedTeam?.id,
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<{ teamID: string }> = (data) => {
    const findTeamById = teams!.find((team) => team.id === data.teamID);
    dispatch(setSelectedTeamState(findTeamById));
    onClose(null);
  };

  return (
    <Modal open={true} onClose={undefined}>
      <>
        <HeaderComponent
          title={`Select Team`}
          icon={
            <IconButton
              onClick={() => {
                onClose(null);
              }}>
              <CloseIcon className='icon' />
            </IconButton>
          }
        />

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={classes.content}>
            {teams && teams?.length !== 0 && (
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name='teamID'
                  render={({ field: { onChange, value }, fieldState: { error } }) => {
                    return (
                      <FormGroup label='Teams' error={error}>
                        <RadioGroup
                          aria-labelledby='controlled-radio-buttons-group'
                          name='controlled-radio-buttons-group'
                          onChange={onChange}
                          value={value ?? ''}
                          sx={classes.groupRadioContainer}>
                          {teams.map((team) => {
                            return (
                              <RadioButton key={team.id} value={team.id} label={team.team_name} />
                            );
                          })}
                        </RadioGroup>
                      </FormGroup>
                    );
                  }}
                />
              </Grid>
            )}
            {teams?.length === 0 && (
              <Box sx={classes.title}>
                <Typo variant='body2'>No teams found</Typo>
              </Box>
            )}
          </Box>

          <Box sx={classes.footer}>
            <Box sx={classes.footerWrapper}>
              <Button
                fullWidth
                neutral
                sx={{ color: theme.palette.text.secondary }}
                onClick={() => {
                  onClose(null);
                }}>
                Close
              </Button>
              <Button fullWidth type={'submit'}>
                Select
              </Button>
            </Box>
          </Box>
        </form>
      </>
    </Modal>
  );
}

export default SelectTeamDialog;
