const useStyles = () => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    padding: '16px',
    maxHeight: '600px',
    // minWidth: '450px',
    width: '100%',
  },
  controllerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  radioGroupWrapper: {
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    paddingRight: '8px',
    marginLeft: 'unset',
    width: '100%',
    my: '4px',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '16px',
    mt: '10px',
  },
  footerWrapper: {
    display: 'flex',
    width: '100%',
    gap: '8px',
  },
});

export default useStyles;
