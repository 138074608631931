import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Nullable } from 'interfaces/Nullable.ts';

export interface PAmultipleFormData {
  category: string;
  damage_description: Nullable<string>;
}

export const defaultValues: PAmultipleFormData = {
  category: '',
  damage_description: null,
};

function usePAmultiForm() {
  const validationSchema = useMemo(
    () =>
      object().shape({
        category: string().required('Category is required'),
        damage_description: string().nullable(),
      }),
    []
  );

  return useForm<any>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
}

export default usePAmultiForm;
