import React, { useState, useRef, useCallback } from 'react';
import Webcam from 'react-webcam';
import { Button, Loading } from 'components/primitive';
import useStyles from './WebcamStreamCapture.styles';
import { Box, useTheme } from '@mui/material';

const FACING_MODE_USER = 'user';
const FACING_MODE_ENVIRONMENT = 'environment';

const videoConstraints = {
  facingMode: { ideal: FACING_MODE_ENVIRONMENT },
  height: { ideal: 250 },
  width: { ideal: 400 },
};

type WebcamStreamCaptureProps = {
  callback: (chunks: any) => void;
  setCapturePhoto: (value: boolean) => void;
};

const WebcamStreamCapture: React.FC<WebcamStreamCaptureProps> = ({ callback, setCapturePhoto }) => {
  const webcamRef = useRef<Webcam>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  // const [capturing, setCapturing] = useState(false);
  const [facingMode, setFacingMode] = useState(FACING_MODE_ENVIRONMENT);
  const [isCameraLoading, setIsCameraLoading] = useState(true);
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleStartCaptureClick = useCallback(() => {
    // setCapturing(true);
    let options = {};
    if (MediaRecorder.isTypeSupported('video/webm')) {
      options = { mimeType: 'video/webm' };
    } else if (MediaRecorder.isTypeSupported('video/mp4')) {
      options = { mimeType: 'video/mp4' };
    }
    if (webcamRef.current && webcamRef.current.stream) {
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        ...options,
        videoBitsPerSecond: 2500000,
      });
      mediaRecorderRef.current.start();
      mediaRecorderRef?.current?.stop();
      const imageSrc = webcamRef?.current?.getScreenshot();
      callback(imageSrc);
    }
  }, [webcamRef, mediaRecorderRef]);

  const handleClick = useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER
    );
  }, []);

  // const handleStopCaptureClick = useCallback(() => {
  //   mediaRecorderRef?.current?.stop();
  //   setCapturing(false);
  //   const imageSrc = webcamRef?.current?.getScreenshot();
  //   callback(imageSrc);
  // }, [mediaRecorderRef, webcamRef, setCapturing]);

  const handleUserMedia = () => {
    setTimeout(() => {
      setIsCameraLoading(false);
    }, 0);
  };

  return (
    <Box sx={classes.container}>
      <Box sx={classes.content}>
        <Box>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat='image/jpeg'
            screenshotQuality={1}
            videoConstraints={{
              ...videoConstraints,
              facingMode: { ideal: facingMode },
            }}
            forceScreenshotSourceSize
            onUserMedia={handleUserMedia}
          />
        </Box>
      </Box>
      <Box sx={classes.content}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '400px' }}>
          {isCameraLoading ? (
            <Loading message={'Loading camera, please wait...'} />
          ) : (
            <>
              <Button onClick={() => setCapturePhoto(false)}>Close</Button>
              {/*{capturing ? (*/}
              {/*  <Button onClick={handleStopCaptureClick}>Preview Photo</Button>*/}
              {/*) : (*/}
              {/*  <Button onClick={handleStartCaptureClick}>Take Photo</Button>*/}
              {/*)}*/}
              <Button onClick={handleStartCaptureClick}>Take Photo</Button>
              <Button onClick={handleClick}>Switch Camera</Button>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default WebcamStreamCapture;
