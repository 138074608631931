import type IEndPoint from 'interfaces/EndPointTypes.ts';
import {
  ApplicantSettingsFilterType,
  ApplicantType,
  ApplicantSettingsFilterParams,
} from 'interfaces/applicants.interface.ts';
import { AuthProtect, BaseURLSelect } from '@/common/enum';

function setSettingsParams(filter: ApplicantSettingsFilterParams): ApplicantSettingsFilterParams {
  const params: ApplicantSettingsFilterParams = {};
  if (filter.pvGroupID) {
    params.pvGroupID = filter.pvGroupID;
  }
  if (filter.pvSettingType) {
    params.pvSettingType = filter.pvSettingType;
  }
  return params;
}

export const getApplicantDataset = (): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: '/api/applicants',
  defaultBaseURL: BaseURLSelect.DMA,
});

export const getApplicantDetails = (id: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: `/api/applicants/${id}`,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const saveApplicant = (url: string, data: ApplicantType): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: data?.id ? 'PUT' : 'POST',
  url,
  data,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const deleteApplicant = (id: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'DELETE',
  url: `/api/applicants/${id}`,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const getApplicantSettings = (filters: ApplicantSettingsFilterParams): IEndPoint => {
  const params = setSettingsParams(filters);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/api/settings',
    params,
    defaultBaseURL: BaseURLSelect.DMA,
  };
};

export const setApplicantSettings = (data: ApplicantSettingsFilterType): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: data.pvSettingID ? 'PUT' : 'POST',
  url: '/api/settings',
  data,
});
