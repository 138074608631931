import type IEndPoint from 'interfaces/EndPointTypes.ts';
import { AuthProtect, BaseURLSelect } from '@/common/enum';

export const getTeamsWithFilter = (SelectedGroup: number, SelectedIncident: string): IEndPoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `/api/teams?filters[group_id]=${SelectedGroup}&filters[incident_id]=${SelectedIncident}`,
    defaultBaseURL: BaseURLSelect.DMA,
  };
};
