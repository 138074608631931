import { Nullable } from 'interfaces/Nullable.ts';
import { QueryState } from '@/common/enum';
import { JPDAIncidentSettingValueType, UserIncidentsType } from 'interfaces/incidents.interface.ts';

export type IncidentsStateTypes = {
  queryState: QueryState;
  error: Nullable<string>;
  userIncidents: Nullable<UserIncidentsType[]>;
  selectedIncidentForParcelSyncSettingID: Nullable<number>;
  selectedIncidentForParcelSyncSetting: Nullable<object>;
  selectedIncidentForParcelSyncID: Nullable<string>;
  selectedIncidentForParcelSync: Nullable<UserIncidentsType>;
  selectedJPDAIncidentSettingID: Nullable<number>;
  selectedJPDAIncidentSettingValue: Nullable<JPDAIncidentSettingValueType>;
  isJPDA_IA_Active: boolean;
  isJPDA_PA_Active: boolean;
};

export const IncidentsInitialState: IncidentsStateTypes = {
  queryState: QueryState.UNDEFINED,
  error: null,
  userIncidents: null,
  selectedIncidentForParcelSyncSettingID: null,
  selectedIncidentForParcelSyncSetting: null,
  selectedIncidentForParcelSyncID: null,
  selectedIncidentForParcelSync: null,
  selectedJPDAIncidentSettingID: null,
  selectedJPDAIncidentSettingValue: null,
  isJPDA_IA_Active: false,
  isJPDA_PA_Active: false,
};
