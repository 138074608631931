/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import useStyles from './DropFileZone.styles.ts';
import { Box, LinearProgress, useTheme } from '@mui/material';
import { Button, Typo } from 'components/primitive';
import { WebcamStreamCapture } from 'components/fragment';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DMAFileTypes, FileType } from 'interfaces/ia.interface.ts';
import { selectConnectionHealth } from 'store/connection/connection.selector.ts';
import { useAppSelector } from 'store/hooks.ts';
import { v4 as uuidv4 } from 'uuid';
import { IUserPortal } from 'interfaces/UserAccountInfo.interface.ts';

type DropFileZoneProps = {
  readOnly: boolean;
  authToken: string;
  uuid: string;
  user: IUserPortal;
  pvDataID?: number;
  setFilesAdded: React.Dispatch<React.SetStateAction<any>>;
  filesDeletedCallback: React.Dispatch<React.SetStateAction<any>>;
  inProgress: boolean;
  uploadedDocuments: DMAFileTypes[];
  localDocuments: DMAFileTypes[];
  progress: number;
};

const DropFileZone: React.FC<DropFileZoneProps> = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const healthRef = useRef(false);
  healthRef.current = useAppSelector(selectConnectionHealth);
  const baseStyle = classes.baseStyle;
  const focusedStyle = classes.focusedStyle;
  const acceptStyle = classes.acceptStyle;
  const rejectStyle = classes.rejectStyle;

  const [capturePhoto, setCapturePhoto] = useState(false);

  const dataURLtoFile = (dataUrl: any, filename: string) => {
    const arr = dataUrl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const captureCallback = (chunks: any) => {
    setCapturePhoto(false);
    const file = dataURLtoFile(chunks, `screenshot-${new Date().getTime()}.jpeg`);
    onDrop([file]).then(() => {
      console.log('file uploaded');
    });
  };

  const onDrop = useCallback(async (acceptedFiles: FileType[] | any[]) => {
    for (let i = 0; i < acceptedFiles.length; i++) {
      const extensions = ['jpeg', 'jpg', 'png', 'bmp'];
      if (extensions.some((extension) => acceptedFiles[i].name.includes(extension)))
        acceptedFiles[i].downloadLink = URL.createObjectURL(acceptedFiles[i]);
      acceptedFiles[i].localId = uuidv4();
    }
    props.setFilesAdded &&
      props.setFilesAdded((prevFiles: any) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, open } = useDropzone({
    onDrop,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  });

  const style: React.CSSProperties | any = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [baseStyle, isFocused, focusedStyle, isDragAccept, acceptStyle, isDragReject, rejectStyle]
  );

  if (!props.uuid) return null;

  return (
    <>
      {!props.inProgress &&
      !capturePhoto &&
      (props.uploadedDocuments.length > 0 || props.localDocuments.length > 0) ? (
        <>
          <Box sx={classes.btnContainer} onClick={open}>
            Add File
            <AddCircleIcon fontSize='small' />
          </Box>
          <Box
            sx={{ ...classes.btnContainer, right: '112px' }}
            onClick={() => setCapturePhoto(true)}>
            Take Photo
            <AddCircleIcon fontSize='small' />
          </Box>
        </>
      ) : null}
      {props.inProgress ? (
        <Box sx={{ width: '100%' }}>
          <LinearProgress variant='determinate' value={props.progress} />
        </Box>
      ) : null}
      {!props.inProgress &&
        props.uploadedDocuments.length === 0 &&
        props.localDocuments.length === 0 &&
        !capturePhoto && (
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <CloudUploadIcon sx={{ color: '#C2C2C2', fontSize: '36px' }} />
            <Typo sx={classes.fileUploadTitle}>Drag & drop a file to upload</Typo>
            <Typo sx={classes.fileUploadSubTitle}>Or</Typo>
            <Button onClick={open}>BROWSE</Button>
            <Button onClick={() => setCapturePhoto(true)}>Capture Photo</Button>
          </div>
        )}
      {capturePhoto && (
        <WebcamStreamCapture callback={captureCallback} setCapturePhoto={setCapturePhoto} />
      )}
    </>
  );
};

export default DropFileZone;
