import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserAccountTypes, UserAccountInitialState } from './userAccount.state';
import { IUserPortal } from 'interfaces/UserAccountInfo.interface.ts';

const initialState: UserAccountTypes = UserAccountInitialState;

export const userAccountSlice = createSlice({
  name: 'userAccount',
  initialState,
  reducers: {
    setUserAccount: (state, action: PayloadAction<Partial<IUserPortal>>) => {
      state.userAccount = action.payload;
    },
    resetUserAccount: () => {
      return initialState;
    },
  },
});

export const { resetUserAccount } = userAccountSlice.actions;
export default userAccountSlice.reducer;
