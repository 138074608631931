import { QueryState } from '@/common/enum';
import { Nullable } from 'interfaces/Nullable.ts';
import { PAData } from 'interfaces/pa.interface.ts';

export type PAStateTypes = {
  queryState: QueryState;
  error: Nullable<string>;
  paDataset: Nullable<PAData[]>;
  currentEntry: Nullable<PAData>;
  paTitle: Nullable<string>;
  fetchProgress: number;
  syncProgress: number;
};

export const PAInitialState: PAStateTypes = {
  queryState: QueryState.UNDEFINED,
  error: null,
  paDataset: null,
  currentEntry: null,
  paTitle: null,
  fetchProgress: 0,
  syncProgress: 0,
};
