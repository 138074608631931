import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import { getPalmettoGroups, getUserDMASettings } from '@/services/accountsInfo.endpoint.ts';
import { PalmettoGroups, UserDMASettings } from 'interfaces/UserAccountInfo.interface.ts';
import type { RootState } from '@/store';
import { PalmettoGroupsJSON } from '@/data';

export const fetchUserDMASettings = createAsyncThunk(
  'fetchUserDMASettings',
  async (controller: AbortController | undefined, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const account = store.accountsInfo.account;
      const endpoint = getUserDMASettings(account?.id || 0);
      const response = await apiFetch<UserDMASettings | null>(endpoint, controller).then(
        (res) => res?.data || null
      );
      return {
        account,
        response,
      };
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);

// export const fetchParentGroups = createAsyncThunk(
//   'fetchParentGroups',
//   async (controller: AbortController, thunkAPI) => {
//     try {
//       const store = thunkAPI.getState() as RootState;
//       // const selectedGroup = store.accountsInfo.selectedGroup;
//       // if (!selectedGroup) return;
//       const endpoint = getPalmettoGroups();
//       return await apiFetch<PalmettoGroups>(endpoint, controller).then((res) => res?.data || null);
//     } catch (error) {
//       let message = genericErrorMessage;
//       if (error instanceof Error) message = error.message;
//       else {
//         if (typeof error === 'object' && error !== null) {
//           if ('message' in error) {
//             message = String(error.message);
//           }
//         }
//       }
//       throw Error(message);
//     }
//   }
// );
//
// export const fetchAllSubGroups = createAsyncThunk(
//   'fetchAllSubGroups',
//   async (controller: AbortController | undefined, thunkAPI) => {
//     try {
//       const store = thunkAPI.getState() as RootState;
//       const { selectedGroupID, selectedGroup } = store.accountsInfo;
//       const activeGroup = selectedGroup ? selectedGroup : selectedGroupID;
//       const filter = {
//         where: { pvParentGroupID: activeGroup },
//       };
//       const endpoint = getPalmettoGroupsFilter(JSON.stringify(filter));
//       return await apiFetch<PalmettoGroups[]>(endpoint, controller).then(
//         (res) => res?.data || null
//       );
//     } catch (error) {
//       let message = genericErrorMessage;
//       if (error instanceof Error) message = error.message;
//       else {
//         if (typeof error === 'object' && error !== null) {
//           if ('message' in error) {
//             message = String(error.message);
//           }
//         }
//       }
//       throw Error(message);
//     }
//   }
// );

export const fetchAllGroups = createAsyncThunk(
  'fetchAllGroups',
  async (controller?: AbortController) => {
    try {
      const endpoint = getPalmettoGroups();
      return await apiFetch<PalmettoGroups[]>(endpoint, controller)
        .then((res) => res?.data || null)
        .catch(() => {
          console.log('Error fetching groups and use local data');
          return PalmettoGroupsJSON as PalmettoGroups[];
        });
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);
