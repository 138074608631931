import type { RootState } from '@/store';

export const paDataSetSelector = (state: RootState) => state.pa.paDataset;
export const paTitleSelector = (state: RootState) => state.pa.paTitle;
export const paDataSetSelectorFilter = (id: string) => (store: RootState) => {
  const filteredData = store.pa.paDataset?.filter((item) => item.parcel_id === id);

  return filteredData?.sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB.getTime() - dateA.getTime();
  });
};

export const paDataSetSelectorFilterMarker = (lat: number, long: number) => (store: RootState) => {
  const filteredData = store.pa.paDataset?.filter(
    (item) => item.latitude === lat && item.longitude === long
  );

  return filteredData?.sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB.getTime() - dateA.getTime();
  });
};

export const paUnsavedDataSelector = (state: RootState) =>
  state.pa.paDataset?.filter((item) => item.isUnsaved === true);

export const paQueryStateSelector = (state: RootState) => state.pa.queryState;
export const paStatusSelector = (state: RootState) => state.pa.queryState;
export const paProgressSelector = (state: RootState) => state.pa.fetchProgress;
export const paSyncProgressSelector = (state: RootState) => state.pa.syncProgress;
