/* eslint-disable no-unused-vars */
import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  useTheme,
} from '@mui/material';
import useStyles from './ModalSelect.styles.ts';
import { Button, Modal, SelectMenu, Typo } from 'components/primitive';
import CloseIcon from '@mui/icons-material/Close';
import { HeaderComponent } from '@zawarski/palmetto-ui-components';
import Box from '@mui/material/Box';
import { Controller, useFormContext } from 'react-hook-form';
import { RadioSelectOptions } from 'interfaces/SelectOptions.interface.ts';
import { FieldName } from '@/common/enum';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import { ApplicantsOptionsType } from 'interfaces/applicants.interface.ts';
import { ErrorMessage } from 'components/primitive';

const iconMap: { [key: string]: React.ElementType } = {
  endIcon: TurnedInNotIcon,
};

type ModalSelectProps = {
  headerTitle: string;
  optionData: 'categoryOptions' | 'applicantsOptions';
  isSaving: boolean;
  onClose: Dispatch<SetStateAction<string | null>>;
  onConfirm: (fieldName: FieldName) => void;
  hideClose?: boolean;
  secondOptionsData?: ApplicantsOptionsType[];
  health: boolean;
};

const ModalSelect: React.FC<ModalSelectProps> = ({
  onClose,
  onConfirm,
  headerTitle,
  optionData,
  isSaving = false,
  hideClose = false,
  secondOptionsData,
  health,
}) => {
  const { control, watch } = useFormContext();
  const fieldName: FieldName = watch('fieldName');
  const selectedValue = watch(fieldName) ? watch(fieldName) : null;
  const hasValue = watch(fieldName) ? true : false;
  const [hasOptionSelected, setHasOptionSelected] = useState<boolean>(hasValue);
  const options: RadioSelectOptions[] = watch(optionData);
  const isModalOpen = watch('isOpen');
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Modal open={isModalOpen} onClose={!isSaving ? () => onClose(null) : undefined}>
      <>
        <HeaderComponent
          title={headerTitle}
          icon={
            hideClose ? null : (
              <IconButton onClick={!isSaving ? () => onClose(null) : undefined}>
                <CloseIcon className='icon' />
              </IconButton>
            )
          }
        />
        <Box sx={classes.content}>
          <Controller
            control={control}
            name={fieldName}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl sx={classes.controllerWrapper}>
                {optionData === 'categoryOptions' && (
                  <RadioGroup
                    sx={classes.controllerWrapper}
                    aria-labelledby='demo-controlled-radio-buttons-group'
                    name='controlled-radio-buttons-group'
                    value={value}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (newValue !== value) {
                        if (!hasOptionSelected) {
                          setHasOptionSelected(true);
                        }
                        return onChange(e);
                      }
                    }}>
                    {options && options.length
                      ? options.map((item) => {
                          const isActive = Number(value) === item.value;
                          return (
                            <FormControlLabel
                              sx={[
                                classes.radioGroupWrapper,
                                isActive
                                  ? { border: `1px solid ${theme.palette.primary.main}` }
                                  : {},
                              ]}
                              key={item.value}
                              control={<Radio />}
                              value={item.value}
                              label={<Typo fontWeight={500}>{item.label}</Typo>}
                            />
                          );
                        })
                      : null}
                  </RadioGroup>
                )}
                {optionData === 'applicantsOptions' && options?.length ? (
                  <Grid item xs={12}>
                    <SelectMenu
                      withLabel
                      label='Applicant'
                      value={value}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        if (newValue !== value) {
                          if (!hasOptionSelected) {
                            setHasOptionSelected(true);
                          }
                          return onChange(e);
                        }
                      }}
                      values={options}
                      error={Boolean(error)}
                      fieldError={error}
                      helperText={error ? error.message : ''}
                      placeholder={'Select an applicant'}
                      disabled={!health}
                    />
                  </Grid>
                ) : null}
              </FormControl>
            )}
          />
          {selectedValue === 'pa' && secondOptionsData?.length ? (
            <Controller
              control={control}
              name={'applicantID'}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <SelectMenu
                  withLabel
                  label='Applicant'
                  value={value}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    if (newValue !== value) {
                      if (!hasOptionSelected) {
                        setHasOptionSelected(true);
                      }
                      return onChange(e);
                    }
                  }}
                  values={secondOptionsData}
                  error={Boolean(error)}
                  fieldError={error}
                  helperText={error ? error.message : ''}
                  placeholder={'Select an applicant'}
                  iconMap={iconMap}
                  disabled={!health}
                />
              )}
            />
          ) : (
            !secondOptionsData ||
            (secondOptionsData?.length === 0 && (
              <ErrorMessage message={'No applicants found for PA'} />
            ))
          )}
        </Box>
        <Box sx={classes.footer}>
          <Box sx={classes.footerWrapper}>
            {hideClose ? null : (
              <Button
                fullWidth
                neutral
                sx={{ color: theme.palette.text.secondary }}
                onClick={!isSaving ? () => onClose(null) : undefined}>
                CANCEL
              </Button>
            )}
            <Button
              neutral={hasOptionSelected ? false : true}
              fullWidth
              progress={isSaving}
              onClick={() => {
                onConfirm(fieldName);
              }}>
              CONFIRM
            </Button>
          </Box>
        </Box>
      </>
    </Modal>
  );
};

export default ModalSelect;
