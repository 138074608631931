import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  gridContainer: {
    px: '16px',
  },
  parcelContainer: {
    display: 'flex',
    padding: '12px 16px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    bgcolor: theme.palette.grey[200],
    borderRadius: '4px',
    boxShadow: '0px 0.85px 3px 0px rgba(0, 0, 0, 0.19), 0px 0.25px 1px 0px rgba(0, 0, 0, 0.04)',
    text: {
      fontSize: '14px',
      fontWeight: 600,
      textTransform: 'capitalize',
      color: theme.palette.secondary.contrastText,
    },
  },
  groupRadioContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  },
  groupCheckContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '8px',
    flexWrap: 'wrap',
  },
  footerContainer: {
    zIndex: 10,
    display: 'flex',
    width: '100vw',
    padding: '24px',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    bottom: 0,
    background: theme.palette.common.white,
    boxShadow:
      '0px 4.13211px 10.01724px 0px rgba(0, 0, 0, 0.13), 0px 33px 80px 0px rgba(0, 0, 0, 0.26)',
  },
  footerWrapper: {
    display: 'flex',
    maxWidth: '1440px',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px',
    px: '30px',
  },
});

export default useStyles;
