import React, { useEffect, useState, useRef, FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { FieldError, SubmitHandler } from 'react-hook-form';
import useIAForm, { defaultValues, IAFormData } from '../hook/useIAForm';
import { ToastMessage } from 'components/primitive';
import { useAppSelector, useAppDispatch } from 'store/hooks.ts';
import { iaStatusSelector } from 'store/ia/ia.selector.ts';
import {
  accountGroupIDSelector,
  selectAccount,
  selectedGroupNameSelector,
  userTypeSelector,
  selectedGroupSelector,
} from 'store/accountsInfo/accountsInfo.selector.ts';
import { selectAuthToken, accessTypeSelector } from 'store/authToken/authToken.selector.ts';
import { saveIAData, fetchIADataByID } from 'store/ia/ia.thunk.ts';
import { InsuranceTypes, QueryState, ToastStatus, UserType, UtilitiesOut } from '@/common/enum';
import {
  addOrDeleteFilesFunc,
  convertToTitleCase,
  prepareDMAFilesData,
  saveFileToPalmettoDB,
} from '@/utilities';
import { DMAFileTypes, FileType, IADetails } from 'interfaces/ia.interface.ts';
import { DetailsViewProps } from './IADetails.props.ts';
import IADetailsView from './IADetailsView.tsx';
import { findByQuery, putDocument } from 'store/db/db.thunk.ts';
import {
  findFileByQuery,
  updateOfflineFile,
  deleteOfflineFile,
} from 'store/file_db/file_db.thunk.ts';
import { selectConnectionHealth } from 'store/connection/connection.selector.ts';
import { useUploadForm } from '@/hooks';
import { BuildingDamageTypes } from '@/data/LegendValues/BuildingDamage.ts';
import { ParcelData } from 'components/fragment/ParcelViewModal/ParcelViewModal.props.tsx';
import moment from 'moment';
import { saveFilesToPouchDB, formatMailCityState } from '@/utilities';
import {
  parcelSyncIncidentIDSelector,
  parcelSyncIncidentNameSelector,
  isJPDA_IA_ActiveSelector,
} from 'store/incidents/incidents.selector.ts';
import { setIADataSet } from 'store/ia/ia.actions.ts';
import { mapInfoSelector } from 'store/map/map.selector.ts';
import { updateMapInfo } from 'store/map/map.action.ts';
import SitePermissionTypes from '@/common/enum/SitePermissionTypes.ts';

interface MarkerData {
  lat: number;
  lng: number;
}

interface IDocToSaveIA extends IADetails {
  type: string;
  dma_category: string;
  deleted: number;
  user_id: number;
  user_group_id: number;
  parcel_id: string | null;
  applicant_name: string;
  created_at: string;
  updated_at: string;
  isUnsaved: boolean;
  filesToDelete: DMAFileTypes[] | any[];
  _id?: string;
  _rev?: string;
}

const IADetailsPage: FC<{
  newEntry?: boolean;
  data?: IADetails;
  parcelData?: ParcelData;
  parcelId: string | null;
  markerData?: MarkerData;
}> = ({ newEntry, ...props }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const form = useIAForm();
  const authToken = useAppSelector(selectAuthToken);
  const palmettoUser = useAppSelector(selectAccount);
  const userType = useAppSelector(userTypeSelector);
  const iaStatus = useAppSelector(iaStatusSelector);
  const countyGroupName = useAppSelector(selectedGroupNameSelector);
  const selectedIncidentForParcelSync = useAppSelector(parcelSyncIncidentIDSelector);
  const selectedGroupID = useAppSelector(accountGroupIDSelector);
  const selectedGroup = useAppSelector(selectedGroupSelector);
  const dropMarkerMode = useAppSelector(mapInfoSelector).dropMarkerMode;
  const incidentName = useAppSelector(parcelSyncIncidentNameSelector) || '';
  const userPermission = useAppSelector(accessTypeSelector);
  const isJPDA_IA_Active = useAppSelector(isJPDA_IA_ActiveSelector);
  const activeGroupID = selectedGroup ? selectedGroup : selectedGroupID;

  const files = form.watch('files');

  const hasOldData: boolean = useMemo(() => {
    if (Array.isArray(files) && files.length > 0) {
      let hasOldData = false;
      for (let i = 0; i < files.length; i++) {
        const fileName = files[i].file_name ?? '';
        if (fileName.length && !fileName.includes('DUMMY')) {
          hasOldData = true;
          break;
        }
      }
      return hasOldData;
    }
    return false;
  }, [files]);

  const { uploadForm } = useUploadForm<FileType>(hasOldData);

  const healthRef = useRef(false);
  healthRef.current = useAppSelector(selectConnectionHealth);

  const defaultAssessmentTeamMember =
    palmettoUser !== null
      ? `${palmettoUser?.pvPersonGivenName} ${palmettoUser?.pvPersonSurName}`
      : '';
  const incidentID = Number(selectedIncidentForParcelSync) ?? 0;
  const isPending = iaStatus === QueryState.AWAIT;
  const isStateUser = userType === UserType.STATE_USER;

  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [filesAdded, setFilesAdded] = useState<DMAFileTypes[] | any>([]);
  const [filesDeleted, setFilesDeleted] = useState<DMAFileTypes[] | any>([]);
  const [localFilesDeleted, setLocalFilesDeleted] = useState<any[]>([]);
  const [utilitiesError, setUtilitiesError] = useState<
    { message: string } | FieldError | undefined
  >();
  const [insuranceError, setInsuranceError] = useState<
    { message: string } | FieldError | undefined
  >();
  const [utilitiesSelect, setUtilitiesSelect] = useState<{
    noOutages: boolean;
    Electricity: boolean;
    Gas: boolean;
    Water: boolean;
  }>({
    noOutages: false,
    Electricity: false,
    Gas: false,
    Water: false,
  });
  const [insuranceSelect, setInsuranceSelect] = useState<{
    condominium: boolean;
    flood: boolean;
    hazardRider: boolean;
    homeOwners: boolean;
    manufacturedHome: boolean;
    unknown: boolean;
    noInsurance: boolean;
  }>({
    condominium: false,
    flood: false,
    hazardRider: false,
    homeOwners: false,
    manufacturedHome: false,
    unknown: false,
    noInsurance: false,
  });
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [allowAutoFill, setAllowAutoFill] = useState<boolean>(true);

  const buildingDamage = form.watch('building_damage');
  const replacementCost = form.watch('replacement_cost');

  const permissions: { UPDATE: boolean } = useMemo(() => {
    const value = { UPDATE: true };
    if (isStateUser) {
      if (
        !isJPDA_IA_Active &&
        (userPermission === SitePermissionTypes.U || userPermission === SitePermissionTypes.F)
      ) {
        value.UPDATE = false;
      }
    }
    return value;
  }, [isStateUser, userPermission, isJPDA_IA_Active]);

  function SetUtilitiesInputState(value: string[]) {
    type UtilitiesKey = 'noOutages' | 'Electricity' | 'Gas' | 'Water';
    if (Array.isArray(value) && value.length > 0) {
      const tempState = { ...utilitiesSelect };
      value.forEach((item) => {
        const key = item === 'No Outages' ? 'noOutages' : (item as UtilitiesKey);
        if (Object.keys(tempState).includes(key)) {
          tempState[key] = true;
        }
      });
      setUtilitiesSelect(tempState);
    }
  }

  function FormatUtilitiesData() {
    const tempArr: string[] = [];
    if (utilitiesSelect.noOutages) {
      tempArr.push(UtilitiesOut.noOutages);
    }
    if (utilitiesSelect.Electricity) {
      tempArr.push(UtilitiesOut.Electricity);
    }
    if (utilitiesSelect.Gas) {
      tempArr.push(UtilitiesOut.Gas);
    }
    if (utilitiesSelect.Water) {
      tempArr.push(UtilitiesOut.Water);
    }
    return tempArr;
  }

  function SetInsuranceInputState(value: string[]) {
    type InsuranceKey =
      | 'condominium'
      | 'flood'
      | 'hazardRider'
      | 'homeOwners'
      | 'manufacturedHome'
      | 'unknown'
      | 'noInsurance';

    if (value && Array.isArray(value)) {
      // *: Check the array to set the insurance checkbox state
      if (value.length) {
        const tempInsState = { ...insuranceSelect };
        value.forEach((item) => {
          const text = item.trim().toLowerCase();
          if (text.includes(InsuranceTypes.condominium.toLowerCase())) {
            const key = 'condominium' as InsuranceKey;
            if (Object.keys(tempInsState).includes(key)) {
              tempInsState[key] = true;
            }
          } else if (text.includes(InsuranceTypes.flood.toLowerCase())) {
            const key = 'flood' as InsuranceKey;
            if (Object.keys(tempInsState).includes(key)) {
              tempInsState[key] = true;
            }
          } else if (text.includes(InsuranceTypes.hazardRider.toLowerCase())) {
            const key = 'hazardRider' as InsuranceKey;
            if (Object.keys(tempInsState).includes(key)) {
              tempInsState[key] = true;
            }
          } else if (text.includes(InsuranceTypes.homeOwners.toLowerCase())) {
            const key = 'homeOwners' as InsuranceKey;
            if (Object.keys(tempInsState).includes(key)) {
              tempInsState[key] = true;
            }
          } else if (text.includes(InsuranceTypes.manufacturedHome.toLowerCase())) {
            const key = 'manufacturedHome' as InsuranceKey;
            if (Object.keys(tempInsState).includes(key)) {
              tempInsState[key] = true;
            }
          } else if (text.includes(InsuranceTypes.unknown.toLowerCase())) {
            const key = 'unknown' as InsuranceKey;
            if (Object.keys(tempInsState).includes(key)) {
              tempInsState[key] = true;
            }
          } else if (text.includes(InsuranceTypes.noInsurance.toLowerCase())) {
            const key = 'noInsurance' as InsuranceKey;
            if (Object.keys(tempInsState).includes(key)) {
              tempInsState[key] = true;
            }
          }
        });
        setInsuranceSelect(tempInsState);
      }
    }
  }

  function FormatInsuranceData() {
    const tempArr: string[] = [];
    if (insuranceSelect.condominium) {
      tempArr.push(InsuranceTypes.condominium);
    }
    if (insuranceSelect.flood) {
      tempArr.push(InsuranceTypes.flood);
    }
    if (insuranceSelect.hazardRider) {
      tempArr.push(InsuranceTypes.hazardRider);
    }
    if (insuranceSelect.homeOwners) {
      tempArr.push(InsuranceTypes.homeOwners);
    }
    if (insuranceSelect.manufacturedHome) {
      tempArr.push(InsuranceTypes.manufacturedHome);
    }
    if (insuranceSelect.unknown) {
      tempArr.push(InsuranceTypes.unknown);
    }
    if (insuranceSelect.noInsurance) {
      tempArr.push(InsuranceTypes.noInsurance);
    }
    return tempArr;
  }

  useEffect(() => {
    if (newEntry) {
      const [city, state, zipCode] = formatMailCityState(props.parcelData?.MailCityState);
      let latitude = 0;
      let longitude = 0;
      if (props.parcelData?.latlngCoords[0][0] && props.parcelData?.latlngCoords[0][0].length > 0) {
        longitude = props.parcelData?.latlngCoords[0][0][0];
        latitude = props.parcelData?.latlngCoords[0][0][1];
      }
      if (props.markerData) {
        latitude = props.markerData.lat;
        longitude = props.markerData.lng;
      }

      const data: IAFormData = {
        ...defaultValues,
        pvGlobalID: uuidv4(),
        county: convertToTitleCase(countyGroupName ?? ''),
        assessment_team_member: defaultAssessmentTeamMember,
        incident_id: incidentID,
        parcel_id: props.parcelId ?? null,
        group_id: Number(activeGroupID) ?? 0,
        user_group_id: Number(selectedGroupID),
        replacement_cost: props?.parcelData?.MarketValue ?? 0,
        occupant_name: props?.parcelData?.Owner ?? '',
        address: props?.parcelData?.SiteAddress ?? '',
        city: city,
        state: state,
        zipCode: zipCode,
        latitude: latitude,
        longitude: longitude,
        pvParcelGeometryObject: props?.parcelData?.latlngCoords
          ? { coordinates: props.parcelData?.latlngCoords }
          : null,
        has_files: 0,
      };

      Object.keys(data).forEach((key) => {
        const typedKey = key as keyof IAFormData;
        const value = data[typedKey];
        if (value) {
          form.setValue(typedKey, value);
        }
      });

      setInsuranceSelect({ ...insuranceSelect, unknown: true });
      setReadOnly(false);
    } else if (props.data) {
      const tempData: IADetails = {
        ...props.data,
        posting: props.data?.posting ? props.data?.posting?.toLowerCase() : null,
        pvGlobalID: props.data?.pvGlobalID ? props.data.pvGlobalID : uuidv4(),
        _id: props.data?._id,
        _rev: props.data?._rev,
        pvParcelGeometryObject: props.data?.pvParcelGeometryObject
          ? JSON.parse(props.data.pvParcelGeometryObject)
          : null,
        utilities_out: props.data.utilities_out ? props.data.utilities_out : [],
        has_files: props.data?.has_files ?? 0,
      };
      const tempUtilitiesOut = props.data.utilities_out ? props.data.utilities_out : [];
      const tempInsuranceType = props.data.insurance_type ? props.data.insurance_type : [];

      SetUtilitiesInputState(tempUtilitiesOut);
      SetInsuranceInputState(tempInsuranceType);
      if (props?.data?.filesToDelete?.length) {
        setFilesDeleted(props.data.filesToDelete);
      }
      form.reset(tempData);
    }
  }, [newEntry, form]);

  useEffect(() => {
    const bldgDmg2Percent = (value: string): number => {
      const options: Partial<Record<BuildingDamageTypes, number>> = {
        Affected: 0.25,
        'Minor Damage': 0.5,
        'Major Damage': 0.75,
        Destroyed: 1,
      };
      const key = value;
      const isValidKey: boolean = Object.keys(options).includes(key);
      if (!isValidKey) return 0;
      return options[key as BuildingDamageTypes] ?? 0;
    };

    if (buildingDamage && Number(replacementCost)) {
      const percentComplete = bldgDmg2Percent(buildingDamage);
      const newEstimateCost = Number(replacementCost) * percentComplete;
      form.setValue('estimated_loss', newEstimateCost);
    }
  }, [buildingDamage, replacementCost, form]);
  useEffect(() => {
    const atLeastOneTrue = Object.values(utilitiesSelect).some((value) => value);
    if (atLeastOneTrue) {
      const utilitiesOut = FormatUtilitiesData();
      form.setValue('utilities_out', utilitiesOut);
    }
  }, [utilitiesSelect]);
  useEffect(() => {
    const atLeastOneTrue = Object.values(insuranceSelect).some((value) => value);
    if (atLeastOneTrue) {
      const insuranceType = FormatInsuranceData();
      form.setValue('insurance_type', insuranceType);
    }
  }, [insuranceSelect]);
  useEffect(() => {
    if (utilitiesError && Object.keys(utilitiesError).length) {
      const atLeastOneTrue = Object.values(utilitiesSelect).some((value) => value);
      if (atLeastOneTrue) {
        form.clearErrors('utilities_out');
        setUtilitiesError(undefined);
      }
    }
  }, [utilitiesSelect, utilitiesError]);
  useEffect(() => {
    if (insuranceError && Object.keys(insuranceError).length) {
      const atLeastOneTrue = Object.values(insuranceSelect).some((value) => value);
      if (atLeastOneTrue) {
        form.clearErrors('insurance_type');
        setInsuranceError(undefined);
      }
    }
  }, [insuranceSelect, insuranceError]);
  useEffect(() => {
    if (form.formState.errors && Object.keys(form.formState.errors).length) {
      Object.keys(form.formState.errors).forEach((key) => {
        if (key === 'utilities_out') {
          const msg = 'Please select a utility';
          setUtilitiesError({ message: msg });
        }
        if (key === 'insurance_type') {
          const msg = 'Please select an insurance';
          setInsuranceError({ message: msg });
        }
      });
    }
  }, [form.formState.errors]);

  const goBack = () => {
    navigate(-1);
  };

  const handleEdit = () => {
    setReadOnly((prevState) => !prevState);
  };

  const toggleAutoFill = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setAllowAutoFill(checked);
  };

  const handleCheckUtilities = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: UtilitiesOut
  ) => {
    const fieldName = field === UtilitiesOut.noOutages ? 'noOutages' : field;
    const { checked } = event.target;
    setUtilitiesSelect((prevState) => ({ ...prevState, [fieldName]: checked }));
  };

  const handleCheckInsurance = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
    const { checked } = event.target;
    setInsuranceSelect((prevState) => ({ ...prevState, [field]: checked }));
  };

  const saveData = async (payload: any) => {
    const savedItem = {};
    await dispatch(saveIAData(payload)).then((res: any) => {
      if (res.type === 'ia/saveIAData/rejected') {
        throw new Error(res.error.message);
      }
      Object.assign(savedItem, res.payload);
    });
    return savedItem;
  };

  const onHandleSubmit: SubmitHandler<IAFormData> = async (data) => {
    try {
      setIsSaving(true);
      if (localFilesDeleted.length) {
        for (let i = 0; i < localFilesDeleted.length; i++) {
          const fileToDel = localFilesDeleted[i];
          await deleteOfflineFile(fileToDel);
        }
      }
      if (healthRef.current) {
        await onSubmit(data);
        toast.info(<ToastMessage status={ToastStatus.SUCCESS} message='Successfully Processed' />);
      } else {
        if (!data?.pvGlobalID) return;
        if (filesAdded.length) {
          filesAdded.forEach((file: any) => {
            delete file.localId;
          });
          await saveFilesToPouchDB(filesAdded, data?.pvGlobalID, dispatch);
        }

        //filter files to delete
        let filteredFiles;
        if (props?.data?.files?.length && filesDeleted?.length) {
          const currentFiles = props?.data?.files?.length ? props?.data?.files : [];
          filteredFiles = currentFiles.filter((file: any) => {
            return !filesDeleted.some(
              (deletedFile: any) => deletedFile.pvGlobalID === file.pvGlobalID
            );
          });
        } else {
          filteredFiles = props?.data?.files || [];
        }
        let hasFiles = props?.data?.has_files ?? 0;
        if (filesAdded.length || filteredFiles.length) {
          hasFiles = 1;
        }
        const docToSave: IDocToSaveIA = {
          ...data,
          type: props.parcelId ? 'parcel' : 'marker',
          dma_category: 'ia',
          isolated: data.building_damage === BuildingDamageTypes.Inaccessible ? 'Yes' : 'No',
          deleted: props.data?.deleted ?? 0,
          user_id: palmettoUser?.id ?? 0,
          user_group_id: Number(selectedGroupID) ?? 0,
          replacement_cost: props.data?.replacement_cost ?? null,
          parcel_id: data?.parcel_id ?? null,
          pvParcelGeometryObject: data?.pvParcelGeometryObject
            ? JSON.stringify(data.pvParcelGeometryObject)
            : '',
          applicant_name: props.data?.applicant_name ?? '',
          created_at:
            props.data && props.data?.created_at
              ? props.data?.created_at
              : moment(new Date(), 'YYYY-MM-DD HH:mm:ss').toISOString(),

          updated_at: moment(new Date(), 'YYYY-MM-DD HH:mm:ss').toISOString(),
          isUnsaved: true,
          filesToDelete: filesDeleted.length ? filesDeleted : [],
          files: filteredFiles || [],
          has_files: hasFiles,
        };
        putDocument(docToSave).then(async () => {
          const getPouchIAData = (await findByQuery({
            selector: { dma_category: 'ia' },
          })) as any;
          dispatch(setIADataSet(getPouchIAData));
        });
        toast.info(
          <ToastMessage status={ToastStatus.SUCCESS} message='Successfully Saved Offline' />
        );
      }
      if (dropMarkerMode) {
        dispatch(updateMapInfo({ dropMarkerMode: false }));
      }
      setIsSaving(false);
      navigate(-1);
    } catch (error) {
      toast.error(<ToastMessage status={ToastStatus.ERROR} message='Encountered System Error' />);
      setIsSaving(false);
      throw error;
    }
  };

  const onSubmit: SubmitHandler<IAFormData> = async (data) => {
    if (!authToken || !palmettoUser || !data.pvGlobalID) return;

    const saveLocalFiles = async (globalID: string, files: DMAFileTypes[]) => {
      //find local files associated with the globalId
      const filesArr = (await findFileByQuery({
        selector: { pvGlobalGroupID: globalID, isUnsaved: true },
      })) as any;
      const savedFiles: FileType[] = [];
      //save files to palmetto and flag them as saved in local db
      for (let i = 0; i < filesArr.length; i++) {
        const file = filesArr[i];
        const binaryFile = new File([file.file], file.name, { type: file.type });
        try {
          await saveFileToPalmettoDB(
            binaryFile,
            authToken,
            data?.pvGlobalID,
            palmettoUser.id,
            palmettoUser.username,
            uploadForm
          ).then((res) => {
            savedFiles.push(res);
            //update the file in local db to reflect that it has been saved
            file.isUnsaved = false;
            updateOfflineFile(file).then((res) => {
              console.log('file updated', res);
            });
          });
        } catch {
          toast.error(
            <ToastMessage status={ToastStatus.ERROR} message={`Error saving file ${file.name}`} />
          );
        }
      }

      if (files?.length) {
        //loop added files and save file to palmetto db
        for (let i = 0; i < filesAdded.length; i++) {
          const file = filesAdded[i];
          try {
            await saveFileToPalmettoDB(
              file,
              authToken,
              data?.pvGlobalID,
              palmettoUser.id,
              palmettoUser.username,
              uploadForm
            ).then((res) => {
              savedFiles.push(res);
            });
          } catch {
            toast.error(
              <ToastMessage status={ToastStatus.ERROR} message={`Error saving file ${file.name}`} />
            );
          }
        }
      }
      return savedFiles;
    };

    const savedFiles = await saveLocalFiles(data.pvGlobalID, filesAdded);
    //set pvGlobalGroupID for each file
    savedFiles.forEach((file: any) => {
      file.pvGlobalGroupID = data.pvGlobalID;
    });

    let hasFiles = props?.data?.has_files ?? 0;
    if (savedFiles.length) {
      hasFiles = 1;
    }

    const payload: any = {
      ...data,
      parcel_id: props.parcelId,
      isolated: data.building_damage === BuildingDamageTypes.Inaccessible ? 'Yes' : 'No',
      deleted: 0,
      user_id: palmettoUser?.id ?? 0,
      files: prepareDMAFilesData(savedFiles, 'ia'),
      pvParcelGeometryObject: data?.pvParcelGeometryObject
        ? JSON.stringify(data.pvParcelGeometryObject)
        : null,
      has_files: hasFiles,
    } as any;

    await addOrDeleteFilesFunc({
      filesToAdd: savedFiles,
      filesToDelete: filesDeleted,
      uuid: data?.pvGlobalID,
      authToken,
    });
    if (payload?.filesToDelete) {
      delete payload.filesToDelete;
    }

    if (payload?.isUnsaved) {
      delete payload.isUnsaved;
    }
    if (payload?._id) {
      delete payload._id;
    }
    if (payload?._rev) {
      delete payload._rev;
    }
    //if is new item we get the response from saveData, if not we make a call to get the response
    let response: any = await saveData(payload);
    if (data.id) {
      response = await dispatch(fetchIADataByID(data.id)).unwrap();
    }
    //format response
    if (response?.utilities_out && !Array.isArray(response.utilities_out)) {
      response.utilities_out = response.utilities_out.split(',').map((item: any) => item.trim());
    }
    if (response?.insurance_type && !Array.isArray(response.insurance_type)) {
      response.insurance_type = response.insurance_type.split(',').map((item: any) => item.trim());
    }
    if (response?.created_at) {
      response.created_at = moment(response.created_at, 'YYYY-MM-DD HH:mm:ss').toISOString();
    }
    if (response?.updated_at) {
      response.updated_at = moment(response.updated_at, 'YYYY-MM-DD HH:mm:ss').toISOString();
    }
    if (response?.files?.length) {
      delete response.files;
    }
    //if item is in pouch we delete isUnsaved and update the item
    const itemInPouchDb = (await findByQuery({
      selector: { pvGlobalID: data?.pvGlobalID },
    })) as any;

    if (itemInPouchDb[0] && itemInPouchDb[0]?._id) {
      const item = itemInPouchDb[0];
      if (item?.files?.length) {
        // filter deleted files
        if (filesDeleted?.length) {
          const filteredFiles = item.files.filter((file: any) => {
            return !filesDeleted.some(
              (deletedFile: any) => deletedFile.pvGlobalID === file.pvGlobalID
            );
          });
          item.files = filteredFiles;
        }
        if (savedFiles?.length) {
          item.files = [...item.files, ...savedFiles];
        }
      }
      if (item?.filesToDelete?.length) {
        delete item.filesToDelete;
      }
      const updatedItem = {
        ...item,
        ...response,
      };
      if (updatedItem?.isUnsaved) {
        delete updatedItem.isUnsaved;
      }
      await putDocument(updatedItem).then(async () => {
        const getItemsFromPouchDB = (await findByQuery({
          selector: { dma_category: 'ia' },
        })) as any;
        dispatch(setIADataSet(getItemsFromPouchDB));
      });
    } else {
      const docToSave: IDocToSaveIA = {
        ...response,
        dma_category: 'ia',
        // files: savedFiles,
      };
      await putDocument(docToSave).then(async () => {
        const getPouchIAData = (await findByQuery({
          selector: { dma_category: 'ia' },
        })) as any;
        dispatch(setIADataSet(getPouchIAData));
      });
    }
  };

  const combineProps: DetailsViewProps = {
    palmettoUser: palmettoUser,
    authToken: authToken ?? '',
    inProcess: isPending || isSaving,
    readOnly,
    form,
    utilitiesError,
    insuranceError,
    insuranceSelect,
    utilitiesSelect,
    allowAutoFill,
    isStateUser,
    filesAdded,
    filesDeleted,
    setFilesAdded,
    setFilesDeleted,
    setLocalFilesDeleted,
    goBack,
    toggleAutoFill,
    handleCheckInsurance,
    handleCheckUtilities,
    handleEdit,
    onHandleSubmit,
    incidentName,
    permissions,
    isOnline: healthRef.current,
    hasOldData,
    files,
  };

  return (
    <>
      <IADetailsView {...combineProps} />;
    </>
  );
};

export default IADetailsPage;
