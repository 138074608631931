import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { PAJPDAStatus } from '@/common/enum';
import { yupResolver } from '@hookform/resolvers/yup';
import { Nullable } from 'interfaces/Nullable.ts';
import { DMAFileTypes } from 'interfaces/ia.interface.ts';

export interface PAFormData {
  id?: Nullable<string>;
  deleted?: number;
  user_id: number;
  user_group_id: Nullable<number>;
  updated_at: Nullable<string>;
  created_at: Nullable<string>;
  group_id: Nullable<number>;
  site_number: Nullable<number>;
  category: Nullable<string>;
  building_type: Nullable<string>;
  class_code: Nullable<string>;
  damage_description: Nullable<string>;
  floodplain: Nullable<string>;
  old_facility: Nullable<string>;
  impact: Nullable<string>;
  tax_authority_group: Nullable<string>;
  percent_complete: Nullable<string>;
  cost_estimate: Nullable<string>;
  insurance_status: Nullable<string>;
  notes: Nullable<string>;
  address: Nullable<string>;
  address2: Nullable<string>;
  county: Nullable<string>;
  city: Nullable<string>;
  state: Nullable<string>;
  zipCode: Nullable<string>;
  latitude: Nullable<number>;
  longitude: Nullable<number>;
  parcel_id: Nullable<string>;
  comments: Nullable<string>;
  incident_id: number;
  date: Nullable<string> | Date;
  applicant_county: Nullable<string>;
  name: Nullable<string>;
  phone: Nullable<string>;
  email: Nullable<string>;
  applicant_id: Nullable<string>;
  applicant_name?: Nullable<string>;
  team_cost_estimate: Nullable<number>;
  applicant_cost_estimate: Nullable<number>;
  impacted_department: Nullable<string>;
  pvGlobalID: string;
  pvParcelGeometryObject: Nullable<object>;
  state_modified: Nullable<number>;
  posting: Nullable<string>;
  jpda_status?: Nullable<string>;
  files: Partial<DMAFileTypes>[];
  _id?: string;
  _rev?: string;
  filesToDelete?: any[];
  has_files?: number;
}

export const defaultValues: PAFormData = {
  id: null,
  user_id: 0,
  user_group_id: null,
  updated_at: null,
  created_at: null,
  group_id: 0,
  site_number: null,
  category: null,
  building_type: null,
  class_code: null,
  damage_description: null,
  floodplain: null,
  old_facility: null,
  impact: null,
  tax_authority_group: null,
  percent_complete: null,
  cost_estimate: null,
  insurance_status: null,
  notes: null,
  address: null,
  address2: null,
  county: null,
  city: null,
  state: null,
  zipCode: null,
  latitude: null,
  longitude: null,
  parcel_id: null,
  comments: null,
  incident_id: 0,
  date: new Date(),
  applicant_county: null,
  name: null,
  phone: null,
  email: null,
  applicant_id: null,
  applicant_name: '',
  team_cost_estimate: null,
  applicant_cost_estimate: null,
  impacted_department: null,
  pvGlobalID: '',
  pvParcelGeometryObject: null,
  state_modified: null,
  posting: null,
  jpda_status: PAJPDAStatus.siteNotVisited,
  files: [],
  has_files: 0,
};

function usePADataForm() {
  const validationSchema = useMemo(
    () =>
      object().shape({
        category: string().required('Category is required'),
        email: string().email('Invalid email').nullable(),
        zipCode: string()
          .nullable()
          .test(
            'length',
            'Zip code must be exactly 5 characters',
            (val) => !val || val.length === 5
          ),
      }),
    []
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useForm<any>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
}

export default usePADataForm;
