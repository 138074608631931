/* eslint-disable @typescript-eslint/no-explicit-any,react-hooks/exhaustive-deps,react-refresh/only-export-components,no-unused-vars */
import React, { useEffect, useRef } from 'react';
import { TextFieldProps as MuiTFieldProps } from '@mui/material/TextField';
import { TextField } from 'components/primitive';
import {
  AddressClickType,
  AddressSelectCBType,
  SearchInputLocationProps,
} from 'interfaces/SearchInputLocationTypes.ts';
import { useAppSelector } from 'store/hooks.ts';
import { selectConnectionHealth } from 'store/connection/connection.selector.ts';

const googleAPIKey = import.meta.env.VITE_GOOGLE_API_KEY;

export async function destructureAddressIntoComponents(
  resultsWithoutLatLng: AddressClickType[],
  callback: (e: AddressSelectCBType) => void
) {
  const results: any = await geocodeByAddress(resultsWithoutLatLng[0].formatted_address);

  let streetNumber;
  let streetName;
  let address2 = '';
  let city = '';
  let county = '';
  let state = '';
  let zipCode = '';

  for (let i = 0; i < results[0].address_components.length; i++) {
    if (results[0].address_components[i].types[0] === 'street_number') {
      streetNumber = results[0].address_components[i].long_name;
    } else if (results[0].address_components[i].types[0] === 'route') {
      streetName = results[0].address_components[i].long_name;
    } else if (results[0].address_components[i].types[0] === 'neighborhood') {
      address2 = results[0].address_components[i].long_name;
    } else if (results[0].address_components[i].types[0] === 'locality') {
      city = results[0].address_components[i].long_name;
    } else if (results[0].address_components[i].types[0] === 'administrative_area_level_1') {
      state = results[0].address_components[i].long_name;
    } else if (results[0].address_components[i].types[0] === 'administrative_area_level_2') {
      county = results[0].address_components[i].long_name;
      county =
        county.indexOf(' County') !== -1 ? county.substring(0, county.indexOf(' County')) : county;
    } else if (results[0].address_components[i].types[0] === 'postal_code') {
      zipCode = results[0].address_components[i].long_name;
    }
  }

  const a = {
    address1: [streetNumber, streetName].filter(Boolean).join(' '),
    address2: address2,
    city: city,
    zipCode: zipCode,
    state: state,
    county: county,
    fullAddress: results[0].formatted_address,
    latitude: (results[0].geometry && results[0].geometry.location.lat().toFixed(8)) || undefined,
    longitude: (results[0].geometry && results[0].geometry.location.lng().toFixed(8)) || undefined,
  };
  callback && callback(a);
}

export const geocodeByAddress = (address: string) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  if (!window.google) return;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  const geocoder = new window.google.maps.Geocoder();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  const OK = window.google.maps.GeocoderStatus.OK;

  return new Promise((resolve, reject) => {
    geocoder.geocode({ address }, (results: any, status: any) => {
      if (status !== OK) {
        reject(status);
      }
      resolve(results);
    });
  });
};

export const geoCodeFromLatLonInput = async (
  lat: number,
  lon: number,
  callback: (a: AddressSelectCBType) => void
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  if (!window.google) return;
  const lat_lng = { lat: lat, lng: lon };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  const geocoder = new window.google.maps.Geocoder();
  geocoder.geocode({ location: lat_lng }, (results: any, status: any) => {
    if (status === 'OK') {
      destructureAddressIntoComponents(results, (addressObj) => {
        callback(addressObj);
      });
    }
  });
};

export const SearchInputLocation: React.FC<SearchInputLocationProps & MuiTFieldProps> = (props) => {
  const healthRef = useRef(false);
  healthRef.current = useAppSelector(selectConnectionHealth);

  const autoCompleteRef = useRef(null);

  let autoComplete: any;
  let placeChangedListener: any;
  // To prevent the error below, we destructured the prop object like this
  // Warning: Invalid value for prop callback on <div> tag.Either remove it from the element, or pass a string or number value to keep it in the DOM.
  const { callbackAddressSelect, onChange, value } = props;
  const handleAddressClick = (addresses: AddressClickType) => {
    // noinspection JSIgnoredPromiseFromCall
    destructureAddressIntoComponents([addresses], (formattedAddress) => {
      callbackAddressSelect && callbackAddressSelect(formattedAddress);
    });
  };

  const loadScript = (url: string, callback: () => void) => {
    if (document.querySelector('#pcl-sci-gm-api-v3')) {
      callback();
      return;
    }
    const script: any = document.createElement('script');
    script.id = 'pcl-sci-gm-api-v3';
    script.type = 'text/javascript';

    if (script.readyState) {
      script.onreadystatechange = () => {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  };

  function handleScriptLoad(updateQuery: any, autoCompleteRef: any) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    if (!window.google || !autoCompleteRef) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
      componentRestrictions: { country: 'us' },
    });
    autoComplete.setFields(['address_components', 'formatted_address']);
    placeChangedListener = autoComplete.addListener('place_changed', () =>
      handlePlaceSelect(updateQuery)
    );
  }

  async function handlePlaceSelect(updateQuery: any) {
    const addressObject = autoComplete.getPlace();
    // This return address is in a comma seperated format
    // const query = addressObject.formatted_address;
    updateQuery(addressObject);
  }

  useEffect(() => {
    if (!healthRef.current) return;
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${
        'AIzaSyDpfXqpJ4ieDYmvrZcUBG_arjixqjTJ06s' || googleAPIKey
      }&libraries=drawing,geometry,places,visualization`,
      () => handleScriptLoad(handleAddressClick, autoCompleteRef)
    );

    return () => {
      // Anything in here is fired on a component unmounted.
      placeChangedListener && placeChangedListener.remove();
    };
  }, [healthRef.current]);

  return (
    <TextField
      label={props.label}
      error={props.error}
      errMsg={props.errMsg}
      inputRef={autoCompleteRef}
      value={value}
      placeholder={props.InputProps && props.InputProps.readOnly ? ' ' : props.placeholder}
      onChange={onChange}
      required={props.required}
      InputProps={props.InputProps}
    />
  );
};

export default SearchInputLocation;
