import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks.ts';
import { WSRoomNames } from '@/common/enum';

const WEBSOCKET_URL = import.meta.env.VITE_APP_PALMETTO_WEBSOCKET_URL;
const POLL_TIME = 10000; // How often to poll the connection state
const RETRY_TIME = 30 * 60 * 1000; // timeout for retry
const RECONNECT_DELAY = 5000; // delay before reconnecting

type CallbackFunction = (data: any, category: string, dispatch: any, userId: number) => void;
type WsConnectValues = {
  userid: number;
  groupid: number;
  superadmin: boolean;
  access_token: string;
};

let ws: WebSocket | null = null;
let connectionActive = false;
const joinedRooms: Record<string, boolean> = {};
let pollInterval: NodeJS.Timeout | null = null;
let timeoutInterval: NodeJS.Timeout | null = null;
let isWsReady: boolean | null = null;
let connectionCallbacks: (() => void)[] = [];
const roomCallbacks: Record<string, CallbackFunction> = {};
let wsConnectValues: WsConnectValues | null = null;
let connectionStateChangeCallback: ((isActive: boolean) => void) | null = null;

export const useWebSocket = () => {
  const dispatch = useAppDispatch();

  const setConnectionStateChangeCallback = useCallback((func: (isActive: boolean) => void) => {
    if (func) {
      connectionStateChangeCallback = func;
    }
  }, []);

  const pollConnection = useCallback(() => {
    if (ws) {
      const status = ws.readyState;
      connectionActive = status === WebSocket.OPEN;
      if (status !== WebSocket.OPEN) {
        stopPolling();
      } else {
        ws.send(
          JSON.stringify({
            action: 'pingpong',
          })
        );
      }
    }
  }, []);

  const stopPolling = useCallback(() => {
    if (pollInterval) {
      clearInterval(pollInterval);
      pollInterval = null;
    }
    if (connectionStateChangeCallback) {
      connectionStateChangeCallback(false);
    }
  }, []);

  const retry = useCallback(() => {
    if (connectionActive) {
      return;
    }
    if (ws) {
      ws.close();
      ws = null;
    }
    connectionActive = false;
    if (timeoutInterval) {
      clearInterval(timeoutInterval);
      timeoutInterval = null;
    }
    stopPolling();

    if (wsConnectValues) {
      connect(
        wsConnectValues.userid,
        wsConnectValues.groupid,
        wsConnectValues.superadmin,
        wsConnectValues.access_token
      );
    }
  }, [connectionActive, stopPolling]);

  const connect = useCallback(
    async (userid: number, groupid: number, superadmin: boolean, access_token: string) => {
      wsConnectValues = { userid, groupid, superadmin, access_token };
      if (connectionActive) {
        return;
      }

      try {
        ws = new WebSocket(
          `${WEBSOCKET_URL}?userid=${userid}&groupid=${groupid}&superadmin=${superadmin}&access_token=${access_token}`
        );
      } catch (err) {
        connectionActive = false;
        return;
      }

      if (connectionStateChangeCallback) {
        connectionStateChangeCallback(true);
      }

      ws.onopen = () => {
        connectionActive = true;
        isWsReady = true;
        connectionCallbacks.forEach((callback) => callback());
        connectionCallbacks = [];
        if (Object.keys(joinedRooms).length > 0) {
          ws!.send(
            JSON.stringify({
              action: 'join',
              data: {
                room: Object.keys(joinedRooms),
              },
            })
          );
        }
      };

      ws.onclose = () => {
        connectionActive = false;
        isWsReady = false;
        stopPolling();
        if (timeoutInterval) {
          clearInterval(timeoutInterval);
          timeoutInterval = null;
        }

        setTimeout(() => {
          retry();
        }, RECONNECT_DELAY);
      };

      ws.onmessage = (msg) => {
        if (msg) {
          let parsed = null;
          try {
            parsed = JSON.parse(msg.data);
          } catch (err) {
            return;
          }

          if (parsed.room === 'pong') {
            return;
          }

          if (parsed.room && roomCallbacks[parsed.room]) {
            const category = parsed.room === WSRoomNames.DMA_APP_PARENT_ROOM_NAME_IA ? 'ia' : 'pa';
            roomCallbacks[parsed.room](
              parsed.data,
              category,
              dispatch,
              wsConnectValues?.userid || 0
            );
          } else {
            console.log('Room not in callbackRooms:', parsed.room);
          }
        }
      };

      pollInterval = setInterval(pollConnection, POLL_TIME);

      timeoutInterval = setTimeout(() => {
        retry();
      }, RETRY_TIME);
    },
    [connectionActive, pollConnection, retry, joinedRooms]
  );

  const joinRoom = useCallback(
    (room: string, parentRoom: string, callback: CallbackFunction) => {
      if (joinedRooms[room]) return;

      const joinAction = () => {
        if (ws && ws.readyState === 1) {
          // console.log('Joining Room:', room, parentRoom);
          ws.send(
            JSON.stringify({
              action: 'join',
              data: {
                room: [room],
              },
            })
          );
        }
      };
      // console.log('IS WS READY', isWsReady.current);
      if (isWsReady) {
        joinAction();
      } else {
        // console.log('WebSocket not ready, queuing join action for room:', room, parentRoom);
        connectionCallbacks.push(joinAction);
      }

      roomCallbacks[parentRoom] = callback;
      joinedRooms[room] = true;
    },
    [joinedRooms, isWsReady]
  );

  return {
    connect,
    retry,
    setConnectionStateChangeCallback,
    joinRoom,
    connectionActive,
  };
};
