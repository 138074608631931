/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks.ts';
import { mapInfoSelector } from 'store/map/map.selector.ts';
import Box from '@mui/material/Box';
import { IconButton, useTheme, Badge } from '@mui/material';
import useStyles from './SuffixButtons.styles.ts';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ModalLegends from '../ModalLegends';
import SignalWifiConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiConnectedNoInternet4';
import { selectConnectionHealth } from 'store/connection/connection.selector.ts';
import ListIcon from '@mui/icons-material/List';
import { useNavigate } from 'react-router-dom';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import { updateMapInfo } from 'store/map/map.action.ts';
import { iaUnsavedDataSelector } from 'store/ia/ia.selector.ts';
import { paUnsavedDataSelector } from 'store/pa/pa.selector.ts';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import HolidayVillageRoundedIcon from '@mui/icons-material/HolidayVillageRounded';
import { shallowEqual } from 'react-redux';
import { selectNonParcelMode } from 'store/accountsInfo/accountsInfo.selector.ts';
import { isMobile as isMobileDeviceDetect } from 'react-device-detect';

interface ISuffixProps {
  handleHeaderMenuClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const SuffixButtons: React.FC<ISuffixProps> = ({ handleHeaderMenuClick }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [isMobileDevice, setIsMobileDevice] = React.useState(false);
  const classes = useStyles(theme, isMobileDevice);
  const navigate = useNavigate();
  const [modalLegends, setModalLegends] = useState<boolean>(false);
  const categorySelected = useAppSelector(mapInfoSelector).category;
  const health = useAppSelector(selectConnectionHealth);
  const handleLegends = () => setModalLegends((prevState) => !prevState);
  const [numberOfUnsavedItems, setNumberOfUnsavedItems] = useState<number>(0);
  const dropMarkerMode = useAppSelector(mapInfoSelector).dropMarkerMode;
  const quickAssessmentMode = useAppSelector(mapInfoSelector).quickAssessmentMode;
  const [isLocationButtonDisabled, setIsLocationButtonDisabled] = useState(false);
  const nonParcelMode: boolean = !!useAppSelector(selectNonParcelMode);

  const iaUnsavedData = useAppSelector(iaUnsavedDataSelector, shallowEqual);
  const paUnsavedData = useAppSelector(paUnsavedDataSelector, shallowEqual);

  useEffect(() => {
    if (isMobileDeviceDetect) setIsMobileDevice(true);
  }, []);

  const handleQueueClick = () => {
    navigate('queue');
  };

  const handleLocationClick = () => {
    dispatch(updateMapInfo({ dropMarkerMode: !dropMarkerMode }));
    dispatch(updateMapInfo({ quickAssessmentMode: false }));
    setIsLocationButtonDisabled(true);
    setTimeout(() => {
      setIsLocationButtonDisabled(false);
    }, 1000);
  };

  const handleQuickAssessmentMode = () => {
    dispatch(updateMapInfo({ quickAssessmentMode: !quickAssessmentMode }));
  };

  useEffect(() => {
    if (categorySelected === 'ia') {
      setNumberOfUnsavedItems(iaUnsavedData?.length || 0);
    } else {
      setNumberOfUnsavedItems(paUnsavedData?.length || 0);
    }
  }, [categorySelected, iaUnsavedData, paUnsavedData]);

  return (
    <>
      <Box sx={classes.container}>
        {!nonParcelMode && (
          <IconButton onClick={handleQuickAssessmentMode} sx={classes.suffixButton}>
            {quickAssessmentMode ? (
              <HolidayVillageRoundedIcon fontSize={'small'} sx={classes.suffixIcon} />
            ) : (
              <HouseRoundedIcon fontSize={'small'} sx={classes.suffixIcon} />
            )}
          </IconButton>
        )}
        <IconButton onClick={handleQueueClick} sx={classes.suffixButton}>
          {numberOfUnsavedItems ? (
            <Badge badgeContent={numberOfUnsavedItems} color={'error'}>
              <ListIcon fontSize={'small'} sx={classes.suffixIcon} />
            </Badge>
          ) : (
            <ListIcon fontSize={'small'} sx={classes.suffixIcon} />
          )}
        </IconButton>
        {!health && (
          <IconButton sx={classes.suffixButton}>
            <SignalWifiConnectedNoInternet4Icon fontSize={'small'} sx={classes.suffixIcon} />
          </IconButton>
        )}
        <IconButton onClick={handleLegends} sx={classes.suffixButton}>
          <FlagCircleIcon fontSize={'small'} sx={classes.suffixIcon} />
        </IconButton>
        <IconButton
          onClick={handleLocationClick}
          disabled={isLocationButtonDisabled}
          sx={classes.suffixButton}>
          <AddLocationIcon
            fontSize={'small'}
            sx={{ color: theme.palette.common.white, opacity: dropMarkerMode ? 1 : 0.5 }}
          />
        </IconButton>
        <IconButton onClick={(e) => handleHeaderMenuClick(e)} sx={classes.suffixButton}>
          <MoreVertIcon fontSize={'small'} sx={classes.suffixIcon} />
        </IconButton>
      </Box>
      <ModalLegends isOpen={modalLegends} onClose={() => setModalLegends(false)} />
    </>
  );
};

export default SuffixButtons;
