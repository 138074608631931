import { Nullable } from 'interfaces/Nullable.ts';
import { TeamInterface } from 'interfaces/team.interface.ts';

export type TeamTypes = {
  entities: Nullable<TeamInterface>;
};

export const TeamInitialState: TeamTypes = {
  entities: null,
};
