import { store } from '@/store';
import { resetAuthToken } from '@/store/authToken/authToken.slice';
import { resetAccountsInfo } from '@/store/accountsInfo/accountsInfo.slice';
import { resetUserAccount } from 'store/userAccount/userAccount.slice.ts';
import { resetIncidents } from 'store/incidents/incidents.slice.ts';
import { resetApplicant } from 'store/applicants/applicants.slice.ts';
import { destroyAuthDB } from 'store/authToken/authToken.thunk.ts';

export const logout = () => {
  const dispatch = store.dispatch;

  dispatch(resetAuthToken());
  dispatch(resetAccountsInfo());
  dispatch(resetUserAccount());
  dispatch(resetIncidents());
  dispatch(resetApplicant());
  dispatch(destroyAuthDB());
  sessionStorage.clear();
  localStorage.clear();
  window.location.href = '/login'; // Navigate to login page
};
