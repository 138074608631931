const PACategoryValues = {
  DebrisRemoval: { value: 'A', label: 'Debris Removal' },
  EmergencyProtectiveMeasures: { value: 'B', label: 'Emergency Protective Measures' },
  RoadsAndBridges: { value: 'C', label: 'Roads and Bridges' },
  WaterControlFacilities: { value: 'D', label: 'Water Control Facilities' },
  BuildingsAndEquipment: { value: 'E', label: 'Buildings and Equipment' },
  Utilities: { value: 'F', label: 'Utilities' },
  ParksRecreationAndOther: { value: 'G', label: 'Parks, Recreation, and Other' },
};

export const PACategoryColors: { [key: string]: string } = {
  A: '#FF4181',
  B: '#5C00A4',
  C: '#DB60CE',
  D: '#003771',
  E: '#F57E23',
  F: '#F44336',
  G: '#007499',
};

export default PACategoryValues;
