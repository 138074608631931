import { Nullable } from 'interfaces/Nullable.ts';
import { IUserPortal } from 'interfaces/UserAccountInfo.interface.ts';

export type UserAccountTypes = {
  userAccount: Nullable<Partial<IUserPortal>>;
};

export const UserAccountInitialState: UserAccountTypes = {
  userAccount: null,
};
