import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks.ts';
import RedirectView from './RedirectView.tsx';
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  FormControl,
  FormLabel,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button, ErrorMessage, Modal, Typo } from 'components/primitive';
import { cognitoLogin } from 'store/authToken/authToken.thunk.ts';
import { setUserAccount } from 'store/userAccount/userAccount.action.ts';
import { selectConnectionHealth } from 'store/connection/connection.selector.ts';
import { useStyles } from 'components/fragment/ConfirmModal/ConfirmModal.style.ts';
import WifiOffIcon from '@mui/icons-material/WifiOff';
const appTitle = import.meta.env.VITE_APP_TITLE ?? 'Damage Assessment';
import { useMediaQuery } from '@/hooks';

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const healthRef = useRef(false);
  const health = useAppSelector(selectConnectionHealth);
  healthRef.current = useAppSelector(selectConnectionHealth);
  const { isMobileDevice } = useMediaQuery();
  const [errors, setErrors] = useState('');
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    username: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const toggleVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async () => {
    const payload = {
      username: form.username,
      password: form.password,
    };

    return await dispatch(cognitoLogin(payload)).unwrap();
  };

  function loginFunc() {
    if (!form.username) {
      return setErrors('Please enter a username.');
    }
    if (!form.password) {
      return setErrors('Please enter a password.');
    }
    if (healthRef.current) {
      setLoading(true);
      onSubmit()
        .then((data) => {
          if (data) {
            dispatch(setUserAccount(data));
            navigate('/loading');
          }
        })
        .catch((error) => {
          let message = 'Something went wrong!';
          if (error instanceof Error) message = error.message;
          else {
            if (typeof error === 'object' && error !== null) {
              if ('message' in error) {
                message = String(error.message);
              }
            }
          }
          setErrors(message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setErrors('Please check your internet connection.');
    }
  }

  const handleUsernameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setForm((prevState) => ({ ...prevState, username: event.target.value }));
  };
  const handlePasswordChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setForm((prevState) => ({ ...prevState, password: event.target.value }));
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      loginFunc();
    }
  };

  const offlineModal = () => {
    return (
      <Modal open={true} onClose={undefined}>
        <>
          <Box sx={{ margin: '24px' }}>
            <div className='layout horizontal'>
              <WifiOffIcon
                style={{
                  fontSize: '32px',
                  color: 'rgba(0,0,0,0.54)',
                  marginBottom: '24px',
                }}
              />
            </div>
            <Box>
              <Typo sx={{ fontSize: '24px!important' }}>No internet connection.</Typo>
            </Box>
            <Box>
              <Typo sx={{ fontSize: '15px!important' }}>
                Please check your internet connection.
              </Typo>
            </Box>
          </Box>
          <Box sx={classes.footer}>
            <Box sx={classes.footerWrapper}>
              <Button fullWidth onClick={() => window.location.reload()}>
                TRY AGAIN
              </Button>
            </Box>
          </Box>
        </>
      </Modal>
    );
  };

  // noinspection HtmlUnknownTarget
  return (
    <>
      {!isMobileDevice ? (
        <RedirectView />
      ) : (
        <Box
          style={{ caretColor: 'transparent' }}
          className='layout vertical center vertical-center full-height'>
          <div className='layout vertical logincontainer' style={{ gap: '8px' }}>
            <div className='layout horizontal center center-justified app-logo-container'>
              <img className='app-logo' src='/images/launcher.png' alt='Palmetto Logo' />
            </div>
            <div className='layout horizontal center-justified'>
              <span className='app-title'>{appTitle}</span>
            </div>

            {errors.length ? (
              <div style={{ margin: '20px 50px 0 50px' }}>
                <ErrorMessage message={errors} />
              </div>
            ) : (
              ''
            )}
            <div
              style={{ caretColor: 'auto' }}
              onKeyUp={(e) => handleKeyPress(e)}
              className='flex login-input-container'>
              <div className='layout horizontal' style={{ marginBottom: '24px' }}>
                <FormControl variant='outlined' fullWidth>
                  <FormLabel htmlFor='username-box' sx={{ marginBottom: '8px' }}>
                    Username
                  </FormLabel>
                  <OutlinedInput
                    name='Username'
                    id='username-box'
                    placeholder='Enter username'
                    className='flex'
                    value={form.username}
                    onChange={(e) => handleUsernameChange(e)}
                  />
                </FormControl>
              </div>
              <div className='layout horizontal'>
                <FormControl variant='outlined' fullWidth>
                  <FormLabel htmlFor='password-box' sx={{ marginBottom: '8px' }}>
                    Password
                  </FormLabel>
                  <OutlinedInput
                    name='Password'
                    id='password-box'
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Enter password'
                    className='flex'
                    value={form.password}
                    onChange={(e) => handlePasswordChange(e)}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          edge='end'
                          onClick={toggleVisibility}
                          onMouseDown={toggleVisibility}>
                          {showPassword ? (
                            <Visibility fontSize='small' />
                          ) : (
                            <VisibilityOff fontSize='small' />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div className='height-20'></div>
              <div className='layout horizontal'>
                <Button
                  disabled={loading}
                  className='flex login-btn'
                  onClick={() => loginFunc()}
                  style={{ maxWidth: 'unset' }}>
                  {!loading ? 'Login' : ''}
                  <CircularProgress
                    style={{ display: loading ? 'block' : 'none', width: '28px', height: '28px' }}
                    sx={{
                      color: '#ffffff',
                    }}
                  />
                </Button>
              </div>
            </div>
          </div>
          <p style={{ fontSize: '12px', color: 'rgba(0,0,0,0.54)', textAlign: 'center' }}>
            All data in Palmetto is to be considered FOUO and not for public dissemination.
          </p>
        </Box>
      )}
      {!health && offlineModal()}
    </>
  );
};

export default Login;
