import type { RootState } from '@/store';

export const incidentForParcelSyncSelector = (state: RootState) =>
  state.incidents.selectedIncidentForParcelSync;
export const parcelSyncIncidentNameSelector = (state: RootState) =>
  state.incidents?.selectedIncidentForParcelSync?.pvIncidentName;
export const isJPDA_IA_ActiveSelector = (state: RootState) => state.incidents.isJPDA_IA_Active;
export const isJPDA_PA_ActiveSelector = (state: RootState) => state.incidents.isJPDA_PA_Active;
export const parcelSyncIncidentIDSelector = (state: RootState) =>
  state.incidents.selectedIncidentForParcelSyncID;
