import type IEndPoint from 'interfaces/EndPointTypes.ts';
import { AuthProtect, BaseURLSelect } from '@/common/enum';
import { IADetails } from 'interfaces/ia.interface.ts';

export const getIADataSet = (url: string): IEndPoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url,
    defaultBaseURL: BaseURLSelect.DMA,
  };
};

export const postIAData = (url: string, data: Partial<IADetails>): IEndPoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: data?.id ? 'PUT' : 'POST',
    url: url,
    defaultBaseURL: BaseURLSelect.DMA,
    data,
  };
};

export const getIADataByID = (url: string): IEndPoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url,
    defaultBaseURL: BaseURLSelect.DMA,
  };
};
