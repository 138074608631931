import { useEffect, useState, useRef, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { SubmitHandler } from 'react-hook-form';
import { defaultValues as PADefaultValues } from '../../PA/hook/usePADataForm.ts';
import usePAmultiForm, { defaultValues, PAmultipleFormData } from '../hook/usePAmultiForm.ts';
import { ToastMessage } from 'components/primitive';
import { useAppSelector, useAppDispatch } from 'store/hooks.ts';
import {
  accountGroupIDSelector,
  selectAccount,
  selectedGroupNameSelector,
} from 'store/accountsInfo/accountsInfo.selector.ts';
import { savePAData } from 'store/pa/pa.thunk.ts';
import { ToastStatus } from '@/common/enum';
import { convertToTitleCase, DateTimeFormat, formatMailCityState } from '@/utilities';
import { PADetail } from 'interfaces/pa.detail.interface.ts';
import { PAmultiDetailsViewProps } from './PAmultiDetails.props.ts';
import PAmultiDetailsView from './PAmultiDetailsView.tsx';
import { findByQuery, putDocument } from 'store/db/db.thunk.ts';
import { selectConnectionHealth } from 'store/connection/connection.selector.ts';
import { ParcelData } from 'components/fragment/ParcelViewModal/ParcelViewModal.props.tsx';
import moment from 'moment';
import {
  parcelSyncIncidentIDSelector,
  parcelSyncIncidentNameSelector,
} from 'store/incidents/incidents.selector.ts';
import { setPADataSet } from 'store/pa/pa.actions.ts';
import { selectedApplicantSelector } from 'store/applicants/applicants.selector.ts';

interface IDocToSavePA extends PADetail {
  type: 'parcel' | 'marker';
  dma_category: string;
  isUnsaved: boolean;
  parcel_id: string | null;
  _id?: string;
  _rev?: string;
}

const PAmultiDetailsPage: FC<{
  multiParcelsData: ParcelData[];
}> = ({ multiParcelsData }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const form = usePAmultiForm();
  const palmettoUser = useAppSelector(selectAccount);
  const countyGroupName = useAppSelector(selectedGroupNameSelector);
  const selectedIncidentForParcelSync = useAppSelector(parcelSyncIncidentIDSelector);
  const selectedGroupID = useAppSelector(accountGroupIDSelector);
  const selectedApplicant = useAppSelector(selectedApplicantSelector);
  const incidentName = useAppSelector(parcelSyncIncidentNameSelector) || '';

  const healthRef = useRef(false);
  healthRef.current = useAppSelector(selectConnectionHealth);

  const defaultAssessmentTeamMember =
    palmettoUser !== null
      ? `${palmettoUser?.pvPersonGivenName} ${palmettoUser?.pvPersonSurName}`
      : '';

  const incidentID = Number(selectedIncidentForParcelSync) ?? 0;

  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    const data: any = {
      ...defaultValues,
    };
    Object.keys(data).forEach((key) => {
      if (Object.keys(data).includes(key)) {
        const value = data[key];
        if (value) {
          form.setValue(key, value);
        }
      }
    });
  }, [form]);

  const goBack = () => {
    navigate(-1);
  };

  const saveData = async (payload: any) => {
    const savedItem = {};
    await dispatch(savePAData(payload)).then((res: any) => {
      if (res.type === 'ia/savePAData/rejected') {
        throw new Error(res.error.message);
      }
      Object.assign(savedItem, res.payload);
    });
    return savedItem;
  };

  const onHandleSubmit: SubmitHandler<PAmultipleFormData> = async (data) => {
    try {
      if (!palmettoUser) {
        toast.info(<ToastMessage status={ToastStatus.ERROR} message='Missing User' />);
        return;
      }
      setIsSaving(true);
      //loop multiparcels data
      let offlineSavedCount = 0;
      let uploadedCount = 0;
      for (let i = 0; i < multiParcelsData.length; i++) {
        const parcel = multiParcelsData[i];
        const [city, state, zipCode] = formatMailCityState(parcel?.MailCityState);

        const payload: any = {
          ...PADefaultValues,
          pvGlobalID: uuidv4(),
          category: data.category,
          damage_description: data.damage_description ?? null,
          county: convertToTitleCase(countyGroupName ?? ''),
          incident_id: incidentID,
          parcel_id: parcel.PIN,
          group_id: selectedGroupID ?? 0,
          replacement_cost: parcel?.MarketValue ?? 0,
          address: parcel?.SiteAddress ?? '',
          city: city,
          state: state,
          zipCode: zipCode,
          applicant_name: selectedApplicant?.applicant_name ?? '',
          applicant_county: countyGroupName,
          applicant_id: selectedApplicant?.id ?? 0,
          name: defaultAssessmentTeamMember,
          phone: palmettoUser?.pvOfficePhone ?? null,
          email: palmettoUser?.email ?? null,
          pvParcelGeometryObject: parcel?.latlngCoords
            ? JSON.stringify({ coordinates: parcel?.latlngCoords })
            : null,
          date: DateTimeFormat({ dte: new Date(), format: 'YYYY-MM-DD HH:mm:ss' }),
          files: [],
          has_files: 0,
        };
        if (healthRef.current) {
          await onSubmit(payload);
          uploadedCount++;
        } else {
          const docToSave: IDocToSavePA = {
            ...payload,
            dma_category: 'pa',
            created_at: moment(new Date(), 'YYYY-MM-DD HH:mm:ss').toISOString(),
            updated_at: moment(new Date(), 'YYYY-MM-DD HH:mm:ss').toISOString(),
            isUnsaved: true,
          };
          await putDocument(docToSave);
          offlineSavedCount++;
        }
      }
      const getPouchPAData = (await findByQuery({
        selector: { dma_category: 'pa' },
      })) as any;
      dispatch(setPADataSet(getPouchPAData));
      const messageForOffline = offlineSavedCount
        ? `${offlineSavedCount} parcel` + (offlineSavedCount > 1 ? 's' : '') + ' offline'
        : '';
      let messageForOnline = uploadedCount
        ? `${uploadedCount} parcel` + (uploadedCount > 1 ? 's' : '')
        : '';
      if (messageForOffline && messageForOnline) {
        messageForOnline += ' and ';
      }
      toast.info(
        <ToastMessage
          status={ToastStatus.SUCCESS}
          message={`Successfully Saved ${messageForOnline} ${messageForOffline}`}
        />
      );
      setIsSaving(false);
      navigate(-1);
    } catch (error) {
      toast.error(<ToastMessage status={ToastStatus.ERROR} message='Encountered System Error' />);
      setIsSaving(false);
      throw error;
    }
  };

  const onSubmit: SubmitHandler<PAmultipleFormData> = async (payload: any) => {
    //if is new item we get the response from saveData, if not we make a call to get the response
    const response: any = await saveData(payload);

    //format response, save in PouchDB and set in state
    if (response?.created_at) {
      response.created_at = moment(response.created_at, 'YYYY-MM-DD HH:mm:ss').toISOString();
    }
    if (response?.updated_at) {
      response.updated_at = moment(response.updated_at, 'YYYY-MM-DD HH:mm:ss').toISOString();
    }

    const docToSave: IDocToSavePA = {
      ...response,
      dma_category: 'pa',
    };
    await putDocument(docToSave);
  };

  const combineProps: PAmultiDetailsViewProps = {
    form,
    inProcess: isSaving,
    goBack,
    onHandleSubmit,
    numberOfParcels: multiParcelsData.length,
    incidentName,
  };

  return (
    <>
      <PAmultiDetailsView {...combineProps} />;
    </>
  );
};

export default PAmultiDetailsPage;
