import type { RootState } from '@/store';
import { ApplicantType } from 'interfaces/applicants.interface.ts';

const applicantsSelector = (state: RootState) => {
  const applicants = state.applicants.applicants as ApplicantType[];

  if (!applicants) {
    return [];
  }

  return applicants.slice().sort((a: ApplicantType, b: ApplicantType) => {
    return a.applicant_name.localeCompare(b.applicant_name);
  });
};
const selectedApplicantSelector = (state: RootState) => state.applicants.selectedApplicant;
const userSelectedApplicantSettingIDSelector = (state: RootState) =>
  state.applicants.userSelectedApplicantSettingID;
const queryStateApplicantsSelector = (state: RootState) => state.applicants.queryState;

export {
  applicantsSelector,
  selectedApplicantSelector,
  userSelectedApplicantSettingIDSelector,
  queryStateApplicantsSelector,
};
