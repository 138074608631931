import React from 'react';
import { useTheme } from '@mui/material';
import useStyles from './Redirect.styles.ts';
import Box from '@mui/material/Box';
import { Typo } from 'components/primitive';

const Redirect: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Box sx={classes.container}>
      <Typo sx={classes.messageStyle}>
        All data in Palmetto is to be considered FOUO and not for public dissemination.
      </Typo>
    </Box>
  );
};

export default Redirect;
