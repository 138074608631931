import { QueryState } from '@/common/enum';
import { Nullable } from 'interfaces/Nullable.ts';

export type MapStateTypes = {
  queryState: QueryState;
  error: Nullable<string>;
  mapInfo: {
    mapId: Nullable<string>;
    mapSettingsID: Nullable<number>;
    category: Nullable<string>;
    dropMarkerMode: boolean;
    quickAssessmentMode: boolean;
  };
};

export const MapInitialState: MapStateTypes = {
  queryState: QueryState.UNDEFINED,
  error: null,
  mapInfo: {
    mapId: null,
    mapSettingsID: null,
    category: null,
    dropMarkerMode: false,
    quickAssessmentMode: false,
  },
};
