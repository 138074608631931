import React from 'react';
import FormControl from '@mui/material/FormControl';
import { RadioSelectOptions } from 'interfaces/SelectOptions.interface.ts';
import { CustomInputLabel } from '@zawarski/palmetto-ui-components';
import MSelect, { SelectProps } from '@mui/material/Select';
import { FieldError } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import ListItemIcon from '@mui/material/ListItemIcon';

interface SelectInterface extends RadioSelectOptions {
  withEndIcon?: boolean;
}

interface SelectMenuProps {
  values: SelectInterface[] | string[];
  withLabel?: boolean;
  label?: string;
  id?: string;
  fieldError?: FieldError | undefined;
  required?: boolean;
  helperText?: string;
  placeholder?: string;
  iconMap?: { [key: string]: React.ElementType };
}

const SelectMenu: React.FC<SelectMenuProps & SelectProps> = ({
  withLabel = false,
  placeholder = 'Select an option',
  ...props
}) => {
  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
        maxWidth: 400,
      },
    },
  };
  return (
    <FormControl style={{ width: '100%', margin: 0 }}>
      {withLabel && (
        <CustomInputLabel
          error={Boolean(props.error || props.fieldError)}
          shrink
          htmlFor={props.id}
          style={{ margin: 0 }}>
          {props.label ?? ''} <span className={props.required ? 'required' : ''}></span>{' '}
        </CustomInputLabel>
      )}
      <MSelect
        labelId='demo-multiple-checkbox-label'
        id='demo-multiple-checkbox'
        disabled={props.disabled}
        error={props.error || Boolean(props.fieldError)}
        placeholder={placeholder}
        value={props.value}
        onChange={props.onChange}
        MenuProps={menuProps}
        displayEmpty
        renderValue={(selected) => {
          if (!selected) {
            return <span style={{ color: 'rgba(0, 0, 0, 0.38)' }}>{placeholder}</span>;
          }

          // Find the selected item to display its label and icon
          const selectedItem = props.values.find(
            (type) => (typeof type === 'object' ? type.value : type) === selected
          );

          if (selectedItem && typeof selectedItem === 'object') {
            return (
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>{selectedItem.label}</span>
                {selectedItem.withEndIcon && props.iconMap && (
                  <props.iconMap.endIcon style={{ marginLeft: 'auto' }} />
                )}
              </div>
            );
          } else {
            return <span>{selectedItem}</span>;
          }
        }}
        variant='standard'
        sx={{ marginTop: 0 }}>
        {props.values && props.values.length
          ? props.values.map((type, idx) => {
              if (type && typeof type === 'object') {
                return (
                  <MenuItem
                    key={type.value}
                    value={type.value}
                    sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    {type.label}
                    {type.withEndIcon && props.iconMap && (
                      <ListItemIcon>
                        <props.iconMap.endIcon />
                      </ListItemIcon>
                    )}
                  </MenuItem>
                );
              } else {
                return (
                  <MenuItem key={idx} value={type}>
                    {type}
                  </MenuItem>
                );
              }
            })
          : null}
      </MSelect>
      {(Boolean(props.fieldError) || props.error) && (
        <FormHelperText error={Boolean(props.fieldError)} style={{ margin: 0 }}>
          {props.helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectMenu;
