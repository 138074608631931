import { createAsyncThunk } from '@reduxjs/toolkit';
import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';

PouchDB.plugin(PouchDBFind);
const dbInstance = new PouchDB(
  import.meta.env.VITE_APP_TYPE === 'palmetto' ? 'production_db_v2' : 'test_db'
);
declare global {
  interface Window {
    __PALMETTO_POUCH_DB_INSTANCE__: any;
  }
}
window.__PALMETTO_POUCH_DB_INSTANCE__ = dbInstance;

export function putDocument(doc: any) {
  return new Promise((resolve, reject) => {
    let result = null;
    if (doc._id) {
      result = dbInstance.put(doc);
    } else {
      result = dbInstance.post(doc);
    }
    result
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function putDocumentsBulk(docs: any[]) {
  return new Promise((resolve, reject) => {
    if (docs.length === 0) {
      return resolve({ ok: true, message: 'No documents to process' });
    }

    dbInstance
      .bulkDocs(docs)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function getDocument(id: string) {
  return new Promise((resolve, reject) => {
    dbInstance
      .get(id)
      .then((doc: any) => {
        resolve(doc);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function findByQuery(query = { selector: {} }) {
  return new Promise((resolve, reject) => {
    dbInstance
      .find(query)
      .then((result: any) => {
        resolve(result.docs || []);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function deleteDocument(doc: any) {
  return new Promise((resolve, reject) => {
    dbInstance
      .remove(doc)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

/************DANGER ZONE ******************/

export const destroyDB = createAsyncThunk('DESTROY_DB', async () => {
  try {
    if (window.__PALMETTO_POUCH_DB_INSTANCE__) {
      await window.__PALMETTO_POUCH_DB_INSTANCE__.destroy();
      window.location.reload();
    } else {
      throw new Error('Database instance not found');
    }
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else if (typeof error === 'object' && error !== null && 'message' in error) {
      message = String(error.message);
    }
    console.error('Error destroying database:', message);
    throw Error(message);
  }
});
