/* eslint-disable @typescript-eslint/no-explicit-any */
// noinspection TypeScriptValidateJSTypes

import React from 'react';
import moment from 'moment-timezone';
import { Controller } from 'react-hook-form';
import { HeaderButton, DualTitleHeaderComponent } from '@zawarski/palmetto-ui-components';
import useStyles from './PADetailsView.styles.ts';
import Box from '@mui/material/Box';
import { Grid, IconButton, InputAdornment, RadioGroup, useTheme } from '@mui/material';
import BackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { PageMaxLayout } from 'components/layout';
import {
  Button,
  DatePickerField,
  DecimalNumber,
  FormContent,
  FormGroup,
  Modal,
  RadioButton,
  SelectMenu,
  TextField,
  Typo,
} from 'components/primitive';
import {
  ActionButtons,
  Documents,
  geoCodeFromLatLonInput,
  SearchInputLocation,
} from 'components/fragment';
import { PADetailsProps } from './PADetails.props.ts';
import { handlePhoneChange, PhoneNumberFormat, DateTimeFormat, limitZipLength } from '@/utilities';
import { PAJPDAStatus } from '@/common/enum';
import { PACategoryValues } from '@/data';
import { useStyles as modalStyles } from 'components/fragment/ConfirmModal/ConfirmModal.style.ts';

const EditView: React.FC<PADetailsProps> = ({
  authToken,
  palmettoUser,
  title,
  form,
  inProcess,
  isDeleting,
  isStateUser,
  readOnly,
  goBack,
  countyOptions,
  allowAutoFill,
  handleEdit,
  onDelete,
  toggleAutoFill,
  onAddressSelect,
  setFilesAdded,
  setFilesDeleted,
  setLocalFilesDeleted,
  isJPDA_PA_Active,
  onHandleSubmit,
  filesAdded,
  isInfoModalOpen,
  onHandleConfirm,
  filesDeleted,
  incidentName,
  permissions,
  isOnline,
  hasOldData,
  files,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const modalClasses = modalStyles();
  const pvGlobalID = form.watch('pvGlobalID');
  const dataID = form.watch('id');
  const parcelID = form.watch('parcel_id');

  const disableCostEstimateTeam = isStateUser ? !isJPDA_PA_Active : true;
  const disableCostEstimateApplicant = isStateUser ? isJPDA_PA_Active : false;

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = parseFloat(position.coords.latitude.toFixed(8).toString());
          const lon = parseFloat(position.coords.longitude.toFixed(8).toString());
          await geoCodeFromLatLonInput(lat, lon, onAddressSelect);
        },
        (err) => console.log(err),
        { maximumAge: 60000, timeout: 10000, enableHighAccuracy: true }
      );
    }
  };

  const infoModal = () => {
    return (
      <Modal open={true} onClose={undefined}>
        <>
          <Box sx={{ margin: '24px' }}>
            <Box>
              <Typo sx={{ fontSize: '15px!important' }}>
                Save form without entering cost estimate?
              </Typo>
            </Box>
          </Box>
          <Box sx={modalClasses.footer}>
            <Box sx={modalClasses.footerWrapper}>
              <Button
                fullWidth
                neutral
                sx={{ color: theme.palette.text.secondary }}
                onClick={() => onHandleConfirm(false)}>
                CANCEL
              </Button>
              <Button fullWidth onClick={() => onHandleConfirm(true)}>
                CONFIRM
              </Button>
            </Box>
          </Box>
        </>
      </Modal>
    );
  };

  return (
    <Box className={'layout vertical full-height'} style={{ overflowY: 'auto' }}>
      <DualTitleHeaderComponent
        titleBig={title}
        titleSmall={incidentName}
        icon={
          <IconButton onClick={!inProcess ? () => goBack() : undefined}>
            {readOnly ? <BackIcon className='icon' /> : <CloseIcon className='icon' />}
          </IconButton>
        }
        suffix={
          <div className='form-margin-16'>
            {readOnly ? (
              permissions.UPDATE ? (
                <HeaderButton onClick={() => handleEdit()}>
                  <Typo fontWeight={600}>EDIT</Typo>
                </HeaderButton>
              ) : null
            ) : null}
          </div>
        }
      />
      <PageMaxLayout>
        <Box sx={classes.parcelContainer}>
          <Typo sx={classes.parcelContainer.text} style={{ textTransform: 'uppercase' }}>
            {parcelID ? 'PARCEL INFO' : 'ADD MARKER'}
          </Typo>
          <Typo sx={classes.parcelContainer.text}>{parcelID ? `Parcel ${parcelID}` : ''}</Typo>
        </Box>
        <FormContent title='Applicant Information' bgcolor={'#FFF'}>
          <Grid container spacing={2} sx={classes.gridContainer}>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='category'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <SelectMenu
                    withLabel
                    label='Category'
                    value={value}
                    onChange={onChange}
                    values={Object.values(PACategoryValues)}
                    disabled={readOnly}
                    error={Boolean(error)}
                    fieldError={error}
                    helperText={error ? error.message : ''}
                    placeholder={'Select category'}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='applicant_name'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Applicant name'
                    placeholder=''
                    disabled={true}
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: true,
                    }}
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='name'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Name'
                    disabled={true}
                    placeholder=''
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              {readOnly && (
                <Controller
                  control={form.control}
                  name='date'
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      errMsg={error}
                      label='Date'
                      placeholder='Enter date'
                      value={DateTimeFormat({ dte: value, format: 'MMMM DD, YYYY' })}
                      onChange={onChange}
                      InputProps={{
                        readOnly: true,
                      }}
                      helperTxt={error ? error.message : ''}
                    />
                  )}
                />
              )}
              {!readOnly && (
                <Controller
                  control={form.control}
                  name='date'
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <DatePickerField
                      label='Date'
                      format={'MMMM DD, YYYY'}
                      value={moment.utc(value)}
                      onChange={onChange}
                      errMsg={error}
                      helperTxt={error ? error.message : ''}
                      readOnly={readOnly}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              {readOnly && (
                <Controller
                  control={form.control}
                  name='county'
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      errMsg={error}
                      label='County'
                      placeholder='Enter county'
                      value={value}
                      onChange={onChange}
                      InputProps={{
                        readOnly: true,
                      }}
                      helperTxt={error ? error.message : ''}
                    />
                  )}
                />
              )}
              {!readOnly && (
                <Controller
                  control={form.control}
                  name='applicant_county'
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <SelectMenu
                      withLabel
                      label='County'
                      value={value}
                      onChange={onChange}
                      values={countyOptions}
                      disabled={readOnly}
                      error={Boolean(error)}
                      fieldError={error}
                      helperText={error ? error.message : ''}
                      placeholder={'Select county'}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='phone'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Phone'
                    placeholder='Enter phone number'
                    value={PhoneNumberFormat(value ?? '')}
                    onChange={handlePhoneChange(onChange)}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='email'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Email'
                    placeholder='Enter email'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormContent>
        <FormContent
          title='Site Location'
          bgcolor={'#FFF'}
          isAddress
          allowAutoFill={allowAutoFill}
          toggleAutoFill={toggleAutoFill}
          onClick={() => getCurrentLocation()}>
          <Grid container spacing={2} sx={classes.gridContainer}>
            <Grid item xs={12} md={6}>
              {!allowAutoFill && (
                <Controller
                  control={form.control}
                  name='address'
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      errMsg={error}
                      label='Address'
                      placeholder='Enter Address'
                      value={value}
                      onChange={onChange}
                      InputProps={{
                        readOnly: readOnly,
                      }}
                      helperTxt={error ? error.message : ''}
                    />
                  )}
                />
              )}
              {allowAutoFill && (
                <Controller
                  control={form.control}
                  name='address'
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <SearchInputLocation
                      id='address'
                      label='Address'
                      placeholder='Enter Address'
                      value={value}
                      onChange={onChange}
                      callbackAddressSelect={(value: any) => onAddressSelect(value)}
                      error={Boolean(error)}
                      errMsg={error}
                      InputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={form.control}
                name='address2'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Address 2'
                    placeholder='Enter Address 2'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                control={form.control}
                name='city'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='City'
                    placeholder='Enter City'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                control={form.control}
                name='county'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='County'
                    placeholder='Enter County'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: true,
                    }}
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                control={form.control}
                name='state'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='State'
                    placeholder='Enter State'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                control={form.control}
                name='zipCode'
                rules={{
                  validate: {
                    required: (value) => {
                      if (value && value.length !== 5) {
                        return 'Zip code must be exactly 5 characters';
                      } else return false;
                    },
                  },
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Zipcode'
                    placeholder='Enter Zipcode'
                    value={value}
                    onChange={(e) => onChange(limitZipLength(e.target.value, 5))}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={form.control}
                name='latitude'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Latitude'
                    placeholder='Enter Latitude'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={form.control}
                name='longitude'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Longitude'
                    placeholder='Enter Longitude'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormContent>
        <FormContent title='Site Details' bgcolor={'#FFF'}>
          <Grid container spacing={2} sx={classes.gridContainer}>
            {isStateUser && (
              <Grid item xs={12}>
                <Controller
                  control={form.control}
                  name='jpda_status'
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormGroup label='JPDA Status' error={error}>
                      <RadioGroup
                        aria-labelledby='controlled-radio-buttons-group'
                        name='controlled-radio-buttons-group'
                        value={value ? value.toLowerCase() : null}
                        onChange={!readOnly ? onChange : undefined}
                        sx={classes.groupRadioContainer}>
                        <RadioButton
                          isActive={
                            value
                              ? value.toLowerCase() === PAJPDAStatus.siteVisited.toLowerCase()
                              : false
                          }
                          value={PAJPDAStatus.siteVisited.toLowerCase()}
                          label={PAJPDAStatus.siteVisited}
                        />
                        <RadioButton
                          isActive={
                            value
                              ? value.toLowerCase() === PAJPDAStatus.siteNotVisited.toLowerCase()
                              : false
                          }
                          value={PAJPDAStatus.siteNotVisited.toLowerCase()}
                          label={PAJPDAStatus.siteNotVisited}
                        />
                      </RadioGroup>
                    </FormGroup>
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='damage_description'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Description of damage'
                    placeholder='Enter description'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='impact'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Impact to jurisdiction'
                    placeholder='Enter impact'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='impacted_department'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Impacted Department'
                    placeholder='Enter department'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='percent_complete'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='% Complete'
                    placeholder='Enter % complete'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='comments'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Team comments'
                    placeholder='Enter comments'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    helperTxt={error ? error.message : ''}
                    multiline
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormContent>
        <FormContent title='Estimates' bgcolor={'#FFF'}>
          <Grid container spacing={2} sx={classes.gridContainer}>
            <Grid item xs={12} md={6}>
              <Controller
                control={form.control}
                name='team_cost_estimate'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    disabled={disableCostEstimateTeam}
                    error={Boolean(error)}
                    errMsg={error}
                    label='Cost estimate - Team'
                    placeholder='Enter estimate'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      inputComponent: DecimalNumber as any,
                    }}
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={form.control}
                name='applicant_cost_estimate'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    disabled={disableCostEstimateApplicant}
                    error={Boolean(error)}
                    errMsg={error}
                    label='Cost estimate - Applicant'
                    placeholder='Enter estimate'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      inputComponent: DecimalNumber as any,
                    }}
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormContent>
        <FormContent title='File Attachments' bgcolor={'#FFF'}>
          {!isOnline && (
            <Box sx={{ padding: '0 16px' }}>
              <Typo fontWeight={600}>Viewing existing files is not available offline.</Typo>
            </Box>
          )}
          {!!authToken && palmettoUser && pvGlobalID && (
            <Box sx={{ padding: '16px' }}>
              <Documents
                readOnly={readOnly}
                authToken={authToken}
                uuid={pvGlobalID}
                user={palmettoUser}
                pvDataID={dataID ?? ''}
                setFilesAdded={setFilesAdded}
                filesDeletedCallback={setFilesDeleted}
                localFilesDeletedCallback={setLocalFilesDeleted}
                filesAdded={filesAdded}
                filesDeleted={filesDeleted}
                hasOldData={hasOldData}
                files={files}
              />
            </Box>
          )}
        </FormContent>
        <Box sx={{ display: 'flex', width: '100%', minHeight: '65px' }} />
      </PageMaxLayout>
      <ActionButtons
        withSave
        // withDelete={!isNew}
        withDelete={false}
        readonly={readOnly}
        isSaving={inProcess}
        isDeleting={isDeleting}
        delete={!isDeleting ? onDelete : undefined}
        save={!inProcess ? form.handleSubmit(onHandleSubmit) : undefined}
      />
      {isInfoModalOpen && infoModal()}
    </Box>
  );
};

export default EditView;
