import { DetailsViewProps, measurements } from './IADetails.props.ts';
import { Grid, IconButton, InputAdornment, RadioGroup, useTheme } from '@mui/material';
import useStyles from './IADetailsView.styles.ts';
import Box from '@mui/material/Box';
import { DualTitleHeaderComponent, HeaderButton } from '@zawarski/palmetto-ui-components';
import BackIcon from '@mui/icons-material/ArrowBack';
import {
  InsuranceTypes,
  JPDAAssessment,
  JPDAStatus,
  OccupancyStatus,
  OccupancyType,
  PostingOptions,
  PostingTheme,
  PostingThemeBG,
  StructureType,
  UtilitiesOut,
  YesNoSelect,
} from '@/common/enum';
import {
  CheckButton,
  FormContent,
  FormGroup,
  RadioButton,
  SelectMenu,
  TextField,
  Typo,
} from 'components/primitive';
import { PageMaxLayout } from 'components/layout';
import { Controller } from 'react-hook-form';
import {
  ActionButtons,
  Documents,
  geoCodeFromLatLonInput,
  SearchInputLocation,
} from 'components/fragment';
import { AddressSelectCBType } from '@/interfaces/SearchInputLocationTypes.ts';
import { FC } from 'react';
import { BuildingDamageTypes } from '@/data/LegendValues/BuildingDamage.ts';
import { limitZipLength } from '@/utilities';

const IADetailsView: FC<DetailsViewProps> = ({
  allowAutoFill,
  palmettoUser,
  readOnly,
  form,
  authToken,
  insuranceSelect,
  utilitiesSelect,
  inProcess,
  isStateUser,
  insuranceError,
  goBack,
  handleEdit,
  handleCheckUtilities,
  handleCheckInsurance,
  toggleAutoFill,
  setFilesAdded,
  setFilesDeleted,
  setLocalFilesDeleted,
  onHandleSubmit,
  filesAdded,
  filesDeleted,
  incidentName,
  permissions,
  isOnline,
  hasOldData,
  files,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const pvGlobalID = form.watch('pvGlobalID');
  const dataID = form.watch('id');
  const parcelID = form.watch('parcel_id');

  const onAddressSelect = (value: AddressSelectCBType) => {
    if (value) {
      form.setValue('address', value.address1);
      form.setValue('address2', value.address2);
      form.setValue('city', value.city);
      form.setValue('county', value.county);
      form.setValue('state', value.state);
      form.setValue('zipCode', value.zipCode);
      form.setValue('latitude', value.latitude);
      form.setValue('longitude', value.longitude);
    }
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = parseFloat(position.coords.latitude.toFixed(8).toString());
          const lon = parseFloat(position.coords.longitude.toFixed(8).toString());
          await geoCodeFromLatLonInput(lat, lon, onAddressSelect);
        },
        (err) => console.log(err),
        { maximumAge: 60000, timeout: 10000, enableHighAccuracy: true }
      );
    }
  };

  return (
    <Box className={'layout vertical full-height'} style={{ overflowY: 'auto' }}>
      <DualTitleHeaderComponent
        titleBig={<Typo style={{ fontSize: 16, fontWeight: 500 }}>Individual Assistance</Typo>}
        titleSmall={incidentName}
        icon={
          <IconButton onClick={() => goBack()}>
            <BackIcon className='icon' />
          </IconButton>
        }
        suffix={
          <div className='form-margin-16'>
            {readOnly ? (
              permissions.UPDATE ? (
                <HeaderButton onClick={() => handleEdit()}>
                  <Typo fontWeight={600}>EDIT</Typo>
                </HeaderButton>
              ) : null
            ) : null}
          </div>
        }
      />
      <PageMaxLayout>
        <Box sx={classes.parcelContainer}>
          <Typo sx={classes.parcelContainer.text} style={{ textTransform: 'uppercase' }}>
            {parcelID ? 'PARCEL INFO' : 'ADD MARKER'}
          </Typo>
          <Typo sx={classes.parcelContainer.text}>{parcelID ? `Parcel ${parcelID}` : ''}</Typo>
        </Box>
        <FormContent
          title='Address'
          bgcolor={'#FFF'}
          isAddress
          allowAutoFill={allowAutoFill}
          toggleAutoFill={toggleAutoFill}
          onClick={() => getCurrentLocation()}>
          <Grid container spacing={2} sx={classes.gridContainer}>
            <Grid item xs={12} md={6}>
              <Controller
                control={form.control}
                name='occupant_name'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Occupant name'
                    placeholder='Enter Occupant name'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {!allowAutoFill && !readOnly && (
                <Controller
                  control={form.control}
                  name='address'
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      errMsg={error}
                      label='Address'
                      placeholder='Enter Address'
                      value={value}
                      onChange={onChange}
                      InputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  )}
                />
              )}
              {allowAutoFill && !readOnly && (
                <Controller
                  control={form.control}
                  name='address'
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <SearchInputLocation
                      id='address'
                      label='Address'
                      placeholder='Enter Address'
                      value={value}
                      onChange={onChange}
                      callbackAddressSelect={(value: AddressSelectCBType) => onAddressSelect(value)}
                      error={Boolean(error)}
                      errMsg={error}
                      InputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  )}
                />
              )}
              {readOnly && (
                <Controller
                  control={form.control}
                  name='address'
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      errMsg={error}
                      label='Address'
                      placeholder='Enter Address'
                      value={value}
                      onChange={onChange}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  )}
                />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={classes.gridContainer}>
            <Grid item xs={12} md={6}>
              <Controller
                control={form.control}
                name='address2'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Address 2'
                    placeholder='Enter Address 2'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={form.control}
                name='city'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='City'
                    placeholder='Enter City'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='county'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='County'
                    placeholder='Enter County'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='state'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='State'
                    placeholder='Enter State'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='zipCode'
                rules={{
                  validate: {
                    required: (value) => {
                      if (value && value.length !== 5) {
                        return 'Zip code must be exactly 5 characters';
                      } else return false;
                    },
                  },
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Zipcode'
                    placeholder='Enter Zipcode'
                    value={value}
                    onChange={(e) => onChange(limitZipLength(e.target.value, 5))}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={classes.gridContainer}>
            <Grid item xs={12} md={6}>
              <Controller
                control={form.control}
                name='latitude'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Latitude'
                    placeholder='Enter Latitude'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={form.control}
                name='longitude'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Longitude'
                    placeholder='Enter Longitude'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormContent>
        <FormContent title='Details' bgcolor={'#FFF'}>
          <Grid container spacing={2} sx={classes.gridContainer}>
            {isStateUser && (
              <Grid item xs={12} md={6}>
                <Controller
                  control={form.control}
                  name='jpda_status'
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormGroup label='JPDA' error={error}>
                      <RadioGroup
                        aria-labelledby='controlled-radio-buttons-group'
                        name='controlled-radio-buttons-group'
                        value={value ? value.toLowerCase() : null}
                        onChange={!readOnly ? onChange : undefined}
                        sx={classes.groupRadioContainer}>
                        <RadioButton
                          isActive={
                            value
                              ? value.toLowerCase() === JPDAStatus.notVisited.toLowerCase()
                              : false
                          }
                          value={JPDAStatus.notVisited.toLowerCase()}
                          label={JPDAStatus.notVisited}
                        />
                        <RadioButton
                          isActive={
                            value
                              ? value.toLowerCase() === JPDAStatus.noChanges.toLowerCase()
                              : false
                          }
                          value={JPDAStatus.noChanges.toLowerCase()}
                          label={JPDAStatus.noChanges}
                        />
                        <RadioButton
                          isActive={
                            value
                              ? value.toLowerCase() === JPDAStatus.dataChanged.toLowerCase()
                              : false
                          }
                          value={JPDAStatus.dataChanged.toLowerCase()}
                          label={JPDAStatus.dataChanged}
                        />
                      </RadioGroup>
                    </FormGroup>
                  )}
                />
              </Grid>
            )}
            {isStateUser && (
              <Grid item xs={12} md={6}>
                <Controller
                  control={form.control}
                  name='jpda_assessment'
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormGroup label='JPDA Assessment type' error={error}>
                      <RadioGroup
                        aria-labelledby='controlled-radio-buttons-group'
                        name='controlled-radio-buttons-group'
                        value={value ? value.toLowerCase() : null}
                        onChange={!readOnly ? onChange : undefined}
                        sx={classes.groupRadioContainer}>
                        <RadioButton
                          isActive={
                            value ? value.toLowerCase() === JPDAAssessment.SBA.toLowerCase() : false
                          }
                          value={JPDAAssessment.SBA.toLowerCase()}
                          label={JPDAAssessment.SBA}
                        />
                        <RadioButton
                          isActive={
                            value ? value.toLowerCase() === JPDAAssessment.IA.toLowerCase() : false
                          }
                          value={JPDAAssessment.IA.toLowerCase()}
                          label={JPDAAssessment.IA}
                        />
                        <RadioButton
                          isActive={
                            value
                              ? value.toLowerCase() === JPDAAssessment.Both.toLowerCase()
                              : false
                          }
                          value={JPDAAssessment.Both.toLowerCase()}
                          label={JPDAAssessment.Both}
                        />
                      </RadioGroup>
                    </FormGroup>
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='structure_type'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <FormGroup label='Structure type' required error={error}>
                    <RadioGroup
                      aria-labelledby='controlled-radio-buttons-group'
                      name='controlled-radio-buttons-group'
                      value={value ? value.toLowerCase() : null}
                      onChange={!readOnly ? onChange : undefined}
                      sx={classes.groupRadioContainer}>
                      <RadioButton
                        isActive={
                          value
                            ? value.toLowerCase() === StructureType.business.toLowerCase()
                            : false
                        }
                        value={StructureType.business.toLowerCase()}
                        label={StructureType.business}
                      />
                      <RadioButton
                        isActive={
                          value
                            ? value.toLowerCase() === StructureType.singleFamily.toLowerCase()
                            : false
                        }
                        value={StructureType.singleFamily.toLowerCase()}
                        label={StructureType.singleFamily}
                      />
                      <RadioButton
                        isActive={
                          value
                            ? value.toLowerCase() === StructureType.manufacturedHome.toLowerCase()
                            : false
                        }
                        value={StructureType.manufacturedHome.toLowerCase()}
                        label={StructureType.manufacturedHome}
                      />
                      <RadioButton
                        isActive={
                          value
                            ? value.toLowerCase() === StructureType.multiFamily.toLowerCase()
                            : false
                        }
                        value={StructureType.multiFamily.toLowerCase()}
                        label={StructureType.multiFamily}
                      />
                    </RadioGroup>
                  </FormGroup>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={form.control}
                name='residence_status'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <FormGroup label='Occupancy type' required error={error}>
                    <RadioGroup
                      aria-labelledby='controlled-radio-buttons-group'
                      name='controlled-radio-buttons-group'
                      value={value ? value.toLowerCase() : null}
                      onChange={!readOnly ? onChange : undefined}
                      sx={classes.groupRadioContainer}>
                      <RadioButton
                        isActive={
                          value ? value.toLowerCase() === OccupancyType.Owned.toLowerCase() : false
                        }
                        value={OccupancyType.Owned.toLowerCase()}
                        label={OccupancyType.Owned}
                      />
                      <RadioButton
                        isActive={
                          value ? value.toLowerCase() === OccupancyType.Rented.toLowerCase() : false
                        }
                        value={OccupancyType.Rented.toLowerCase()}
                        label={OccupancyType.Rented}
                      />
                      <RadioButton
                        isActive={
                          value
                            ? value.toLowerCase() === OccupancyType.Unknown.toLowerCase()
                            : false
                        }
                        value={OccupancyType.Unknown.toLowerCase()}
                        label={OccupancyType.Unknown}
                      />
                    </RadioGroup>
                  </FormGroup>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={form.control}
                name='house_type'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <FormGroup label='Occupancy status' error={error}>
                    <RadioGroup
                      aria-labelledby='controlled-radio-buttons-group'
                      name='controlled-radio-buttons-group'
                      value={value ? value.toLowerCase() : null}
                      onChange={!readOnly ? onChange : undefined}
                      sx={classes.groupRadioContainer}>
                      <RadioButton
                        isActive={
                          value
                            ? value.toLowerCase() === OccupancyStatus.Primary.toLowerCase()
                            : false
                        }
                        value={OccupancyStatus.Primary.toLowerCase()}
                        label={OccupancyStatus.Primary}
                      />
                      <RadioButton
                        isActive={
                          value
                            ? value.toLowerCase() === OccupancyStatus.Secondary.toLowerCase()
                            : false
                        }
                        value={OccupancyStatus.Secondary.toLowerCase()}
                        label={OccupancyStatus.Secondary}
                      />
                      <RadioButton
                        isActive={
                          value
                            ? value.toLowerCase() === OccupancyStatus.Vacant.toLowerCase()
                            : false
                        }
                        value={OccupancyStatus.Vacant.toLowerCase()}
                        label={OccupancyStatus.Vacant}
                      />
                    </RadioGroup>
                  </FormGroup>
                )}
              />
            </Grid>
          </Grid>
        </FormContent>
        <FormContent title='Damage' bgcolor={'#FFF'}>
          <Grid container spacing={2} sx={classes.gridContainer}>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='building_damage'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <FormGroup label='Building damage' required error={error}>
                    <RadioGroup
                      aria-labelledby='controlled-radio-buttons-group'
                      name='controlled-radio-buttons-group'
                      value={value ? value : null}
                      onChange={!readOnly ? onChange : undefined}
                      sx={classes.groupRadioContainer}>
                      <RadioButton
                        isActive={value ? value === BuildingDamageTypes.NoDamage : false}
                        value={BuildingDamageTypes.NoDamage}
                        label={BuildingDamageTypes.NoDamage}
                      />
                      <RadioButton
                        isActive={value ? value === BuildingDamageTypes.Affected : false}
                        value={BuildingDamageTypes.Affected}
                        label={BuildingDamageTypes.Affected}
                      />
                      <RadioButton
                        isActive={value ? value === BuildingDamageTypes.MinorDamage : false}
                        value={BuildingDamageTypes.MinorDamage}
                        label={BuildingDamageTypes.MinorDamage}
                      />
                      <RadioButton
                        isActive={value ? value === BuildingDamageTypes.MajorDamage : false}
                        value={BuildingDamageTypes.MajorDamage}
                        label={BuildingDamageTypes.MajorDamage}
                      />
                      <RadioButton
                        isActive={value ? value === BuildingDamageTypes.Destroyed : false}
                        value={BuildingDamageTypes.Destroyed}
                        label={BuildingDamageTypes.Destroyed}
                      />
                      <RadioButton
                        isActive={value ? value === BuildingDamageTypes.Inaccessible : false}
                        value={BuildingDamageTypes.Inaccessible}
                        label={BuildingDamageTypes.Inaccessible}
                      />
                    </RadioGroup>
                  </FormGroup>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormGroup label='Utilities out' error={undefined}>
                <Box sx={classes.groupCheckContainer}>
                  <CheckButton
                    disabled={readOnly}
                    label={UtilitiesOut.noOutages}
                    value={utilitiesSelect.noOutages}
                    onChange={(e) => handleCheckUtilities(e, UtilitiesOut.noOutages)}
                  />
                  <CheckButton
                    disabled={readOnly}
                    label={UtilitiesOut.Gas}
                    value={utilitiesSelect.Gas}
                    onChange={(e) => handleCheckUtilities(e, UtilitiesOut.Gas)}
                  />
                  <CheckButton
                    disabled={readOnly}
                    label={UtilitiesOut.Electricity}
                    value={utilitiesSelect.Electricity}
                    onChange={(e) => handleCheckUtilities(e, UtilitiesOut.Electricity)}
                  />
                  <CheckButton
                    disabled={readOnly}
                    label={UtilitiesOut.Water}
                    value={utilitiesSelect.Water}
                    onChange={(e) => handleCheckUtilities(e, UtilitiesOut.Water)}
                  />
                </Box>
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup label='Insurance types' required error={insuranceError}>
                <Box sx={classes.groupCheckContainer}>
                  <CheckButton
                    disabled={readOnly}
                    label={InsuranceTypes.condominium}
                    value={insuranceSelect.condominium}
                    onChange={(e) => handleCheckInsurance(e, 'condominium')}
                  />
                  <CheckButton
                    disabled={readOnly}
                    label={InsuranceTypes.flood}
                    value={insuranceSelect.flood}
                    onChange={(e) => handleCheckInsurance(e, 'flood')}
                  />
                  <CheckButton
                    disabled={readOnly}
                    label={InsuranceTypes.hazardRider}
                    value={insuranceSelect.hazardRider}
                    onChange={(e) => handleCheckInsurance(e, 'hazardRider')}
                  />
                  <CheckButton
                    disabled={readOnly}
                    label={InsuranceTypes.homeOwners}
                    value={insuranceSelect.homeOwners}
                    onChange={(e) => handleCheckInsurance(e, 'homeOwners')}
                  />
                  <CheckButton
                    disabled={readOnly}
                    label={InsuranceTypes.manufacturedHome}
                    value={insuranceSelect.manufacturedHome}
                    onChange={(e) => handleCheckInsurance(e, 'manufacturedHome')}
                  />
                  <CheckButton
                    disabled={readOnly}
                    label={InsuranceTypes.unknown}
                    value={insuranceSelect.unknown}
                    onChange={(e) => handleCheckInsurance(e, 'unknown')}
                  />
                  <CheckButton
                    disabled={readOnly}
                    label={InsuranceTypes.noInsurance}
                    value={insuranceSelect.noInsurance}
                    onChange={(e) => handleCheckInsurance(e, 'noInsurance')}
                  />
                </Box>
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='water_level'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Water level in essential living area'
                    type={readOnly ? 'text' : 'number'}
                    placeholder='Enter Water Level'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Controller
                            control={form.control}
                            name='water_level_units'
                            render={({ field: { onChange, value } }) => (
                              <SelectMenu
                                value={value}
                                onChange={onChange}
                                values={measurements}
                                disabled={readOnly}
                              />
                            )}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='replacement_cost'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Replacement cost'
                    type={readOnly ? 'text' : 'number'}
                    placeholder='Enter Replacement Cost'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='estimated_loss'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Estimated loss'
                    type={readOnly ? 'text' : 'number'}
                    placeholder='Enter Estimated Lost'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={form.control}
                name='criteria_met'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <FormGroup label='Are there 40% or greater uninsured damages?' error={error}>
                    <RadioGroup
                      aria-labelledby='controlled-radio-buttons-group'
                      name='controlled-radio-buttons-group'
                      value={value ? value.toLowerCase() : null}
                      onChange={!readOnly ? onChange : undefined}
                      sx={classes.groupRadioContainer}>
                      <RadioButton
                        isActive={
                          value ? value.toLowerCase() === YesNoSelect.YES.toLowerCase() : false
                        }
                        value={YesNoSelect.YES.toLowerCase()}
                        label={YesNoSelect.YES}
                      />
                      <RadioButton
                        isActive={
                          value ? value.toLowerCase() === YesNoSelect.NO.toLowerCase() : false
                        }
                        value={YesNoSelect.NO.toLowerCase()}
                        label={YesNoSelect.NO}
                      />
                    </RadioGroup>
                  </FormGroup>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={form.control}
                name='posting'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <FormGroup label='Posting' error={error}>
                    <RadioGroup
                      aria-labelledby='controlled-radio-buttons-group'
                      name='controlled-radio-buttons-group'
                      value={value ? value.toLowerCase() : null}
                      onChange={!readOnly ? onChange : undefined}
                      sx={classes.groupRadioContainer}>
                      <RadioButton
                        customActiveBorder={PostingTheme.Unsafe}
                        customActiveBg={PostingThemeBG.Unsafe}
                        isActive={
                          value
                            ? value.toLowerCase() === PostingOptions.Unsafe.toLowerCase()
                            : false
                        }
                        value={PostingOptions.Unsafe.toLowerCase()}
                        label={PostingOptions.Unsafe}
                      />
                      <RadioButton
                        customActiveBorder={PostingTheme.Inspected}
                        customActiveBg={PostingThemeBG.Inspected}
                        isActive={
                          value
                            ? value.toLowerCase() === PostingOptions.Inspected.toLowerCase()
                            : false
                        }
                        value={PostingOptions.Inspected.toLowerCase()}
                        label={PostingOptions.Inspected}
                      />
                      <RadioButton
                        customActiveBorder={PostingTheme.RestrictedUse}
                        customActiveBg={PostingThemeBG.RestrictedUse}
                        isActive={
                          value
                            ? value.toLowerCase() === PostingOptions.RestrictedUse.toLowerCase()
                            : false
                        }
                        value={PostingOptions.RestrictedUse.toLowerCase()}
                        label={PostingOptions.RestrictedUse}
                      />
                    </RadioGroup>
                  </FormGroup>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='comments'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Comments'
                    placeholder='Enter Comments'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    multiline
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='assessment_team_member'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Assessment team member'
                    placeholder='Enter assessment team member'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    required
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormContent>
        <FormContent title='File Upload' bgcolor={'#FFF'}>
          {!isOnline && (
            <Box sx={{ padding: '0 16px' }}>
              <Typo fontWeight={600}>Viewing existing files is not available offline.</Typo>
            </Box>
          )}
          {!!authToken && palmettoUser && pvGlobalID && (
            <Box sx={{ padding: '16px' }}>
              <Documents
                readOnly={readOnly}
                authToken={authToken}
                uuid={pvGlobalID}
                user={palmettoUser}
                pvDataID={dataID ?? ''}
                setFilesAdded={setFilesAdded}
                filesDeletedCallback={setFilesDeleted}
                localFilesDeletedCallback={setLocalFilesDeleted}
                filesAdded={filesAdded}
                filesDeleted={filesDeleted}
                hasOldData={hasOldData}
                files={files}
              />
            </Box>
          )}
        </FormContent>
      </PageMaxLayout>
      {!readOnly && <Box sx={{ display: 'flex', width: '100%', minHeight: '65px' }} />}
      <ActionButtons
        readonly={readOnly}
        isSaving={inProcess}
        withSave
        save={!inProcess ? form.handleSubmit(onHandleSubmit) : undefined}
      />
    </Box>
  );
};

export default IADetailsView;
