/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useState } from 'react';
import apiClient, { AxiosRequestConfig } from 'axios';
import localforage from 'localforage';
import { CountiesLatLng } from '@/common/static';

const palmettoBaseURL = import.meta.env.VITE_PALMETTO_ENDPOINT;
const useParcelData = (groupName: string) => {
  const [parcelData, setParcelData] = useState<any>(null);
  const [roadData, setRoadData] = useState<any>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const centerLatLon: [number, number] = useMemo(() => {
    if (groupName) {
      return CountiesLatLng[groupName.toLowerCase().trim()];
    }
    return [34.222558, -82.45869028];
  }, [groupName]);

  function isGroupExist(groupName: string) {
    if (groupName) {
      const countyExist = CountiesLatLng[groupName.toLowerCase().trim()];
      return !!(countyExist && countyExist.length);
    }
    return false;
  }

  const getParcelData = async (nonParcelMode: boolean) => {
    if (!isGroupExist(groupName)) return setIsFetching(false);
    setIsFetching(true);
    const parcelData = await localforage.getItem(
      `${groupName.toLowerCase().trim()}parcels.mbtiles`
    );
    const roads = await localforage.getItem(`${groupName.toLowerCase().trim()}tigerroads.geojson`);
    if ((!nonParcelMode && !parcelData) || !roads) {
      setParcelData(null);
      setRoadData(null);
      return setIsFetching(false);
    }
    setParcelData(parcelData);
    setRoadData(roads);
    setIsFetching(false);
    return {
      parcel: parcelData,
      roads: roads,
    };
  };

  // const downloadParcel = async (config: AxiosRequestConfig, nonParcelMode: boolean) => {
  //   setIsFetching(true);
  //   const tileName = `${groupName.toLowerCase().trim()}parcels.mbtiles`;
  //   const roadName = `${groupName.toLowerCase().trim()}tigerroads.geojson`;
  //   try {
  //     const baseUrl = `${palmettoBaseURL}/api/damageassessment_pwa`;
  //     //if is non parcel mode there is no parcel data to download
  //     const responseTiles = nonParcelMode
  //       ? { data: null }
  //       : await apiClient.get(`${baseUrl}/getTiles?group=${groupName.toLowerCase().trim()}`, {
  //           ...config,
  //           onDownloadProgress: (progressEvent) => {
  //             const progress = Math.round(
  //               (100 * progressEvent.loaded) / (progressEvent?.total ?? 0)
  //             );
  //             setProgress(progress);
  //           },
  //         });
  //
  //     const responseRoad = await apiClient.get(
  //       `${baseUrl}/getRoads?group=${groupName.toLowerCase().trim()}`,
  //       {
  //         ...config,
  //         onDownloadProgress: (progressEvent) => {
  //           const progress = Math.round((100 * progressEvent.loaded) / (progressEvent?.total ?? 0));
  //           setProgress(progress);
  //         },
  //       }
  //     );
  //     //  *: Set the downloaded data to localforage
  //     const tileName = `${groupName.toLowerCase().trim()}parcels.mbtiles`;
  //     const roadName = `${groupName.toLowerCase().trim()}tigerroads.geojson`;
  //     await Promise.all([
  //       localforage.setItem(tileName, responseTiles.data),
  //       localforage.setItem(roadName, responseRoad.data),
  //     ]);
  //     setParcelData(responseTiles.data);
  //     setRoadData(responseRoad.data);
  //     setIsFetching(false);
  //   } catch (error) {
  //     if (isGroupExist(groupName)) {
  //       await Promise.all([
  //         localforage.setItem(tileName, JSON.stringify({})),
  //         localforage.setItem(roadName, JSON.stringify({})),
  //       ]);
  //       setParcelData(JSON.stringify({}));
  //       setRoadData(JSON.stringify({}));
  //       setIsFetching(false);
  //       return;
  //     }
  //     setIsFetching(false);
  //     setParcelData(null);
  //     setRoadData(null);
  //     return Promise.reject(error);
  //   }
  // };

  const downloadParcel = async (config: AxiosRequestConfig, nonParcelMode: boolean) => {
    setIsFetching(true);
    const baseUrl = `${palmettoBaseURL}/api/damageassessment_pwa`;
    const tileName = `${groupName.toLowerCase().trim()}parcels.mbtiles`;
    const roadName = `${groupName.toLowerCase().trim()}tigerroads.geojson`;

    try {
      // Download parcel tiles
      const responseTiles = nonParcelMode
        ? { data: null }
        : await apiClient.get(`${baseUrl}/getTiles?group=${groupName.toLowerCase().trim()}`, {
            ...config,
            onDownloadProgress: (progressEvent) => {
              const progress = Math.round(
                (100 * progressEvent.loaded) / (progressEvent?.total ?? 0)
              );
              setProgress(progress);
            },
          });

      // Save the tile data to localforage
      await localforage.setItem(tileName, responseTiles.data);
      setParcelData(responseTiles.data);
    } catch (error) {
      // Handle errors in downloading or saving parcel tiles
      if (isGroupExist(groupName)) {
        await localforage.setItem(tileName, JSON.stringify({}));
        setParcelData(JSON.stringify({}));
      } else {
        setParcelData(null);
      }
    }

    try {
      // Download road data
      const responseRoad = await apiClient.get(
        `${baseUrl}/getRoads?group=${groupName.toLowerCase().trim()}`,
        {
          ...config,
          onDownloadProgress: (progressEvent) => {
            const progress = Math.round((100 * progressEvent.loaded) / (progressEvent?.total ?? 0));
            setProgress(progress);
          },
        }
      );

      // Save the road data to localforage
      await localforage.setItem(roadName, responseRoad.data);
      setRoadData(responseRoad.data);
    } catch (error) {
      // Handle errors in downloading or saving road data

      await localforage.setItem(roadName, JSON.stringify({}));
      setRoadData(JSON.stringify({}));
    } finally {
      setIsFetching(false);
    }
  };

  return {
    getParcelData,
    downloadParcel,
    parcelData,
    roadData,
    isFetching,
    progress,
    centerLatLon,
  };
};

export default useParcelData;
