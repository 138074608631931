import { Button, Modal, Typo } from 'components/primitive';
import { HeaderComponent } from '@zawarski/palmetto-ui-components';
import { CircularProgress, IconButton, Paper, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

import { ConfirmModalProps } from 'components/fragment/ConfirmModal/ConfirmModal.props.tsx';
import { useStyles } from 'components/fragment/ConfirmModal/ConfirmModal.style.ts';

export function ConfirmModal({
  onConfirm,
  onClose,
  title,
  message,
  allowProceed,
  loading,
  children,
}: ConfirmModalProps) {
  const theme = useTheme();
  const classes = useStyles();
  function ProgressIcon() {
    return <CircularProgress color='inherit' size={22} sx={{ mr: 0.5 }} />;
  }
  return (
    <Modal open={true} onClose={undefined}>
      <>
        <HeaderComponent
          title={title}
          icon={
            <IconButton
              onClick={() => {
                onClose(null);
              }}>
              <CloseIcon className='icon' />
            </IconButton>
          }
        />
        {message && (
          <Box sx={classes.content}>
            <Typo variant='body1'>{message}</Typo>
          </Box>
        )}
        {children && (
          <>
            <Paper sx={{ ...classes.content, display: 'block', boxShadow: 'none' }} elevation={1}>
              {children}
            </Paper>
          </>
        )}

        <Box sx={classes.footer}>
          <Box sx={classes.footerWrapper}>
            <Button
              fullWidth
              neutral
              sx={{ color: theme.palette.text.secondary }}
              onClick={() => {
                onClose(null);
              }}>
              Close
            </Button>
            {allowProceed && (
              <Button fullWidth onClick={onConfirm} endIcon={loading ? <ProgressIcon /> : null}>
                Confirm
              </Button>
            )}
          </Box>
        </Box>
      </>
    </Modal>
  );
}

export default ConfirmModal;
