import { createSlice } from '@reduxjs/toolkit';

import { fetchAllTeams } from './teams.thunk.ts';
import { TeamsInitialState, TeamsTypes } from 'store/teams/teams.state.ts';
import { GetTeamsInterface } from 'interfaces/team.interface.ts';

const initialState: TeamsTypes = TeamsInitialState;

export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllTeams.fulfilled, (state, action) => {
        const apiResponse = action.payload as GetTeamsInterface;

        state.entities = apiResponse.dataset;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchAllTeams.rejected, (state, action) => {
        state.error = action.error.message || 'Something went wrong!';
        state.loading = false;
      })
      .addCase(fetchAllTeams.pending, (state) => {
        state.loading = true;
      });
  },
});

export default teamsSlice.reducer;
