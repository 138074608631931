import type IEndPoint from 'interfaces/EndPointTypes.ts';
import { AuthProtect, BaseURLSelect } from '@/common/enum';

export const getMapLayerSettings = (params: {
  pvGroupID: number;
  pvSettingType: string;
}): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: '/api/settings',
  params,
  defaultBaseURL: BaseURLSelect.DMA,
});
