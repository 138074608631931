import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import merge from 'deepmerge';
import apiConfig from './apiConfig';
import { AuthProtect, BaseURLSelect } from '@/common/enum';
import type EndPointTypes from 'interfaces/EndPointTypes.ts';
import { logout } from '@/utilities/logout.ts';
import { store } from '@/store';

export const genericErrorMessage =
  'We just experienced a technical issue, please wait a few seconds and try again.';

const palmettoBaseURL = import.meta.env.VITE_PALMETTO_ENDPOINT;
const dmaBaseURL = import.meta.env.VITE_DAMAGEASSESSMENT_API_ENDPOINT;

async function apiFetch<T>(
  api: EndPointTypes,
  controller?: AbortController
): Promise<{ status: number; data: T }> {
  const params: any = api.params || {}; // eslint-disable-line
  const { headers } = apiConfig;
  const baseURL = api.defaultBaseURL === BaseURLSelect.DMA ? dmaBaseURL : palmettoBaseURL;
  if (api.authorization === AuthProtect.REQUIRED) {
    const token = store.getState().authToken.token;
    if (token) {
      params.access_token = token;
    }
  }
  const apiConfigMerge: AxiosRequestConfig = merge.all([
    apiConfig,
    api,
    {
      baseURL,
      headers,
      params,
    },
  ]);
  const axiosCreate = axios.create(apiConfigMerge);
  axiosCreate.interceptors.request.use(
    async function (config) {
      if (controller) {
        config.signal = controller.signal;
      }
      if (api.authorization === AuthProtect.REQUIRED) {
        const token = store.getState().authToken.token;
        if (token) {
          config.headers.Authorization = token;
        }
      }
      return config;
    },
    function (error) {
      console.log('🚀apiFetch function ERROR: ', error);
      throw Error(error);
    }
  );
  return axiosCreate(api).then(
    (res: AxiosResponse<T>) => {
      return { status: res.status, data: res.data };
    },
    (error) => {
      if (error.response) {
        if (error.response.status === 401) {
          logout();
        }
        throw Error(JSON.stringify(error.response.data));
      } else {
        console.log('🚀apiFetch ERROR: ', error.message);
        throw Error(genericErrorMessage);
      }
    }
  );
}

export default apiFetch;
