import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import { RootState } from '@/store';
import { getMapLayerSettings } from '@/services/map.endpoint.ts';
import { MapLayerSettings } from 'interfaces/map.interface.ts';

export const fetchMapLayerID = createAsyncThunk('FETCH_MAP_LAYER_ID', async (_, thunkAPI) => {
  try {
    const store = thunkAPI.getState() as RootState;
    const { selectedGroup, selectedGroupID } = store.accountsInfo;
    const activeGroup = selectedGroup ? selectedGroup : selectedGroupID;
    if (!activeGroup) return Promise.reject('No active group');

    const endpoint = getMapLayerSettings({
      pvGroupID: Number(activeGroup),
      pvSettingType: 'mapLayerIdSetting',
    });

    return apiFetch<MapLayerSettings[]>(endpoint).then((res) => res.data ?? null);
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});
