import { logout as performLogout } from '@/utilities/logout.ts';

const useLogout = () => {
  const logout = () => {
    performLogout();
  };

  return logout;
};

export default useLogout;
