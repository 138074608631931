import { useEffect } from 'react';
import { useAppSelector } from 'store/hooks.ts';
import { selectIsInitApiLoading } from 'store/connection/connection.selector.ts';

const useScript = (url: string) => {
  const isApiLoading = useAppSelector(selectIsInitApiLoading);
  useEffect(() => {
    if (isApiLoading) return;
    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {};
    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export default useScript;
