import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    flexDirection: 'column',
    backgroundColor: theme.palette.grey[50],
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  content: {
    display: 'flex',
    maxWidth: '1440px',
    width: '100%',
    height: 'auto',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '16px',
    py: '24px',
    [theme.breakpoints.down('xl')]: {
      padding: '24px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
    },
  },
});

export default useStyles;
