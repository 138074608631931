import './ReloadPrompt.css';

import { useRegisterSW } from 'virtual:pwa-register/react';
import { Button, Typo } from 'components/primitive';
import { Box, useTheme } from '@mui/material';
import useReloadPromptView from 'components/fragment/ReloadPromt/ReloadPrompt.style.ts';

function ReloadPrompt() {
  // replaced dynamically
  const buildDate = '__DATE__';
  // replaced dyanmicaly
  const reloadSW = '__RELOAD_SW__';

  const theme = useTheme();
  const classes = useReloadPromptView();

  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      console.log(`Service Worker at: ${swUrl}`);
      // @ts-expect-error just ignore
      if (reloadSW === 'true') {
        r &&
          setInterval(() => {
            console.log('Checking for sw update');
            r.update();
          }, 20000 /* 20s for testing purposes */);
      } else {
        // eslint-disable-next-line prefer-template
        console.log('SW Registered: ' + r);
      }
    },
    onRegisterError(error) {
      console.log('SW registration error', error);
    },
  });

  const close = () => {
    setOfflineReady(false);
    setNeedRefresh(false);
  };

  return (
    <div className='ReloadPrompt-container'>
      {(offlineReady || needRefresh) && (
        <div className='ReloadPrompt-toast'>
          <Box sx={classes.content}>
            <div className='ReloadPrompt-message'>
              {offlineReady ? (
                <Typo sx={classes.title}>App ready to work offline</Typo>
              ) : (
                <Typo sx={classes.title}>
                  New content available, click on reload button to update.
                </Typo>
              )}
            </div>
          </Box>
          <Box sx={classes.footer}>
            <Box sx={classes.footerWrapper}>
              {needRefresh && (
                <Button
                  fullWidth
                  onClick={() => {
                    updateServiceWorker(true);
                  }}>
                  Reload
                </Button>
              )}
              <Button
                fullWidth
                neutral
                sx={{ color: theme.palette.text.secondary }}
                onClick={() => {
                  close();
                }}>
                Close
              </Button>
            </Box>
          </Box>
        </div>
      )}
      <div className='ReloadPrompt-date'>{buildDate}</div>
    </div>
  );
}

export default ReloadPrompt;
