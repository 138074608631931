import { createAsyncThunk } from '@reduxjs/toolkit';
import { genericErrorMessage } from '@/services/apiFetch.ts';
import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';
import { FileType } from 'interfaces/ia.interface.ts';

const OFFLINE_FILE_DB_NAME = 'offline_files_v2';
PouchDB.plugin(PouchDBFind);
const dbInstance = new PouchDB(OFFLINE_FILE_DB_NAME);
declare global {
  interface Window {
    __PALMETTO_POUCH_DB_FILE_INSTANCE__: any;
  }
}
window.__PALMETTO_POUCH_DB_FILE_INSTANCE__ = dbInstance;

export const saveOfflineFile = createAsyncThunk(
  'saveOfflineFile',
  async (payload: {
    key: string;
    file: FileType;
    pvGlobalGroupID: string;
    name: string;
    size: number;
  }) => {
    try {
      if (!dbInstance) {
        throw Error('Offline file DB not initialized');
      }
      const fileDoc = {
        _id: payload.key,
        type: 'file',
        file: payload.file,
        pvGlobalGroupID: payload.pvGlobalGroupID,
        name: payload.name,
        size: payload.size,
        isUnsaved: true,
      };
      await dbInstance.put(fileDoc);
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);

export function updateOfflineFile(doc: any) {
  return new Promise((resolve, reject) => {
    let result = null;
    if (doc._id) {
      result = dbInstance.put(doc);
    } else {
      result = dbInstance.post(doc);
    }
    result
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export async function findFileByQuery(query = { selector: {} }) {
  return new Promise((resolve, reject) => {
    dbInstance
      .find(query)
      .then((result: any) => {
        resolve(result.docs || []);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function deleteOfflineFile(doc: any) {
  return new Promise((resolve, reject) => {
    dbInstance
      .remove(doc)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

/************DANGER ZONE ******************/

export const destroyOfflineFileDB = createAsyncThunk('DESTROY_OFFLINE_FILE_DB', async () => {
  try {
    if (window.__PALMETTO_POUCH_DB_FILE_INSTANCE__) {
      await window.__PALMETTO_POUCH_DB_FILE_INSTANCE__.destroy();
      window.location.reload();
    } else {
      throw new Error('Database instance not found');
    }
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else if (typeof error === 'object' && error !== null && 'message' in error) {
      message = String(error.message);
    }
    console.error('Error destroying database:', message);
    throw Error(message);
  }
});
