import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    bgcolor: theme.palette.secondary.main,
    gap: '4px',
    alignItems: 'flex-start',
    alignContent: 'space-between',
    borderRadius: '6px',
    border: '1px solid #E0E0E0',
    padding: '8px',
    position: 'relative',
  },
  title: {
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
    fontWeight: 700,
    fontSize: 12,
    marginBottom: '8px',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    flexWrap: 'wrap',
  },
  empty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    opacity: 0.35,
    padding: '24px',
  },
  emptyText: {
    color: theme.palette.grey[400],
    fontSize: 12,
    fontWeight: 500,
  },
});

export default useStyles;
