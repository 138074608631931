import { RootState } from '@/store';

export const selectAccount = (state: RootState) => state.accountsInfo.account;
export const selectAccountState = (state: RootState) => state.accountsInfo.queryState;
export const selectAppLoading = (state: RootState) => state.accountsInfo.appLoading;
export const accountGroupIDSelector = (state: RootState) => state.accountsInfo.selectedGroupID;
export const selectedGroupNameSelector = (state: RootState) => state.accountsInfo.selectedGroupName;
export const selectedGroupSelector = (state: RootState) => state.accountsInfo.selectedGroup;
export const selectedGroupNameForParcels = (state: RootState) =>
  state.accountsInfo.selectedGroupNameForParcels;
export const allGroupsSelector = (state: RootState) => state.accountsInfo.allGroups;
export const allSubGroupsSelector = (state: RootState) => state.accountsInfo.allSubGroups;
export const userTypeSelector = (state: RootState) => state.accountsInfo.userType;
export const selectNonParcelMode = (state: RootState) => state.accountsInfo.nonParcelMode;
export const hasFetchedAllGroupsSelector = (state: RootState) =>
  state.accountsInfo.hasFetchedAllGroups;
