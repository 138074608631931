import { Box, IconButton, Paper, Container, LinearProgress, CircularProgress } from '@mui/material';
import { DualTitleHeaderComponent, HeaderComponent } from '@zawarski/palmetto-ui-components';
import BackIcon from '@mui/icons-material/ArrowBack';
import { Button, Modal, Typo } from 'components/primitive';
import { PageMaxLayout } from 'components/layout';
import { QueueProps } from './QueueProps.ts';
import { PACategoryValues } from '@/data';
import { StructureType } from '@/common/enum';

import { FC } from 'react';

const QueueView: FC<QueueProps> = ({
  goBack,
  items,
  categorySelected,
  handleSyncData,
  isSaving,
  unsavedItems,
  savedItems,
  unsavedFilesForItem,
  savedFilesForItem,
}) => {
  const calculateProgressPercentage = (saved: number, unsaved: number): number => {
    return (saved / unsaved) * 100;
  };
  const getLabelByValue = (properties: any, targetValue: string): string | undefined => {
    for (const key in properties) {
      if (properties[key].value === targetValue) {
        return properties[key].label;
      }
    }
    return undefined;
  };
  function findEnumKeyByValue(obj: any, value: string): string | undefined {
    for (const key in obj) {
      if (obj[key as keyof typeof obj].toLowerCase() === value) {
        return obj[key as keyof typeof obj];
      }
    }
    return undefined;
  }

  function ProgressIcon() {
    return <CircularProgress color='inherit' size={22} sx={{ mr: 0.5 }} />;
  }

  return (
    <Box className={'layout vertical full-height'} style={{ overflowY: 'auto' }}>
      <DualTitleHeaderComponent
        titleBig={<Typo style={{ fontSize: 16, fontWeight: 700 }}>Assessment Queue</Typo>}
        titleSmall={categorySelected === 'ia' ? 'Individual Assistance' : 'Public Assistance'}
        icon={
          <IconButton onClick={() => goBack()}>
            <BackIcon className='icon' />
          </IconButton>
        }
      />
      <PageMaxLayout>
        <Box sx={{ width: '100%' }}>
          {items.length ? (
            items.map((item, index) => (
              <Paper sx={{ width: '100%', padding: '16px', marginBottom: '16px' }} key={index}>
                {categorySelected === 'pa' ? (
                  <>
                    <Typo style={{ fontSize: 16, fontWeight: 700 }}>
                      {getLabelByValue(PACategoryValues, item.category)}
                    </Typo>
                    <Typo style={{ fontSize: 14, fontWeight: 500 }}>{item.name}</Typo>
                  </>
                ) : (
                  <>
                    <Typo style={{ fontSize: 16, fontWeight: 700 }}>{item.city}</Typo>
                    <Typo style={{ fontSize: 14, fontWeight: 500 }}>{item.address}</Typo>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Typo style={{ fontSize: 14, fontWeight: 500 }}>
                        {findEnumKeyByValue(StructureType, item.structure_type)}
                      </Typo>
                      <span style={{ margin: '0 4px', fontSize: '1.5rem', lineHeight: '1' }}>
                        &middot;
                      </span>
                      <Typo style={{ fontSize: 14, fontWeight: 500 }}>{item.building_damage}</Typo>
                    </Box>
                  </>
                )}
              </Paper>
            ))
          ) : (
            <Typo style={{ fontSize: 16, fontWeight: 700 }}>No items</Typo>
          )}
        </Box>
      </PageMaxLayout>
      <Container
        sx={{
          paddingLeft: '0!important',
          paddingRight: '0!important',
          boxShadow: '0 -5px 10px rgba(0, 0, 0, 0.1)',
          width: '100%',
          maxWidth: '100%!important',
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: '16px',
          }}>
          <Button className={'blueButton'} type={'submit'} onClick={handleSyncData}>
            {'Sync Data'}
          </Button>
        </Box>
      </Container>
      {isSaving && (
        <Modal open={true}>
          <>
            <HeaderComponent
              title={'Sync in progress...'}
              suffix={<ProgressIcon />}
              style={{
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
              }}
            />
            <Box sx={{ width: '100%', padding: '16px' }}>
              <Box sx={{ width: '100%', display: 'flex', marginBottom: '24px' }}>
                <Typo
                  color='textSecondary'
                  sx={{ fontSize: '16px', fontWeight: 700, marginRight: '12px' }}>
                  {savedItems}/{unsavedItems} items
                </Typo>
                {unsavedFilesForItem > 0 && (
                  <Typo color='textSecondary' sx={{ fontSize: '16px', fontWeight: 500 }}>
                    {savedFilesForItem}/{unsavedFilesForItem} files
                  </Typo>
                )}
              </Box>
              <Box sx={{ width: '100%' }}>
                <LinearProgress
                  variant='determinate'
                  value={calculateProgressPercentage(savedFilesForItem, unsavedFilesForItem)}
                />
              </Box>
            </Box>
          </>
        </Modal>
      )}
    </Box>
  );
};

export default QueueView;
