import React from 'react';
import {
  AffectedIcon,
  CatAIcon,
  CatBIcon,
  CatCIcon,
  CatDIcon,
  CatEIcon,
  CatFIcon,
  CatGIcon,
  DestroyedIcon,
  MajorDamageIcon,
  MinorDamageIcon,
  InaccessibleIcon,
  NoDamageIcon,
} from '@/assets/icons';

export interface markerType {
  id: string;
  title: string;
  subTitle: string;
  icon: React.ReactElement;
  checked?: boolean;
}

export const LegendTypes: markerType[] = [
  {
    id: 'catA',
    title: 'Debris Removal',
    subTitle: 'Category A',
    icon: <CatAIcon />,
    checked: false,
  },
  {
    id: 'catB',
    title: 'Emergency Protective Measures',
    subTitle: 'Category B',
    icon: <CatBIcon />,
    checked: false,
  },
  {
    id: 'catC',
    title: 'Roads and Bridges',
    subTitle: 'Category C',
    icon: <CatCIcon />,
    checked: false,
  },
  {
    id: 'catD',
    title: 'Water Control Facilities',
    subTitle: 'Category D',
    icon: <CatDIcon />,
    checked: false,
  },
  {
    id: 'catE',
    title: 'Buildings and Equipment',
    subTitle: 'Category E',
    icon: <CatEIcon />,
    checked: false,
  },
  {
    id: 'catF',
    title: 'Utilities',
    subTitle: 'Category F',
    icon: <CatFIcon />,
    checked: false,
  },
  {
    id: 'catG',
    title: 'Parks, Recreation, and Other',
    subTitle: 'Category G',
    icon: <CatGIcon />,
    checked: false,
  },
];

export const LegendStatus: markerType[] = [
  {
    id: 'noDamage',
    title: 'No Damage',
    subTitle: '',
    icon: <NoDamageIcon />,
    checked: false,
  },
  {
    id: 'affected',
    title: 'Affected',
    subTitle: '',
    icon: <AffectedIcon />,
    checked: false,
  },
  {
    id: 'minorDamage',
    title: 'Minor Damage',
    subTitle: '',
    icon: <MinorDamageIcon />,
    checked: false,
  },
  {
    id: 'majorDamage',
    title: 'Major Damage',
    subTitle: '',
    icon: <MajorDamageIcon />,
    checked: false,
  },
  {
    id: 'destroyed',
    title: 'Destroyed',
    subTitle: '',
    icon: <DestroyedIcon />,
    checked: false,
  },
  {
    id: 'inaccessible',
    title: 'Inaccessible',
    subTitle: '',
    icon: <InaccessibleIcon />,
    checked: false,
  },
];
