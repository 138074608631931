import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  drawerContainer: {
    top: '70px',
    right: '20px',
    height: '70%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    minWidth: '350px',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    bgcolor: theme.palette.primary.dark,
    gap: '12px',
    padding: '12px',
  },
  legendContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    gap: '8px',
    padding: '12px',
  },
  title: {
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.grey[400],
  },
  layerContainer: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',
  },
  layerInfo: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 0 0',
      color: theme.palette.secondary.contrastText,
    },
    title: {
      fontSize: '16px',
      fontWeight: 600,
    },
    subTitle: {
      fontSize: '12px',
      fontWeight: 500,
      opacity: 0.5,
    },
  },
});

export default useStyles;
