import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks.ts';
import { selectAuthTokenVerification } from 'store/authToken/authToken.selector.ts';
import {
  setupAuthorizationDatabaseAndSaveToken,
  verifyToken,
} from 'store/authToken/authToken.thunk.ts';
import { Loading } from 'components/primitive';
import { useEffect } from 'react';
import { GetSitePermission } from '@/utilities';
import SitePermissionTypes from '@/common/enum/SitePermissionTypes.ts';
import { UserAuthCreds } from 'interfaces/UserAccountInfo.interface.ts';
import { setAuthCreds } from 'store/authToken/authToken.action.ts';
import { setAccount, setLoadingState } from 'store/accountsInfo/accountsInfo.actions.ts';
import { selectAppLoading } from 'store/accountsInfo/accountsInfo.selector.ts';
import useStartHealthChecker from '@/utilities/startHealthChecker.ts';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';

const AuthPage = () => {
  const [searchParams] = useSearchParams();
  const [idTokenProcessed, setIdTokenProcessed] = useState(false);
  const idToken = searchParams.get('id_token');

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAppLoading = useAppSelector(selectAppLoading);
  const authTokenVerification = useAppSelector(selectAuthTokenVerification);

  useStartHealthChecker();
  useEffect(() => {
    if (idToken?.length) {
      dispatch(verifyToken(idToken)).then(() => {
        setIdTokenProcessed(true);
      });
    } else {
      setIdTokenProcessed(true);
    }
  }, [idToken]);

  useEffect(() => {
    if (!idTokenProcessed) return;
    try {
      if (
        authTokenVerification &&
        authTokenVerification.attributes &&
        authTokenVerification.accessToken
      ) {
        let accessType;
        const groupId = authTokenVerification.pvActiveGroupID;
        const sitePermission = GetSitePermission('DMAM', groupId, authTokenVerification.attributes);
        const permissions = sitePermission.sitePermissions;

        switch (permissions && permissions[0]) {
          case 'A':
            accessType = SitePermissionTypes.A;
            break;
          case 'F':
            accessType = SitePermissionTypes.F;
            break;
          case 'U':
            accessType = SitePermissionTypes.U;
            break;
          case 'L':
            accessType = SitePermissionTypes.L;
            break;
          case 'G':
            accessType = SitePermissionTypes.G;
            break;
          default:
            accessType = null;
        }

        if (!accessType) {
          throw new Error("You don't have access to the Damage Assessment Mobile App.");
        }

        const userAuthCreds: UserAuthCreds = {
          id: authTokenVerification.accessToken,
          userId: authTokenVerification.id,
          username: authTokenVerification.username,
          accessType: accessType,
        };
        sessionStorage.setItem('userAuthCreds', JSON.stringify(userAuthCreds));
        try {
          setupAuthorizationDatabaseAndSaveToken(userAuthCreds).then(() => {});
        } catch (error) {
          console.log('Error occured', error);
          return;
        }
        dispatch(setAuthCreds(userAuthCreds));
        dispatch(setAccount(authTokenVerification));
        dispatch(setLoadingState(false));
        return navigate('/loading');
      } else {
        throw new Error('Authentication failed. Please try again.');
      }
    } catch (error) {
      console.log('Error occured', error);
      dispatch(setLoadingState(false));
      return navigate('/login');
    }
  }, [authTokenVerification, idTokenProcessed]);

  if (isAppLoading) {
    return <Loading message={'Authentication in process, please wait...'} />;
  } else {
    return <Outlet />;
  }
};

export default AuthPage;
