import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks.ts';
import usePADataForm, { defaultValues, PAFormData } from '../hook/usePADataForm.ts';
import { selectAuthToken, accessTypeSelector } from 'store/authToken/authToken.selector.ts';
import {
  accountGroupIDSelector,
  selectAccount,
  selectedGroupNameSelector,
  userTypeSelector,
  selectedGroupSelector,
} from 'store/accountsInfo/accountsInfo.selector.ts';
import { QueryState, ToastStatus, UserType } from '@/common/enum';
import { PADetailsProps } from './PADetails.props.ts';
import { RadioSelectOptions } from 'interfaces/SelectOptions.interface.ts';
import { DMAFileTypes, FileType } from 'interfaces/ia.interface.ts';
import { AddressSelectCBType } from 'interfaces/SearchInputLocationTypes.ts';
import { SubmitHandler } from 'react-hook-form';
import { PADetail } from 'interfaces/pa.detail.interface.ts';
import {
  addOrDeleteFilesFunc,
  preparePAFilesData,
  convertToTitleCase,
  DateTimeFormat,
  saveFilesToPouchDB,
  saveFileToPalmettoDB,
  formatMailCityState,
} from '@/utilities';
import { deletePAData, savePAData, fetchPADataByID } from 'store/pa/pa.thunk.ts';
import { toast } from 'react-toastify';
import { ToastMessage } from 'components/primitive';
import { CountiesLatLng } from '@/common/static';
import { paTitleSelector, paStatusSelector } from 'store/pa/pa.selector.ts';
import {
  selectedApplicantSelector,
  applicantsSelector,
} from 'store/applicants/applicants.selector.ts';
import PADetailsView from './PADetailsView.tsx';
import { ParcelData } from 'components/fragment/ParcelViewModal/ParcelViewModal.props.tsx';
import { useNavigate } from 'react-router-dom';
import { selectConnectionHealth } from 'store/connection/connection.selector.ts';
import { v4 as uuidv4 } from 'uuid';
import {
  parcelSyncIncidentNameSelector,
  parcelSyncIncidentIDSelector,
  isJPDA_PA_ActiveSelector,
} from 'store/incidents/incidents.selector.ts';
import {
  deleteOfflineFile,
  findFileByQuery,
  updateOfflineFile,
} from 'store/file_db/file_db.thunk.ts';
import moment from 'moment/moment';
import { findByQuery, putDocument } from 'store/db/db.thunk.ts';
import { useUploadForm } from '@/hooks';
import { setPADataSet } from 'store/pa/pa.actions.ts';
import { updateMapInfo } from 'store/map/map.action.ts';
import { mapInfoSelector } from 'store/map/map.selector.ts';
import SitePermissionTypes from '@/common/enum/SitePermissionTypes.ts';

interface MarkerData {
  lat: number;
  lng: number;
}

interface IDocToSavePA extends PADetail {
  type: 'parcel' | 'marker';
  dma_category: string;
  isUnsaved: boolean;
  parcel_id: string | null;
  filesToDelete: DMAFileTypes[] | any[];
  has_files?: number;
  _id?: string;
  _rev?: string;
}

const PADetailsPage: React.FC<{
  newEntry?: boolean;
  data?: PADetail;
  parcelData?: ParcelData;
  parcelId?: string | null;
  markerData?: MarkerData;
}> = ({ newEntry, ...props }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const form = usePADataForm();
  const authToken = useAppSelector(selectAuthToken);
  const palmettoUser = useAppSelector(selectAccount);
  const userType = useAppSelector(userTypeSelector);
  const userPermission = useAppSelector(accessTypeSelector);
  const paStatus = useAppSelector(paStatusSelector);
  const applicants = useAppSelector(applicantsSelector);
  const selectedApplicant = useAppSelector(selectedApplicantSelector);
  const title = useAppSelector(paTitleSelector);
  const countyGroupName = useAppSelector(selectedGroupNameSelector);
  const selectedIncidentForParcelSync = useAppSelector(parcelSyncIncidentIDSelector);
  const selectedGroupID = useAppSelector(accountGroupIDSelector);
  const selectedGroup = useAppSelector(selectedGroupSelector);
  const isJPDA_PA_Active = useAppSelector(isJPDA_PA_ActiveSelector);
  const activeGroupID = selectedGroup ? Number(selectedGroup) : selectedGroupID;
  const isPending = paStatus === QueryState.AWAIT;
  const isStateUser = userType === UserType.STATE_USER;
  const incidentID = Number(selectedIncidentForParcelSync) ?? 0;
  const healthRef = useRef(false);
  healthRef.current = useAppSelector(selectConnectionHealth);
  const dropMarkerMode = useAppSelector(mapInfoSelector).dropMarkerMode;
  const incidentName = useAppSelector(parcelSyncIncidentNameSelector) || '';

  const defaultAssessmentTeamMember =
    palmettoUser !== null
      ? `${palmettoUser?.pvPersonGivenName} ${palmettoUser?.pvPersonSurName}`
      : '';

  const [readOnly, setReadOnly] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [countyOptions, setCountyOptions] = useState<RadioSelectOptions[]>([]);
  const [allowAutoFill, setAllowAutoFill] = useState(true);
  const [filesAdded, setFilesAdded] = useState<DMAFileTypes[] | any>([]);
  const [filesDeleted, setFilesDeleted] = useState<DMAFileTypes[] | any>([]);
  const [localFilesDeleted, setLocalFilesDeleted] = useState<any[]>([]);
  const [isInfoModalOpen, setIsInfoModalOpen] = React.useState(false);
  const [dataToSubmit, setDataToSubmit] = React.useState<PADetail | null>(null);

  const files = form.watch('files');

  const hasOldData: boolean = useMemo(() => {
    if (Array.isArray(files) && files.length > 0) {
      let hasOldData = false;
      for (let i = 0; i < files.length; i++) {
        const fileName = files[i].file_name ?? '';
        if (fileName.length && !fileName.includes('DUMMY')) {
          hasOldData = true;
          break;
        }
      }
      return hasOldData;
    }
    return false;
  }, [files]);

  const { uploadForm } = useUploadForm<FileType>(hasOldData);

  const teamCostEstimate = form.watch('team_cost_estimate');
  const applicantCostEstimate = form.watch('applicant_cost_estimate');
  const warnUser =
    (isJPDA_PA_Active && !teamCostEstimate) || (!isJPDA_PA_Active && !applicantCostEstimate);

  const permissions: { UPDATE: boolean } = useMemo(() => {
    const value = { UPDATE: true };
    if (isStateUser) {
      if (
        !isJPDA_PA_Active &&
        (userPermission === SitePermissionTypes.U || userPermission === SitePermissionTypes.F)
      ) {
        value.UPDATE = false;
      }
    }
    return value;
  }, [isStateUser, userPermission, isJPDA_PA_Active]);

  useEffect(() => {
    if (newEntry) {
      const [city, state, zipCode] = formatMailCityState(props.parcelData?.MailCityState);
      let latitude = 0;
      let longitude = 0;
      if (props.parcelData?.latlngCoords[0][0] && props.parcelData?.latlngCoords[0][0].length > 0) {
        longitude = props.parcelData?.latlngCoords[0][0][0];
        latitude = props.parcelData?.latlngCoords[0][0][1];
      }
      if (props.markerData) {
        latitude = props.markerData.lat;
        longitude = props.markerData.lng;
      }

      const data: PAFormData = {
        ...defaultValues,
        pvGlobalID: uuidv4(),
        county: convertToTitleCase(countyGroupName ?? ''),
        incident_id: incidentID,
        parcel_id: props.parcelId ?? null,
        group_id: activeGroupID,
        // replacement_cost: props?.parcelData?.MarketValue ?? 0,
        address: props?.parcelData?.SiteAddress ?? '',
        city: city,
        state: state,
        zipCode: zipCode,
        latitude: latitude,
        longitude: longitude,
        applicant_name: selectedApplicant?.applicant_name ?? '',
        applicant_county: convertToTitleCase(countyGroupName ?? ''),
        applicant_id: selectedApplicant?.id ?? '',
        name: defaultAssessmentTeamMember,
        phone: palmettoUser?.pvOfficePhone ?? null,
        email: palmettoUser?.email ?? null,
        pvParcelGeometryObject: props?.parcelData?.latlngCoords
          ? { coordinates: props.parcelData?.latlngCoords }
          : null,
        user_group_id: selectedGroupID,
        has_files: 0,
      };
      Object.keys(data).forEach((key) => {
        const typedKey = key as keyof PADetail;
        if (Object.keys(data).includes(key)) {
          const value = data[typedKey];
          if (value) {
            form.setValue(key, value);
          }
        }
      });
      setReadOnly(false);
    } else if (props.data) {
      const applicantFound = applicants?.find(
        (applicant) => applicant.id === props.data?.applicant_id
      );
      const tempData: PADetail = {
        ...props.data,
        posting: props.data?.posting ? props.data?.posting?.toLowerCase() : null,
        pvGlobalID: props.data?.pvGlobalID ? props.data.pvGlobalID : uuidv4(),
        //overwrite applicant_name because the data from server is actually the groupname
        applicant_name: applicantFound ? applicantFound.applicant_name : '',
        _id: props.data?._id,
        _rev: props.data?._rev,
        pvParcelGeometryObject: props.data?.pvParcelGeometryObject
          ? JSON.parse(props.data.pvParcelGeometryObject)
          : null,
        has_files: props.data?.has_files ?? 0,
      };
      if (props?.data?.filesToDelete?.length) {
        setFilesDeleted(props.data.filesToDelete);
      }
      form.reset(tempData);
    }
  }, [newEntry, form]);

  useEffect(() => {
    const tempOptions: RadioSelectOptions[] = [];
    Object.keys(CountiesLatLng).map((key) => {
      if (key !== 'scemd')
        tempOptions.push({ label: convertToTitleCase(key), value: convertToTitleCase(key) });
    });
    setCountyOptions(tempOptions.sort((a, b) => a.label.localeCompare(b.label)));
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const handleEdit = () => {
    setReadOnly(!readOnly);
  };

  const toggleAutoFill = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setAllowAutoFill(checked);
  };

  const onAddressSelect = (value: AddressSelectCBType) => {
    if (value) {
      form.setValue('address', value.address1);
      form.setValue('address2', value.address2);
      form.setValue('city', value.city);
      form.setValue('county', value.county);
      form.setValue('state', value.state);
      form.setValue('zipCode', value.zipCode);
      form.setValue('latitude', value.latitude);
      form.setValue('longitude', value.longitude);
    }
  };

  const onHandleSubmit: SubmitHandler<PADetail> = async (data) => {
    if (warnUser) {
      setIsInfoModalOpen(true);
      setDataToSubmit(data);
      return;
    }
    await onHandleSave(data);
  };

  const onHandleConfirm = async (confirm: boolean) => {
    if (confirm) {
      setIsInfoModalOpen(false);
      if (dataToSubmit) {
        await onHandleSave(dataToSubmit);
      }
    } else {
      setIsInfoModalOpen(false);
    }
  };

  const onHandleSave = async (data: PADetail) => {
    try {
      setIsSaving(true);
      if (localFilesDeleted.length) {
        for (let i = 0; i < localFilesDeleted.length; i++) {
          const fileToDel = localFilesDeleted[i];
          await deleteOfflineFile(fileToDel);
        }
      }
      if (healthRef.current) {
        await onSubmit(data);
        toast.info(<ToastMessage status={ToastStatus.SUCCESS} message='Successfully Processed' />);
      } else {
        if (!data?.pvGlobalID) return;
        if (filesAdded.length) {
          filesAdded.forEach((file: any) => {
            delete file.localId;
          });
          await saveFilesToPouchDB(filesAdded, data?.pvGlobalID, dispatch);
        }

        //filter files to delete
        let filteredFiles = [];
        if (props?.data?.files?.length && filesDeleted?.length) {
          const currentFiles = props?.data?.files?.length ? props?.data?.files : [];
          filteredFiles = currentFiles.filter((file: any) => {
            return !filesDeleted.some(
              (deletedFile: any) => deletedFile.pvGlobalID === file.pvGlobalID
            );
          });
        } else {
          filteredFiles = props?.data?.files || [];
        }
        let hasFiles = props?.data?.has_files ?? 0;
        if (filesAdded.length || filteredFiles.length) {
          hasFiles = 1;
        }
        const docToSave: IDocToSavePA = {
          ...props.data,
          ...data,
          type: props.parcelId ? 'parcel' : 'marker',
          dma_category: 'pa',
          deleted: props.data?.deleted ?? 0,
          user_id: palmettoUser?.id ?? 0,
          parcel_id: data?.parcel_id ?? null,
          created_at:
            props.data && props.data?.created_at
              ? props.data?.created_at
              : moment(new Date(), 'YYYY-MM-DD HH:mm:ss').toISOString(),
          updated_at: moment(new Date(), 'YYYY-MM-DD HH:mm:ss').toISOString(),
          isUnsaved: true,
          pvParcelGeometryObject: data?.pvParcelGeometryObject
            ? JSON.stringify(data.pvParcelGeometryObject)
            : null,
          filesToDelete: filesDeleted.length ? filesDeleted : [],
          files: filteredFiles || [],
          has_files: hasFiles,
        };

        putDocument(docToSave).then(async () => {
          const getPouchPAData = (await findByQuery({
            selector: { dma_category: 'pa' },
          })) as any;
          dispatch(setPADataSet(getPouchPAData));
        });
        toast.info(
          <ToastMessage status={ToastStatus.SUCCESS} message='Successfully Saved Offline' />
        );
      }
      if (dropMarkerMode) {
        dispatch(updateMapInfo({ dropMarkerMode: false }));
      }
      setIsSaving(false);
      navigate(-1);
    } catch (error) {
      toast.error(<ToastMessage status={ToastStatus.ERROR} message='Encountered System Error' />);
      setIsSaving(false);
      throw error;
    }
  };

  const saveData = async (payload: any) => {
    const savedItem = {};
    await dispatch(savePAData(payload)).then((res: any) => {
      if (res.type === 'pa/savePAData/rejected') {
        throw new Error(res.error.message);
      }
      Object.assign(savedItem, res.payload);
    });
    return savedItem;
  };

  const onSubmit: SubmitHandler<PADetail> = async (data) => {
    if (!authToken || !palmettoUser || !data.pvGlobalID) return;

    const saveLocalFiles = async (globalID: string, files: DMAFileTypes[]) => {
      //find local files associated with the globalId
      const filesArr = (await findFileByQuery({
        selector: { pvGlobalGroupID: globalID, isUnsaved: true },
      })) as any;
      const savedFiles: FileType[] = [];
      //save files to palmetto and flag them as saved in local db
      for (let i = 0; i < filesArr.length; i++) {
        const file = filesArr[i];
        const binaryFile = new File([file.file], file.name, { type: file.type });
        try {
          await saveFileToPalmettoDB(
            binaryFile,
            authToken,
            data?.pvGlobalID,
            palmettoUser.id,
            palmettoUser.username,
            uploadForm
          ).then((res) => {
            savedFiles.push(res);
            //update the file in local db to reflect that it has been saved
            file.isUnsaved = false;
            updateOfflineFile(file).then((res) => {
              console.log('file updated', res);
            });
          });
        } catch {
          toast.error(
            <ToastMessage status={ToastStatus.ERROR} message={`Error saving file ${file.name}`} />
          );
        }
      }

      if (files?.length) {
        //loop added files and save file to palmetto db
        for (let i = 0; i < filesAdded.length; i++) {
          const file = filesAdded[i];
          try {
            await saveFileToPalmettoDB(
              file,
              authToken,
              data?.pvGlobalID,
              palmettoUser.id,
              palmettoUser.username,
              uploadForm
            ).then((res) => {
              savedFiles.push(res);
            });
          } catch {
            toast.error(
              <ToastMessage status={ToastStatus.ERROR} message={`Error saving file ${file.name}`} />
            );
          }
        }
      }
      return savedFiles;
    };

    const savedFiles = await saveLocalFiles(data.pvGlobalID, filesAdded);
    //set pvGlobalGroupID for each file
    savedFiles.forEach((file: any) => {
      file.pvGlobalGroupID = data.pvGlobalID;
    });

    let hasFiles = props?.data?.has_files ?? 0;
    if (savedFiles.length) {
      hasFiles = 1;
    }

    const payload: any = {
      ...data,
      user_id: palmettoUser?.id ?? 0,
      date: DateTimeFormat({ dte: data.date, format: 'YYYY-MM-DD HH:mm:ss' }),
      files: preparePAFilesData(savedFiles),
      pvParcelGeometryObject: data?.pvParcelGeometryObject
        ? JSON.stringify(data.pvParcelGeometryObject)
        : '',
      has_files: hasFiles,
    };

    await addOrDeleteFilesFunc({
      filesToAdd: savedFiles,
      filesToDelete: filesDeleted,
      uuid: data.pvGlobalID,
      authToken,
    });

    if (!isStateUser) {
      delete payload.jpda_status;
    }
    if (payload?.filesToDelete) {
      delete payload.filesToDelete;
    }

    if (payload?.isUnsaved) {
      delete payload.isUnsaved;
    }
    if (payload?._id) {
      delete payload._id;
    }
    if (payload?._rev) {
      delete payload._rev;
    }
    //if is new item we get the response from saveData, if not we make a call to get the response
    let response: any = await saveData(payload);

    if (data?.id) {
      response = await dispatch(fetchPADataByID(data.id)).unwrap();
    }
    //format response
    if (response?.utilities_out && !Array.isArray(response.utilities_out)) {
      response.utilities_out = response.utilities_out.split(',').map((item: any) => item.trim());
    }
    if (response?.insurance_type && !Array.isArray(response.insurance_type)) {
      response.insurance_type = response.insurance_type.split(',').map((item: any) => item.trim());
    }
    if (response?.created_at) {
      response.created_at = moment(response.created_at, 'YYYY-MM-DD HH:mm:ss').toISOString();
    }
    if (response?.updated_at) {
      response.updated_at = moment(response.updated_at, 'YYYY-MM-DD HH:mm:ss').toISOString();
    }
    if (response?.files?.length) {
      delete response.files;
    }
    const itemInPouchDb = (await findByQuery({
      selector: { pvGlobalID: data?.pvGlobalID },
    })) as any;
    if (itemInPouchDb[0] && itemInPouchDb[0]?._id) {
      const item = itemInPouchDb[0];

      if (item?.files?.length) {
        // filter deleted files
        if (filesDeleted?.length) {
          const filteredFiles = item.files.filter((file: any) => {
            return !filesDeleted.some(
              (deletedFile: any) => deletedFile.pvGlobalID === file.pvGlobalID
            );
          });
          item.files = filteredFiles;
        }
        if (savedFiles?.length) {
          item.files = [...item.files, ...savedFiles];
        }
      }
      if (item?.filesToDelete?.length) {
        delete item.filesToDelete;
      }

      const updatedItem = {
        ...item,
        ...response,
      };
      if (updatedItem?.isUnsaved) {
        delete updatedItem.isUnsaved;
      }
      await putDocument(updatedItem).then(async () => {
        const getItemsFromPouchDB = (await findByQuery({
          selector: { dma_category: 'pa' },
        })) as any;
        dispatch(setPADataSet(getItemsFromPouchDB));
      });
    } else {
      const docToSave: IDocToSavePA = {
        ...response,
        dma_category: 'pa',
        // files: savedFiles,
      };
      await putDocument(docToSave).then(async () => {
        const getPouchPAData = (await findByQuery({
          selector: { dma_category: 'pa' },
        })) as any;
        dispatch(setPADataSet(getPouchPAData));
      });
    }
  };

  const onDelete = async () => {
    const { id } = form.getValues();
    if (!id) return;
    setIsDeleting(true);
    await dispatch(deletePAData({ id }))
      .unwrap()
      .then(() => {
        toast.info(<ToastMessage status={ToastStatus.SUCCESS} message='Successfully Deleted' />);
        setIsDeleting(false);
        setTimeout(() => goBack(), 2000);
      })
      .catch(() => {
        toast.info(<ToastMessage status={ToastStatus.ERROR} message='Error Deleting' />);
        setIsDeleting(false);
      });
  };

  const combineProps: PADetailsProps = {
    isNew: false,
    authToken: authToken ?? '',
    palmettoUser,
    title: title ?? 'Public Assistance',
    form,
    readOnly,
    inProcess: isPending || isSaving,
    isDeleting,
    countyOptions,
    allowAutoFill,
    isStateUser,
    filesAdded,
    filesDeleted,
    handleEdit,
    goBack,
    onSubmit,
    onDelete,
    toggleAutoFill,
    onAddressSelect,
    setFilesAdded,
    setFilesDeleted,
    setLocalFilesDeleted,
    isJPDA_PA_Active,
    onHandleSubmit,
    isInfoModalOpen,
    onHandleConfirm,
    incidentName,
    permissions,
    isOnline: healthRef.current,
    hasOldData,
    files,
  };

  return <PADetailsView {...combineProps} />;
};

export default PADetailsPage;
