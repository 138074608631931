/* eslint-disable @typescript-eslint/no-explicit-any,no-unused-vars */
import { RadioSelectOptions } from 'interfaces/SelectOptions.interface.ts';
import { FieldError, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { DMAFileTypes, IADetails } from 'interfaces/ia.interface.ts';
import { IUserPortal } from 'interfaces/UserAccountInfo.interface.ts';
import React from 'react';
import { UtilitiesOut } from '@/common/enum';
import { IAFormData } from 'pages/IA/hook/useIAForm.ts';
import { Nullable } from 'interfaces/Nullable.ts';

export interface IADataLoaderResponse {
  iaData: IADetails | null;
}

export const measurements: RadioSelectOptions[] = [
  { value: 'inches', label: 'inches' },
  { value: 'feet', label: 'feet' },
];

interface IDetailsProps {
  form: UseFormReturn<any, any>;
  palmettoUser: Nullable<IUserPortal>;
  authToken: string;
  inProcess: boolean;
  isStateUser: boolean;
  allowAutoFill: boolean;
  readOnly: boolean;
  filesAdded: DMAFileTypes[] | any[];
  filesDeleted: DMAFileTypes[] | any[];
  utilitiesError: { message: string } | FieldError | undefined;
  insuranceError: { message: string } | FieldError | undefined;
  utilitiesSelect: {
    noOutages: boolean;
    Electricity: boolean;
    Gas: boolean;
    Water: boolean;
  };
  insuranceSelect: {
    condominium: boolean;
    flood: boolean;
    hazardRider: boolean;
    homeOwners: boolean;
    manufacturedHome: boolean;
    unknown: boolean;
    noInsurance: boolean;
  };
  goBack: () => void;
  handleEdit: () => void;
  handleCheckUtilities: (event: React.ChangeEvent<HTMLInputElement>, field: UtilitiesOut) => void;
  handleCheckInsurance: (event: React.ChangeEvent<HTMLInputElement>, field: string) => void;
  toggleAutoFill: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setFilesAdded: React.Dispatch<React.SetStateAction<any>>;
  setFilesDeleted: React.Dispatch<React.SetStateAction<any>>;
  setLocalFilesDeleted: React.Dispatch<React.SetStateAction<any>>;
  onHandleSubmit: SubmitHandler<IAFormData>;
  incidentName: string;
  permissions: { UPDATE: boolean };
  isOnline: boolean;
  hasOldData: boolean;
  files: DMAFileTypes[];
}

export type DetailsViewProps = IDetailsProps;
