import { useEffect, useState } from 'react';
import { isMobile as isMobileDeviceDetect } from 'react-device-detect';

const MobileDetect = () => {
  const hasWindow = typeof window !== 'undefined';

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (isMobileDeviceDetect) setIsMobileDevice(true);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    if (hasWindow) {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasWindow]);

  return { isMobileDevice, windowDimensions };
};

export default MobileDetect;

export const MOBILE_SIZE = 740; // *: This is the size at which we break from desktop to mobile view
