import useScript from 'hooks/useScript.ts';
import L from 'leaflet';

window.L = L; // The libraries loaded later will complain if we don't set this

function ScriptsLoader() {
  const googleApiKey = import.meta.env.VITE_GOOGLE_API_KEY;
  useScript('https://maps.googleapis.com/maps/api/js?key=' + googleApiKey + '&libraries=places');
  useScript('https://palmetto-dma-pwa-lib.s3.amazonaws.com/sql-allow-memory-growth.js');
  useScript('https://palmetto-dma-pwa-lib.s3.amazonaws.com/Leaflet.VectorGrid.bundled.min.js');
  useScript('https://palmetto-dma-pwa-lib.s3.amazonaws.com/ctxtextpath.js');
  useScript('https://palmetto-dma-pwa-lib.s3.amazonaws.com/leaflet-compass.min.js');
  useScript('https://unpkg.com/esri-leaflet-vector@4.2.3/dist/esri-leaflet-vector.js');
  useScript(
    'https://palmetto-dma-pwa-lib.s3.amazonaws.com/esri-leaflet-3.0.12-renderer-omnivore-webmap.js'
  );

  // useScript('https://palmetto-dma-pwa-lib.s3.amazonaws.com/sql.js');
  // useScript('https://palmetto-dma-pwa-lib.s3.amazonaws.com/L.LabelTextCollision.js');
  // useScript('https://palmetto-dma-pwa-lib.s3.amazonaws.com/Leaflet.streetlabels.js');
  // useScript('https://unpkg.com/esri-leaflet@3.0.12/dist/esri-leaflet.js');
  // useScript('https://cdn.jsdelivr.net/leaflet.esri.renderers/2.0.2/esri-leaflet-renderers.js');
  // useScript(
  //   'https://api.tiles.mapbox.com/mapbox.js/plugins/leaflet-omnivore/v0.3.1/leaflet-omnivore.min.js'
  // );
  // useScript('https://cdn.jsdelivr.net/leaflet.esri.webmap/0.4.0/esri-leaflet-webmap.js');

  return null;
}

export default ScriptsLoader;
