import PACategoryValues from '../PA/PACategoryValues.ts';

export const BuildingCategorySvg = {
  [PACategoryValues.DebrisRemoval.value]: '/images/Category-A.svg',
  [PACategoryValues.EmergencyProtectiveMeasures.value]: '/images/Category-B.svg',
  [PACategoryValues.RoadsAndBridges.value]: '/images/Category-C.svg',
  [PACategoryValues.WaterControlFacilities.value]: '/images/Category-D.svg',
  [PACategoryValues.BuildingsAndEquipment.value]: '/images/Category-E.svg',
  [PACategoryValues.Utilities.value]: '/images/Category-F.svg',
  [PACategoryValues.ParksRecreationAndOther.value]: '/images/Category-G.svg',
};
