import { useEffect, useState, useRef, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { SubmitHandler } from 'react-hook-form';
import { defaultValues as IADefaultValues } from '../../IA/hook/useIAForm.ts';
import useIAmultiForm, { defaultValues, IAmultipleFormData } from '../hook/useIAmultiForm.ts';
import { ToastMessage } from 'components/primitive';
import { useAppSelector, useAppDispatch } from 'store/hooks.ts';
import {
  accountGroupIDSelector,
  selectAccount,
  selectedGroupNameSelector,
} from 'store/accountsInfo/accountsInfo.selector.ts';
import { saveIAData } from 'store/ia/ia.thunk.ts';
import { ToastStatus } from '@/common/enum';
import { convertToTitleCase, formatMailCityState } from '@/utilities';
import { IADetails } from 'interfaces/ia.interface.ts';
import { IAmultiDetailsViewProps } from './IAmultiDetails.props.ts';
import IAmultiDetailsView from './IAmultiDetailsView.tsx';
import { findByQuery, putDocument } from 'store/db/db.thunk.ts';
import { selectConnectionHealth } from 'store/connection/connection.selector.ts';
import { BuildingDamageTypes } from '@/data/LegendValues/BuildingDamage.ts';
import { ParcelData } from 'components/fragment/ParcelViewModal/ParcelViewModal.props.tsx';
import moment from 'moment';
import {
  parcelSyncIncidentIDSelector,
  parcelSyncIncidentNameSelector,
} from 'store/incidents/incidents.selector.ts';
import { setIADataSet } from 'store/ia/ia.actions.ts';

interface IDocToSaveIA extends IADetails {
  type: string;
  dma_category: string;
  deleted: number;
  user_id: number;
  parcel_id: string | null;
  applicant_name: string;
  created_at: string;
  updated_at: string;
  isUnsaved: boolean;
  _id?: string;
  _rev?: string;
}

const IAmultiDetailsPage: FC<{
  multiParcelsData: ParcelData[];
}> = ({ multiParcelsData }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const form = useIAmultiForm();
  const palmettoUser = useAppSelector(selectAccount);
  const countyGroupName = useAppSelector(selectedGroupNameSelector);
  const selectedIncidentForParcelSync = useAppSelector(parcelSyncIncidentIDSelector);
  const selectedGroupID = useAppSelector(accountGroupIDSelector);
  const incidentName = useAppSelector(parcelSyncIncidentNameSelector) || '';

  const healthRef = useRef(false);
  healthRef.current = useAppSelector(selectConnectionHealth);

  const defaultAssessmentTeamMember =
    palmettoUser !== null
      ? `${palmettoUser?.pvPersonGivenName} ${palmettoUser?.pvPersonSurName}`
      : '';

  const incidentID = Number(selectedIncidentForParcelSync) ?? 0;

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const buildingDamage = form.watch('building_damage');

  useEffect(() => {
    const data: any = {
      ...defaultValues,
    };
    Object.keys(data).forEach((key) => {
      if (Object.keys(data).includes(key)) {
        const value = data[key];
        if (value) {
          form.setValue(key, value);
        }
      }
    });
  }, [form]);

  const calcEstimatedLoss = (replacementCost: number) => {
    const bldgDmg2Percent = (value: string): number => {
      const options: Partial<Record<BuildingDamageTypes, number>> = {
        Affected: 0.25,
        'Minor Damage': 0.5,
        'Major Damage': 0.75,
        Destroyed: 1,
      };
      const key = value;
      const isValidKey: boolean = Object.keys(options).includes(key);
      if (!isValidKey) return 0;
      return options[key as BuildingDamageTypes] ?? 0;
    };
    let estimated_loss = 0;
    if (buildingDamage && Number(replacementCost)) {
      const percentComplete = bldgDmg2Percent(buildingDamage);
      const newEstimateCost = Number(replacementCost) * percentComplete;
      estimated_loss = newEstimateCost;
    }
    return estimated_loss;
  };

  const goBack = () => {
    navigate(-1);
  };

  const saveData = async (payload: any) => {
    const savedItem = {};
    await dispatch(saveIAData(payload)).then((res: any) => {
      if (res.type === 'ia/saveIAData/rejected') {
        throw new Error(res.error.message);
      }
      Object.assign(savedItem, res.payload);
    });
    return savedItem;
  };

  const onHandleSubmit: SubmitHandler<IAmultipleFormData> = async (data) => {
    try {
      if (!palmettoUser) {
        toast.info(<ToastMessage status={ToastStatus.ERROR} message='Missing User' />);
        return;
      }
      setIsSaving(true);
      //loop multiparcels data
      let offlineSavedCount = 0;
      let uploadedCount = 0;
      for (let i = 0; i < multiParcelsData.length; i++) {
        const parcel = multiParcelsData[i];
        const [city, state, zipCode] = formatMailCityState(parcel?.MailCityState);

        const payload: any = {
          ...IADefaultValues,
          pvGlobalID: uuidv4(),
          county: convertToTitleCase(countyGroupName ?? ''),
          assessment_team_member: defaultAssessmentTeamMember,
          incident_id: incidentID,
          group_id: selectedGroupID ?? 0,
          replacement_cost: parcel?.MarketValue ?? 0,
          occupant_name: parcel.Owner ?? '',
          address: parcel.SiteAddress ?? '',
          city: city,
          state: state,
          zipCode: zipCode,
          structure_type: data.structure_type,
          building_damage: data.building_damage,
          isolated: data.building_damage === BuildingDamageTypes.Inaccessible ? 'Yes' : 'No',
          parcel_id: parcel.PIN,
          pvParcelGeometryObject: parcel?.latlngCoords
            ? JSON.stringify({ coordinates: parcel?.latlngCoords })
            : null,
          estimated_loss: calcEstimatedLoss(parcel?.MarketValue ?? 0),
          deleted: 0,
          user_id: palmettoUser?.id ?? 0,
          files: [],
          has_files: 0,
        };
        if (healthRef.current) {
          await onSubmit(payload);
          uploadedCount++;
        } else {
          const docToSave: IDocToSaveIA = {
            ...payload,
            dma_category: 'ia',
            created_at: moment(new Date(), 'YYYY-MM-DD HH:mm:ss').toISOString(),
            updated_at: moment(new Date(), 'YYYY-MM-DD HH:mm:ss').toISOString(),
            isUnsaved: true,
          };

          await putDocument(docToSave);
          offlineSavedCount++;
        }
      }
      const getPouchIAData = (await findByQuery({
        selector: { dma_category: 'ia' },
      })) as any;
      dispatch(setIADataSet(getPouchIAData));
      const messageForOffline = offlineSavedCount
        ? `${offlineSavedCount} parcel` + (offlineSavedCount > 1 ? 's' : '') + ' offline'
        : '';
      let messageForOnline = uploadedCount
        ? `${uploadedCount} parcel` + (uploadedCount > 1 ? 's' : '')
        : '';
      if (messageForOffline && messageForOnline) {
        messageForOnline += ' and ';
      }
      toast.info(
        <ToastMessage
          status={ToastStatus.SUCCESS}
          message={`Successfully Saved ${messageForOnline} ${messageForOffline}`}
        />
      );
      setIsSaving(false);
      navigate(-1);
    } catch (error) {
      toast.error(<ToastMessage status={ToastStatus.ERROR} message='Encountered System Error' />);
      setIsSaving(false);
      throw error;
    }
  };

  const onSubmit: SubmitHandler<IAmultipleFormData> = async (payload: any) => {
    //if is new item we get the response from saveData, if not we make a call to get the response
    const response: any = await saveData(payload);

    //format response, save in PouchDB and set in state
    if (response?.created_at) {
      response.created_at = moment(response.created_at, 'YYYY-MM-DD HH:mm:ss').toISOString();
    }
    if (response?.updated_at) {
      response.updated_at = moment(response.updated_at, 'YYYY-MM-DD HH:mm:ss').toISOString();
    }
    if (response?.utilities_out)
      response.utilities_out = response.utilities_out.split(',').map((item: any) => item.trim());
    if (response?.insurance_type)
      response.insurance_type = response.insurance_type.split(',').map((item: any) => item.trim());

    const docToSave: IDocToSaveIA = {
      ...response,
      dma_category: 'ia',
    };
    await putDocument(docToSave);
  };

  const combineProps: IAmultiDetailsViewProps = {
    form,
    inProcess: isSaving,
    goBack,
    onHandleSubmit,
    numberOfParcels: multiParcelsData.length,
    incidentName,
  };

  return (
    <>
      <IAmultiDetailsView {...combineProps} />;
    </>
  );
};

export default IAmultiDetailsPage;
