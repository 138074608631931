import { IAmultiDetailsViewProps } from './IAmultiDetails.props.ts';
import { Grid, IconButton, RadioGroup, useTheme } from '@mui/material';
import useStyles from './IAmultiDetailsView.styles.ts';
import Box from '@mui/material/Box';
import { DualTitleHeaderComponent } from '@zawarski/palmetto-ui-components';
import BackIcon from '@mui/icons-material/ArrowBack';
import { StructureType } from '@/common/enum';
import { FormContent, FormGroup, RadioButton, Typo } from 'components/primitive';
import { PageMaxLayout } from 'components/layout';
import { Controller } from 'react-hook-form';
import { ActionButtons } from 'components/fragment';
import { FC } from 'react';
import { BuildingDamageTypes } from '@/data/LegendValues/BuildingDamage.ts';

const IAmultiDetailsView: FC<IAmultiDetailsViewProps> = ({
  form,
  inProcess,
  goBack,
  onHandleSubmit,
  numberOfParcels,
  incidentName,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box className={'layout vertical full-height'} style={{ overflowY: 'auto' }}>
      <DualTitleHeaderComponent
        titleBig={<Typo style={{ fontSize: 16, fontWeight: 500 }}>Add Assessment - IA</Typo>}
        titleSmall={`${incidentName ? incidentName + ', ' : ''} ${numberOfParcels === 1 ? numberOfParcels + ' parcel' : numberOfParcels + ' parcels'} selected`}
        icon={
          <IconButton onClick={() => goBack()}>
            <BackIcon className='icon' />
          </IconButton>
        }
      />
      <PageMaxLayout>
        <FormContent title='Structure Information' bgcolor={'#FFF'}>
          <Grid container spacing={2} sx={classes.gridContainer}>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='building_damage'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <FormGroup label='Building damage' required error={error}>
                    <RadioGroup
                      aria-labelledby='controlled-radio-buttons-group'
                      name='controlled-radio-buttons-group'
                      value={value ? value : null}
                      onChange={onChange}
                      sx={classes.groupRadioContainer}>
                      <RadioButton
                        isActive={value ? value === BuildingDamageTypes.NoDamage : false}
                        value={BuildingDamageTypes.NoDamage}
                        label={BuildingDamageTypes.NoDamage}
                      />
                      <RadioButton
                        isActive={value ? value === BuildingDamageTypes.Affected : false}
                        value={BuildingDamageTypes.Affected}
                        label={BuildingDamageTypes.Affected}
                      />
                      <RadioButton
                        isActive={value ? value === BuildingDamageTypes.MinorDamage : false}
                        value={BuildingDamageTypes.MinorDamage}
                        label={BuildingDamageTypes.MinorDamage}
                      />
                      <RadioButton
                        isActive={value ? value === BuildingDamageTypes.MajorDamage : false}
                        value={BuildingDamageTypes.MajorDamage}
                        label={BuildingDamageTypes.MajorDamage}
                      />
                      <RadioButton
                        isActive={value ? value === BuildingDamageTypes.Destroyed : false}
                        value={BuildingDamageTypes.Destroyed}
                        label={BuildingDamageTypes.Destroyed}
                      />
                      <RadioButton
                        isActive={value ? value === BuildingDamageTypes.Inaccessible : false}
                        value={BuildingDamageTypes.Inaccessible}
                        label={BuildingDamageTypes.Inaccessible}
                      />
                    </RadioGroup>
                  </FormGroup>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='structure_type'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <FormGroup label='Structure type' required error={error}>
                    <RadioGroup
                      aria-labelledby='controlled-radio-buttons-group'
                      name='controlled-radio-buttons-group'
                      value={value ? value.toLowerCase() : null}
                      onChange={onChange}
                      sx={classes.groupRadioContainer}>
                      <RadioButton
                        isActive={
                          value
                            ? value.toLowerCase() === StructureType.business.toLowerCase()
                            : false
                        }
                        value={StructureType.business.toLowerCase()}
                        label={StructureType.business}
                      />
                      <RadioButton
                        isActive={
                          value
                            ? value.toLowerCase() === StructureType.singleFamily.toLowerCase()
                            : false
                        }
                        value={StructureType.singleFamily.toLowerCase()}
                        label={StructureType.singleFamily}
                      />
                      <RadioButton
                        isActive={
                          value
                            ? value.toLowerCase() === StructureType.manufacturedHome.toLowerCase()
                            : false
                        }
                        value={StructureType.manufacturedHome.toLowerCase()}
                        label={StructureType.manufacturedHome}
                      />
                      <RadioButton
                        isActive={
                          value
                            ? value.toLowerCase() === StructureType.multiFamily.toLowerCase()
                            : false
                        }
                        value={StructureType.multiFamily.toLowerCase()}
                        label={StructureType.multiFamily}
                      />
                    </RadioGroup>
                  </FormGroup>
                )}
              />
            </Grid>
          </Grid>
        </FormContent>
      </PageMaxLayout>
      <ActionButtons
        readonly={false}
        isSaving={inProcess}
        withSave
        save={!inProcess ? form.handleSubmit(onHandleSubmit) : undefined}
      />
    </Box>
  );
};

export default IAmultiDetailsView;
