import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MapInitialState, MapStateTypes } from 'store/map/map.state.ts';
import { fetchMapLayerID } from 'store/map/map.thunk.ts';
const initialState: MapStateTypes = MapInitialState;

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    updateMapInfo: (state, action: PayloadAction<{ [key: string]: string | boolean }>) => {
      state.mapInfo = { ...state.mapInfo, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMapLayerID.fulfilled, (state, action) => {
      const payloadData = action.payload;
      if (Array.isArray(payloadData) && payloadData.length > 0) {
        const data = payloadData[0];
        state.mapInfo.mapId = data.pvSettingValue;
        state.mapInfo.mapSettingsID = data.pvSettingID;
      }
    });
  },
});

export default mapSlice.reducer;
