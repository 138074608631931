import moment from 'moment-timezone';

moment.tz.setDefault('America/New_York');

export const convertToTitleCase = (str: string) => {
  str = str.toLowerCase();
  return str.replace(/(^|\s)\S/g, function (t) {
    return t.toUpperCase();
  });
};

export function PhoneNumberFormat(value: string | null | undefined) {
  if (!value) return value;
  const phoneNumber = value.toString().replace(/\D/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
  }

  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}

type DateTimeFormatProps = {
  dte: string | null | undefined | Date;
  format?: string;
};
export const DateTimeFormat = ({ dte, format = 'MM-DD-YYYY HH:MM' }: DateTimeFormatProps) => {
  if (dte === undefined || dte === null) return '';
  return moment.utc(dte).format(format);
};

export function limitZipLength(value: any, maxZipChar: number) {
  if (!value) return '';
  const zipCode = value.toString().replace(/[^\d]/g, '');
  if (zipCode.length <= maxZipChar) {
    return zipCode;
  } else {
    const result = Number(zipCode.slice(0, maxZipChar));
    return result;
  }
}

export function formatMailCityState(value: string | null | undefined) {
  let city = '';
  let state = '';
  let zipCode = '';
  if (!value) return [city, state, zipCode];
  const splitCityState = value.split(',');
  city = splitCityState[0]?.trim();
  const trimSplitCityState = splitCityState[1]?.trim();
  state = trimSplitCityState?.split(' ')[0]?.trim();
  zipCode = trimSplitCityState?.split(' ')[1]?.trim();
  return [city, state, zipCode];
}
